import axios from 'axios';
import base_url from "../../config/config";

const fetchDataFromLink = async (link, data,headers) => {
  try {
    const response = await axios.post(link, data,{headers});
    const result = response.data;
    return [result, response.status];
  } catch (error) {
    return [error['response']['data']['error'], error['response']['status']];
  }
};

export const completion_api = async (token,name,profile,referral_id) => {

  const headers ={'X-TOKEN': token,'Content-Type':'multipart/form-data; charset=utf-8'};
  const formData = new FormData();
  formData.append('name', name);
  if (referral_id !== null){
    formData.append('referral_id', referral_id);
  }
  if (profile !== null){
    formData.append('profile', profile);
  }
  const data = await fetchDataFromLink(`${base_url}/complete_info`, formData,headers);
  return data;
};

export default completion_api;