import axios from "axios";
import base_url from "../../config/config";

const fetchDataFromLink = async (link, data, headers) => {
  try {
    const response = await axios.post(link, data, { headers });
    const result = response.data;
    return [result, response.status];
  } catch (error) {
    return [error["response"]["data"]["error"], error["response"]["status"]];
  }
};

export const submit_signal_api = async (
  token,
  symbol,
  pair,
  des_fa,
  des_en,
  price,
  logo,
  type,
  position,
  strategy,
  timeframe,
  lightchart,
  darkchart,
  botanalysis,
  expertanalysis,
  freesignal
) => {
  const headers = {
    "X-TOKEN": token,
    "Content-Type": "multipart/form-data; charset=utf-8",
  };
  const formData = new FormData();

  formData.append("symbol", symbol);
  formData.append("pair", pair);
  formData.append("des_fa", des_fa);
  formData.append("des_en", des_en);
  formData.append("price", price);
  formData.append("logo", logo);
  formData.append("type", type);
  formData.append("position", position);
  formData.append("strategy", strategy);
  formData.append("timeframe", timeframe);
  formData.append("lightchart", lightchart);
  formData.append("darkchart", darkchart);
  formData.append("botanalysis", botanalysis === true ? "true" : "false");
  formData.append("expertanalysis", expertanalysis === true ? "true" : "false");
  formData.append("freesignal", freesignal === true ? "true" : "false");

  const data = await fetchDataFromLink(
    `${base_url}/submit_signal`,
    formData,
    headers
  );
  return data;
};

export default submit_signal_api;
