import styled from "./notFound.module.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { Danger,Home2 } from "iconsax-react";
function NotFound() {
  return (
    <div className={styled.pagebody}>
      <h1>404 ارور<Danger size={40}/></h1>
      <h2>صفحه مورد نظر پیدا نشد</h2>
      <Link to="/dashboard" className={styled.back}><span>بازگشت به صفحه اصلی</span><Home2 size={20} /></Link>
    </div>
  );
}
export default NotFound;
