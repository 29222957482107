import styled from "./Login.module.css";
import { Link } from "react-router-dom";
import { Sms, Sun1, Moon, LanguageSquare } from "iconsax-react";
import img2 from "../../assets/img/google.png";
import { language_data } from "../../component/language/language.jsx";
import { login_api } from "../../component/api/login.jsx";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router";

language_data()
  .then((data) => {})
  .catch((error) => {
    console.error(error);
  });
var language_json = JSON.parse(localStorage.getItem("language"));

function Login() {
  const [language, setLanguage] = useState("fa");
  const [theme, setTheme] = useState("light");

  const changeLanguage = () => {
    var text = document.getElementById("text");
    var email_input = document.getElementById("email-input");
    var loginWraper = document.getElementById("loginWraper");
    var loginImgGrad = document.getElementById("loginImgGrad");
    var solgan = document.getElementById("solgan");
    var icons = document.getElementById("icons");
    const newLanguage = language === "fa" ? "en" : "fa";
    localStorage.setItem("select_language", newLanguage);
    if (newLanguage === "fa") {
      text.style.direction = "rtl";
      email_input.style.paddingRight = "12%";
      email_input.style.paddingLeft = "0%";
      loginWraper.style.flexDirection = "row";
      loginImgGrad.style.background = `var(--Gradient)`;
      icons.style.left = "0px";
      icons.style.right = "auto";
      solgan.style.left = "auto";
      solgan.style.right = "30px";
      document.documentElement.style.setProperty("--font", "var(--fa-font)");
    } else if (newLanguage === "en") {
      text.style.direction = "ltr";
      email_input.style.paddingLeft = "12%";
      email_input.style.paddingRight = "0%";
      loginWraper.style.flexDirection = "row-reverse";
      loginImgGrad.style.background = `var(--Gradient-reverse)`;
      icons.style.left = "auto";
      icons.style.right = "0px";
      solgan.style.left = "30px";
      solgan.style.right = "auto";
      document.documentElement.style.setProperty("--font", "var(--en-font)");
    }
    setLanguage(newLanguage);
  };
  const changeTheme = () => {
    var moonbutton = document.getElementById("moonbutton");
    var sunbutton = document.getElementById("sunbutton");

    const newtheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("select_theme", newtheme);
    if (newtheme === "light") {
      sunbutton.style.display = "none";
      moonbutton.style.display = "inline";

      document.documentElement.style.setProperty("--Text", "var(--light-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--light-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--light-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--light-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--light-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--light-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--light-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--light-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--light-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--light-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--light-code-img)"
      );
    } else if (newtheme === "dark") {
      moonbutton.style.display = "none";
      sunbutton.style.display = "inline";

      document.documentElement.style.setProperty("--Text", "var(--dark-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--dark-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--dark-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--dark-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--dark-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--dark-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--dark-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--dark-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--dark-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--dark-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--dark-code-img)"
      );
    }
    setTheme(newtheme);
  };
  if (window.innerWidth < 990) {
    window.location.href = "https://webapp.signalcurrency.com/#/home";
  }
  useEffect(() => {
    const selectLanguage = localStorage.getItem("select_language");
    const selectTheme = localStorage.getItem("select_theme");

    if (selectLanguage === "en") {
      changeLanguage();
    }
    if (selectTheme === "dark") {
      changeTheme();
    }
  }, []);
  const SubmitLogin = async () => {
    var input = document.getElementById("email-input");
    var SubmitLogin = document.getElementById("SubmitLogin");
    const text = input.value;
    if (text !== "" && text !== null) {
      if (text.includes("@") && text.includes(".")) {
        SubmitLogin.innerHTML = language_json[language]["loading"];
        SubmitLogin.style.opacity = "0.5";
        handleClick(true);
        const login_api_data = await login_api(text);
        if (login_api_data[1] === 200) {
          localStorage.setItem("email", text);
          toast.success(language_json[language]["sent"], {
            position:
              language === "fa"
                ? "bottom-right"
                : language === "en"
                ? "bottom-left"
                : "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            rtl: language === "fa" ? true : language === "en" ? false : true,
            draggable: true,
            progress: undefined,
            theme: theme,
          });
          setTimeout(() => {
            window.location.href = "/code";
          }, 2000);
        } else if (login_api_data[1] === 406) {
          localStorage.setItem("email", text);
          toast.info(language_json[language][login_api_data[0]], {
            position:
              language === "fa"
                ? "bottom-right"
                : language === "en"
                ? "bottom-left"
                : "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            rtl: language === "fa" ? true : language === "en" ? false : true,
            draggable: true,
            progress: undefined,
            theme: theme,
          });
          SubmitLogin.style.opacity = "1";
          handleClick(false);
          SubmitLogin.innerHTML = language_json[language]["login"];
          setTimeout(() => {
            window.location.href = "/code";
          }, 2000);
        } else if (login_api_data[1] === 400) {
          toast.error(language_json[language][login_api_data[0]], {
            position:
              language === "fa"
                ? "bottom-right"
                : language === "en"
                ? "bottom-left"
                : "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            rtl: language === "fa" ? true : language === "en" ? false : true,
            draggable: true,
            progress: undefined,
            theme: theme,
          });
          SubmitLogin.style.opacity = "1";
          handleClick(false);
          SubmitLogin.innerHTML = language_json[language]["login"];
        }
      } else {
        toast.error(language_json[language]["err_email_format"], {
          position:
            language === "fa"
              ? "bottom-right"
              : language === "en"
              ? "bottom-left"
              : "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: language === "fa" ? true : language === "en" ? false : true,
          draggable: true,
          progress: undefined,
          theme: theme,
        });
      }
    } else {
      toast.error(language_json[language]["err_email_empty"], {
        position:
          language === "fa"
            ? "bottom-right"
            : language === "en"
            ? "bottom-left"
            : "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: language === "fa" ? true : language === "en" ? false : true,
        draggable: true,
        progress: undefined,
        theme: theme,
      });
    }
  };
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const { referral_id } = useParams();
  var referral_id_sotrage = localStorage.getItem("referral_id");
  if (referral_id_sotrage == null) {
    if (referral_id != null) {
      localStorage.setItem("referral_id", referral_id);
    }
  }

  const handleClick = (boly) => {
    setButtonDisabled(boly);
  };
  return (
    <div id="loginWraper" className={styled.loginWraper}>
      <div className={styled.loginText}>
        <div id="icons" className={styled.icon}>
          <button
            onClick={changeTheme}
            id="moonbutton"
            className={styled.moonbutton}
          >
            <Moon className={styled.moon} />
          </button>
          <button
            onClick={changeTheme}
            id="sunbutton"
            className={styled.sunbutton}
          >
            <Sun1 className={styled.Sun1} />
          </button>
          {
            <LanguageSquare
              className={styled.LanguageSquare}
              onClick={changeLanguage}
            />
          }
        </div>
        <div id="text" className={styled.text}>
          <div id="gradient" className={styled.gradient}>
            <h1 className={styled.TextClassy}>
              {language_json[language]["login_to"]} <br />
              <span>{language_json[language]["app_name"]}</span>
            </h1>
          </div>
          <h5 className={styled.TextClassy}>
            {language_json[language]["login_with_email_address"]}
          </h5>
          <div id="input" className={styled.input}>
            <Sms style={{ zIndex: 99 }} color="var(--Text)" id="sms-icon" />
            <input
              id="email-input"
              type="Email"
              placeholder="Yourname@gmail.com"
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
          </div>
          <br />
          <div>
            <button
              id="SubmitLogin"
              className={styled.button}
              onClick={SubmitLogin}
              disabled={isButtonDisabled}
            >
              {language_json[language]["login"]}
            </button>
            <ToastContainer />
          </div>
          <div id="tohi" className={styled.tohi}></div>
          <h6 style={{ display: "none" }}>
            {language_json[language]["or_continue_with"]}
          </h6>
          <div
            style={{ display: "none" }}
            id="Google"
            to="/completion"
            className={styled.google}
          >
            <img src={img2} />
            <Link to="/completion" id="Google-text">
              Google
            </Link>
          </div>
          <div className={styled.textEnd}>
            <h6 className={styled.TextClassy}>
              {language_json[language]["agree_our_terms_and_conditions"]}
            </h6>
          </div>
        </div>
      </div>
      <div id="img" className={styled.loginImg}>
        <div id="loginImgGrad" className={styled.loginImgGrad}></div>
        <div>
          <h2 id="solgan">{language_json[language]["slogan"]}</h2>
        </div>
      </div>
    </div>
  );
}
export default Login;
