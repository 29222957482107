import React, { useState, useEffect } from "react";
import Describing from "../../component/menu/Menu";
import Loading from "../../component/loading/Loading";
import menulight from "../../assets/img/menu-light.png";
import menudark from "../../assets/img/menu-dark.png";
import base_link from "../../config/config";

import styled from "./Account.module.css";
import Courseinfoimg from "../../assets/img/Swiperimg.jpg";
import alirezaa from "../../assets/img/alirezaa.png";
import { Link } from "react-router-dom";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { user_info } from "../../component/api/user_info";
import { referral_detail } from "../../component/api/referral_detail";

import { ArrowSquareLeft, Clock, Copy, Export, Profile } from "iconsax-react";
import { Height } from "@mui/icons-material";
import { borderRadius } from "@mui/system";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var language_json = JSON.parse(localStorage.getItem("language"));
const token = localStorage.getItem("token");
function gtWithdraw() {
  window.location.href = "/withdraw";
}
function gtHistoryWithdraw() {
  window.location.href = "/withdraw_history";
}
async function userinfo() {
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "dashboard" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "patterns" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "general" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "academy" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "account" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "withdraw" ||
    window.location.pathname
      .toLowerCase()
      .replace("/", "")
      .replace("/", "")
      .slice(0, 10) === "signalinfo"
  ) {
    const user_info_data = await user_info(token);
    if (user_info_data[1] !== 200) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return user_info_data;
  } else {
    return null;
  }
}

async function referral_details() {
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
    "account"
  ) {
    const referral_detail_data = await referral_detail(token);
    if (referral_detail_data[1] !== 200) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return referral_detail_data;
  } else {
    return null;
  }
}

const user_info_data = await userinfo();
const referral_detail_data = await referral_details();

function Accont() {
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState("fa");
  const [theme, setTheme] = useState("light");
  const [copied, setCopied] = useState(false);
  const [firstDirection, setFirstDirection] = useState(false);

  if (!firstDirection) {
    document.documentElement.style.setProperty("--direction", "ltr");
    document.documentElement.style.setProperty("--direction-ref", "rtl");
    setFirstDirection(true);
  }

  const handleCopyText = (text) => {
    toast.success(language_json[language]["copied"], {
      position:
        language === "fa"
          ? "bottom-right"
          : language === "en"
          ? "bottom-left"
          : "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      rtl: language === "fa" ? true : language === "en" ? false : true,
      draggable: true,
      progress: undefined,
      theme: theme,
    });
    navigator.clipboard
      .writeText(text)
      .then(() => setCopied(true))
      .catch((error) => console.error("خطا در کپی متن:", error));
  };

  const changeLanguage = () => {
    const newLanguage = language === "fa" ? "en" : "fa";
    var SidebarControl = document.getElementById("menucontrol");

    localStorage.setItem("select_language", newLanguage);
    if (newLanguage === "fa") {
      SidebarControl.style.direction = "rtl";
      document.documentElement.style.setProperty("--font", "var(--fa-font)");
      document.documentElement.style.setProperty("--direction", "ltr");
      document.documentElement.style.setProperty("--direction-ref", "rtl");
    } else if (newLanguage === "en") {
      SidebarControl.style.direction = "ltr";
      document.documentElement.style.setProperty("--font", "var(--en-font)");
      document.documentElement.style.setProperty("--direction", "rtl");
      document.documentElement.style.setProperty("--direction-ref", "ltr");
    }
    setLanguage(newLanguage);
    // general_analysisCon(newLanguage);
  };
  const changeTheme = () => {
    var moonbutton = document.getElementById("moonbutton");
    var sunbutton = document.getElementById("sunbutton");
    var menuimg = document.getElementById("menuimg");
    var tickerTape = document.getElementById("tickerTape");

    const newtheme = theme === "light" ? "dark" : "light";

    tickerTape.src = `${base_link}/ticker_tape/${newtheme}`;

    localStorage.setItem("select_theme", newtheme);
    if (newtheme === "light") {
      sunbutton.style.display = "inline";
      moonbutton.style.display = "none";

      menuimg.src = menulight;

      document.documentElement.style.setProperty("--Text", "var(--light-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--light-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--light-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--light-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--light-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--light-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--light-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--light-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--light-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--light-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--light-code-img)"
      );
    } else if (newtheme === "dark") {
      moonbutton.style.display = "inline";
      sunbutton.style.display = "none";

      menuimg.src = menudark;

      document.documentElement.style.setProperty("--Text", "var(--dark-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--dark-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--dark-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--dark-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--dark-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--dark-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--dark-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--dark-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--dark-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--dark-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--dark-code-img)"
      );
    }
    setTheme(newtheme);
  };
  useEffect(() => {
    const selectLanguage = localStorage.getItem("select_language");
    const selectTheme = localStorage.getItem("select_theme");
    if (selectLanguage === "en") {
      changeLanguage();
    }
    if (selectTheme === "dark") {
      changeTheme();
    }
    if (selectLanguage !== "en") {
      //   general_analysisCon(language);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  const iframeSrc = `${base_link}/ticker_tape/light`;

  const tdStyleHuman = {};
  const tdStyleprofandemail = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  };
  const tdStyleProfiles = { height: "35px", borderRadius: "5px" };

  return (
    <div id="signalWraper" className={styled.signalWraper}>
      <div className={styled.main}>
        <div className={styled.navbarTap}>
          <iframe id="tickerTape" src={iframeSrc} title="Ticker Tape" />
        </div>

        <div className={styled.arrow}>
          <ArrowSquareLeft size="20" color="var(--Text)" />
          <h2>{language_json[language]["account"]}</h2>
        </div>
        <div id="account" className={styled.Accont}>
          <div className={styled.Accontimg}>
            <img src={user_info_data[0]["data"]["profile_pic"]} />
          </div>
          <div className={styled.Accontbox}>
            <h3>{language_json[language]["your_name"]}</h3>
            <div className={styled.Accontname}>
              <div className={styled.Accontnamer}>
                <h4>{user_info_data[0]["data"]["name"]}</h4>
              </div>
              <div className={styled.Accontnamel}>
                {/* <div className={styled.Accontedit}>
                  <h4>ویرایش</h4>
                </div> */}
              </div>
            </div>
            <h3>{language_json[language]["your_email"]}</h3>
            <div className={styled.Accontemail}>
              <div className={styled.Accontemailr}>
                <h4>{user_info_data[0]["data"]["email"]}</h4>
              </div>
              <div className={styled.Accontemaill}>
                {/* <div className={styled.Accontedit}>
                  <h4>ویرایش</h4>
                </div> */}
              </div>
            </div>
          </div>
          <div className={styled.Accontplan}>
            <div className={styled.Accontplanr}>
              <h3>{language_json[language]["plans"]} :</h3>
            </div>
            <div className={styled.Accontplanl}>
              <h3>
                {user_info_data[0]["data"]["daysleft"]}{" "}
                {language_json[language]["days_left"]}
              </h3>
            </div>
          </div>
          <div
            onClick={() =>
              handleCopyText(user_info_data[0]["data"]["referral_id_link"])
            }
            className={styled.Accontlink}
          >
            <div className={styled.Accontlinkr}>
              <h3>{language_json[language]["referral_link"]} :</h3>
            </div>
            <div className={styled.Accontlinkl}>
              <h3>{user_info_data[0]["data"]["referral_id"]}</h3>
              <Copy size="19" color="var(--Text)" />
            </div>
          </div>
          <div className={styled.Accontinventory}>
            <div className={styled.Accontinventoryr}>
              <h3>{language_json[language]["balance"]} :</h3>
            </div>
            <div className={styled.Accontinventoryl}>
              <h3>
                {user_info_data[0]["data"]["balance"].toLocaleString()}{" "}
                {language_json[language]["usdt"]}
              </h3>
            </div>
          </div>
          <div className={styled.Accontbut}>
            <div onClick={gtWithdraw} className={styled.Accontharvest}>
              <h3>{language_json[language]["withdraw"]}</h3>
              <Export size="20" color="#FFFFFF" />
            </div>
            <div onClick={gtHistoryWithdraw} className={styled.Acconthistory}>
              <h3>{language_json[language]["withdraw_history"]}</h3>
              <Clock size="20" color="#FFFFFF" />
            </div>
          </div>
          <div className={styled.income}>
            <h3>{language_json[language]["earnings"]}</h3>
          </div>
          <table>
            <tr className={styled.income1}>
              <th>{language_json[language]["grade"]} 1</th>
              <th>{language_json[language]["grade"]} 2</th>
              <th>{language_json[language]["grade"]} 3</th>
              <th>{language_json[language]["all"]}</th>
            </tr>
            <tr>
              <td style={tdStyleHuman}>
                {referral_detail_data[0]["data"]["simple"]["step_1"]["count"]}
                <Profile size="15" color="#7E42FF" />
              </td>
              <td style={tdStyleHuman}>
                {referral_detail_data[0]["data"]["simple"]["step_2"]["count"]}
                <Profile size="15" color="#7E42FF" />
              </td>
              <td style={tdStyleHuman}>
                {referral_detail_data[0]["data"]["simple"]["step_3"]["count"]}
                <Profile size="15" color="#7E42FF" />
              </td>
              <td style={tdStyleHuman}>
                {referral_detail_data[0]["data"]["simple"]["total"]["count"]}
                <Profile size="15" color="#7E42FF" />
              </td>
            </tr>
            <tr>
              <td>
                {referral_detail_data[0]["data"]["simple"]["step_1"]["earn"]}{" "}
                {language_json[language]["usdt"]}
              </td>
              <td>
                {referral_detail_data[0]["data"]["simple"]["step_2"]["earn"]}{" "}
                {language_json[language]["usdt"]}
              </td>
              <td>
                {referral_detail_data[0]["data"]["simple"]["step_3"]["earn"]}{" "}
                {language_json[language]["usdt"]}
              </td>
              <td>
                {referral_detail_data[0]["data"]["simple"]["total"]["earn"]}{" "}
                {language_json[language]["usdt"]}
              </td>
            </tr>
          </table>
          <div className={styled.income}>
            <h3>{language_json[language]["earning_per_user"]}</h3>
          </div>
          <table>
            <tr className={styled.income1}>
              <th>{language_json[language]["email"]} </th>
              <th>{language_json[language]["earn"]} </th>
              <th>{language_json[language]["invited"]} </th>
              <th>{language_json[language]["name"]} </th>
            </tr>
            {referral_detail_data[0]["data"]["details"].map((item, index) => (
              <tr key={index}>
                <td style={tdStyleprofandemail}>
                  <img
                    style={tdStyleProfiles}
                    src={item.user.profile_pic}
                    alt=""
                  />{" "}
                  {String(item.email).substring(0, 4) +
                    "..." +
                    String(item.email).substring(String(item.email).length - 4)}
                </td>
                <td>
                  {item.earn} {language_json[language]["usdt"]}
                </td>
                <td style={tdStyleHuman}>
                  {item.count}
                  <Profile size="15" color="#7E42FF" />
                </td>
                <td>{item.user.name}</td>
              </tr>
            ))}
          </table>
        </div>

        <ToastContainer />
      </div>
      <Describing
        changeLanguage={changeLanguage}
        language={language}
        changeTheme={changeTheme}
        theme={theme}
      />
      <Loading active={isLoading} />
    </div>
  );
}
export default Accont;
