import axios from "axios";
import base_url from "../../config/config";

const fetchDataFromLink = async (link, data, headers) => {
  try {
    const response = await axios.post(link, data, { headers });
    const result = response.data;
    return [result, response.status];
  } catch (error) {
    return [error["response"]["data"], error["response"]["status"]];
  }
};

export const buy_plan_api = async (
  token,
  txid,
  for_what,
  plan_name,
  plan_index
) => {
  const headers = {
    "X-TOKEN": token,
    "Content-Type": "multipart/form-data; charset=utf-8",
  };
  const formData = new FormData();
  formData.append("txid", txid);
  formData.append("for_what", for_what);
  formData.append("plan_name", plan_name);
  formData.append("plan_index", plan_index);

  const data = await fetchDataFromLink(
    `${base_url}/buy_plan`,
    formData,
    headers
  );
  return data;
};

export default buy_plan_api;
