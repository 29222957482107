import axios from 'axios';
import base_url from "../../config/config";

const fetchDataFromLink = async (link, data,headers) => {
  try {
    const response = await axios.post(link, data,{headers});
    const result = response.data;
    return [result, response.status];
  } catch (error) {
    return [error['response']['data']['error'], error['response']['status']];
  }
};

export const withdraw_api = async (token,address,amount) => {
  const formData = new FormData();
  const headers ={'X-TOKEN': token,'Content-Type':'multipart/form-data; charset=utf-8'};
  formData.append('address', address);
  formData.append('amount', amount);
  const data = await fetchDataFromLink(`${base_url}/withdraw`, formData,headers);
  return data;
};

export default withdraw_api;