import styled from "./Completion.module.css";
import { Link } from "react-router-dom";
import { Profile } from "iconsax-react";
import { Sun1 } from "iconsax-react";
import { Moon } from "iconsax-react";
import { Add } from "iconsax-react";
import { LanguageSquare } from "iconsax-react";
import profil from "../../assets/img/profile.jpg";
import React, { useState,useEffect,useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {completion_api} from "../../component/api/completion.jsx";

var language_json = JSON.parse(localStorage.getItem('language'));

function Completion() {
  const [language, setLanguage] = useState('fa');
  const [theme, setTheme] = useState('light');
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const token = localStorage.getItem('token');
  const email_address = localStorage.getItem('email');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const fileInputRef = useRef(null);
  if(token === null || token === ""){
    window.location.href = "/Login";
  }
  if(email_address === null || email_address === ""){
    window.location.href = "/Login";
  }
  const handleClick = (boly) => {
    setButtonDisabled(boly);
  };
  const SubmitCompletion = async () => {
    var inputName = document.getElementById("inputName");
    var SubmitCompletion = document.getElementById("SubmitCompletion");
    const name = inputName.value;
    if(name !== "" && name !== null){
      SubmitCompletion.innerHTML = language_json[language]['loading'];
        SubmitCompletion.style.opacity = "0.5";
        handleClick(true);
        var referral_id = localStorage.getItem("referral_id");
        
        const completion_api_data = await completion_api(token,name,selectedImageFile,referral_id);
        if(completion_api_data[1] === 200){
          toast.success(language_json[language][completion_api_data[0]['message']], {
            position: language==="fa"?"bottom-right":language==="en"?"bottom-left":"bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            rtl: language==="fa"?true:language==="en"?false:true,
            draggable: true,
            progress: undefined,
            theme: theme,
          });
          localStorage.removeItem('email');
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 2000);
        }else if(completion_api_data[1] === 406){
          toast.error(language_json[language]['err_code_is_wrong'], {
            position: language==="fa"?"bottom-right":language==="en"?"bottom-left":"bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            rtl: language==="fa"?true:language==="en"?false:true,
            draggable: true,
            progress: undefined,
            theme: theme,
            });
            SubmitCompletion.style.opacity = "1";
            handleClick(false);
            SubmitCompletion.innerHTML = language_json[language]['sign_up'];
        }else if(completion_api_data[1] === 401){
          toast.error(language_json[language]['err_token_has_expired'], {
            position: language==="fa"?"bottom-right":language==="en"?"bottom-left":"bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            rtl: language==="fa"?true:language==="en"?false:true,
            draggable: true,
            progress: undefined,
            theme: theme,
            });
            SubmitCompletion.style.opacity = "1";
            handleClick(false);
            SubmitCompletion.innerHTML = language_json[language]['check'];
            localStorage.removeItem('email');
            localStorage.removeItem('token');
            setTimeout(() => {
              window.location.href = "/login";
            }, 2000);
        }
      
    }else{
      toast.error(language_json[language]['err_name_empty'], {
        position: language==="fa"?"bottom-right":language==="en"?"bottom-left":"bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: language==="fa"?true:language==="en"?false:true,
        draggable: true,
        progress: undefined,
        theme: theme,
        });
    }
  }
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setSelectedImageFile(file);
    reader.onloadend = () => {
      setSelectedImage(reader.result);
      document.getElementById("profile").src = reader.result;
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleAddClick = () => {
    fileInputRef.current.click();
  };
  const changeLanguage = () => {
    var codeImgGrad = document.getElementById("codeImgGrad");
    var CompletionWraper = document.getElementById("CompletionWraper");
    var Completiontext = document.getElementById("Text");
    var solgan = document.getElementById("solgan");
    var inputName = document.getElementById("inputName");
    var icons = document.getElementById("icons");

    const newLanguage = language === 'fa' ? 'en' : 'fa';

    localStorage.setItem('select_language', newLanguage);

    if(newLanguage == 'fa'){
      codeImgGrad.style.background = `var(--Gradient)`;
      CompletionWraper.style.flexDirection = "row";
      Completiontext.style.direction = "rtl";
      inputName.style.paddingRight="12%";
      inputName.style.paddingLeft="0%";
      solgan.style.left = "auto";
      solgan.style.right = "30px";
      icons.style.left = "0px";
      icons.style.right = "auto"
      document.documentElement.style.setProperty('--font', 'var(--fa-font)');

    }else if(newLanguage == 'en'){
      codeImgGrad.style.background = `var(--Gradient-reverse)`;
      CompletionWraper.style.flexDirection = "row-reverse";
      Completiontext.style.direction = "ltr";
      inputName.style.paddingLeft="12%";
      inputName.style.paddingRight="0%";
      solgan.style.left = "30px";
      solgan.style.right = "auto";
      icons.style.left = "auto";
      icons.style.right = "0px";
      document.documentElement.style.setProperty('--font', 'var(--en-font)');

    }
    setLanguage(newLanguage);
  };
  const changeTheme = () => {
    var moonbutton = document.getElementById("moonbutton");
    var sunbutton = document.getElementById("sunbutton");

    const newtheme = theme === 'light' ? 'dark' : 'light';
    localStorage.setItem('select_theme', newtheme);

    if (newtheme == "light") {
      sunbutton.style.display = "inline";
      moonbutton.style.display = "none";

      document.documentElement.style.setProperty('--Text', 'var(--light-Text)');
      document.documentElement.style.setProperty('--Subtext', 'var(--light-Subtext)');
      document.documentElement.style.setProperty('--Primary', 'var(--light-Primary)');
      document.documentElement.style.setProperty('--Dialog', 'var(--light-Dialog)');
      document.documentElement.style.setProperty('--Secondary', 'var(--light-Secondary)');
      document.documentElement.style.setProperty('--Interactive', 'var(--light-Interactive)');
      document.documentElement.style.setProperty('--Stroke', 'var(--light-Stroke)');
      document.documentElement.style.setProperty('--ButtonText', 'var(--light-ButtonText)');
      document.documentElement.style.setProperty('--Gradient', 'var(--light-Gradient)');
      document.documentElement.style.setProperty('--Gradient-reverse', `var(--light-Gradient${language=="en"?"-reverse":""})`);

      document.documentElement.style.setProperty('--completion-img', 'var(--light-completion-img)');
    } else if (newtheme == "dark"){
      moonbutton.style.display = "inline";
      sunbutton.style.display = "none";

      document.documentElement.style.setProperty('--Text', 'var(--dark-Text)');
      document.documentElement.style.setProperty('--Subtext', 'var(--dark-Subtext)');
      document.documentElement.style.setProperty('--Primary', 'var(--dark-Primary)');
      document.documentElement.style.setProperty('--Dialog', 'var(--dark-Dialog)');
      document.documentElement.style.setProperty('--Secondary', 'var(--dark-Secondary)');
      document.documentElement.style.setProperty('--Interactive', 'var(--dark-Interactive)');
      document.documentElement.style.setProperty('--Stroke', 'var(--dark-Stroke)');
      document.documentElement.style.setProperty('--ButtonText', 'var(--dark-ButtonText)');
      document.documentElement.style.setProperty('--Gradient', 'var(--dark-Gradient)');
      document.documentElement.style.setProperty('--Gradient-reverse', `var(--dark-Gradient${language=="en"?"-reverse":""})`);

      document.documentElement.style.setProperty('--completion-img', 'var(--dark-completion-img)');
    }
    setTheme(newtheme);
  };
  useEffect(() => {
    const selectLanguage = localStorage.getItem('select_language');
    const selectTheme = localStorage.getItem('select_theme');

    if (selectLanguage === 'en') {
      changeLanguage();
    }
    if (selectTheme === 'dark') {
      changeTheme();
    }
  }, []);
  return (
    <div id="CompletionWraper" className={styled.CompletionWraper}>
      <div className={styled.CompletionText}>
        <div id="icons" className={styled.icon}>
        <button
            onClick={changeTheme}
            id="sunbutton"
            className={styled.sunbutton}
          >
            <Sun1 className={styled.Sun1} />
          </button>
          <button
            onClick={changeTheme}
            id="moonbutton"
            className={styled.moonbutton}
          >
            <Moon className={styled.moon} />
          </button>
          <LanguageSquare onClick={changeLanguage} className={styled.LanguageSquare} />
        </div>
        <div id="Text" className={styled.Text}>
          <h1 className={styled.gradient}>
          {language_json[language]['completion_of_information']}
            <br />
            <div className={styled.profil}>
              <img onClick={handleAddClick} id="profile" src={profil}/>
              <div style={{cursor: "pointer"}} onClick={handleAddClick} className={styled.add}><Add size="20"/></div>
              <input type="file" accept="image/*" style={{display:"none"}} ref={fileInputRef} onChange={handleImageChange} />
            </div>
            <span>{email_address}</span>
          </h1>

          <h6>{language_json[language]['enter_your_name']}</h6>

          <div className={styled.input}>
            <Profile style={{zIndex:99}} className={styled.sms} />
            <input id="inputName" type="Email" placeholder={language_json[language]['enter_your_name']} />
          </div>

          <div className={styled.button}>
              <button id="SubmitCompletion" onClick={SubmitCompletion}>{language_json[language]['sign_up']}</button>
          </div>
        </div>
      </div>

      <div className={styled.CodeImg}>
      <div id="codeImgGrad" className={styled.CodeImggGrad}></div>
        <h2 id="solgan">
        {language_json[language]['slogan']}
        </h2>
      </div>
      <ToastContainer/>

    </div>
  );
}
export default Completion;
