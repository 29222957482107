import React, { useState, useEffect } from "react";
import Describing from "../../component/menu/Menu";
import Loading from "../../component/loading/Loading";
import menulight from "../../assets/img/menu-light.png";
import menudark from "../../assets/img/menu-dark.png";
import base_link from "../../config/config";
import { LanguageSquare } from "iconsax-react";
import styled from "./Landing.module.css";
import coin from "../../assets/img/coin.png";
import line2 from "../../assets/img/line2.png";
import logo from "../../assets/img/logo.png";
import Vector from "../../assets/img/Vector.png";
import google from "../../assets/img/google.png";
import App from "../../assets/img/App.png";
import introduce from "../../assets/img/introducePart.png";
import subscribe from "../../assets/img/subscribe.png";
import Blob from "../../assets/img/Blob.png";
import layer from "../../assets/img/layer.png";
import Video from "../../assets/img/Video.png";
import sood1 from "../../assets/img/soods/1.png";
import sood2 from "../../assets/img/soods/2.png";
import sood3 from "../../assets/img/soods/3.png";
import sood4 from "../../assets/img/soods/4.png";

import nazar1 from "../../assets/img/nazar/1.png";
import nazar2 from "../../assets/img/nazar/2.png";
import nazar3 from "../../assets/img/nazar/3.png";
import nazar4 from "../../assets/img/nazar/4.png";
import nazar5 from "../../assets/img/nazar/5.png";
import nazar6 from "../../assets/img/nazar/6.png";
import nazar7 from "../../assets/img/nazar/7.png";

import mobile from "../../assets/img/mobile.png";
import reportPart from "../../assets/img/reportPart.png";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import { FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
function gtLogin() {
  window.location.href = "/dashboard";
}
function gtGoogle() {
  window.location.href =
    "https://backyard.signalcurrency.com/app/1/SignalCurrency.apk";
}
function gtAppStore() {
  window.location.href = "https://webapp.signalcurrency.com/";
}
var language_json = JSON.parse(localStorage.getItem("language"));
function Landing() {
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState("fa");
  const [theme, setTheme] = useState("light");
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
    ""
  ) {
    document.body.style.setProperty("overflow", "auto", "important");
  }
  const changeLanguage = () => {
    console.log("changed");
    const newLanguage = language === "fa" ? "en" : "fa";

    // var SidebarControl = document.getElementById("menucontrol");

    localStorage.setItem("select_language", newLanguage);
    if (newLanguage == "fa") {
      // SidebarControl.style.direction = "rtl";
      document.documentElement.style.setProperty("--font", "var(--fa-font)");
      // document.documentElement.style.setProperty("--direction", "ltr");
      // document.documentElement.style.setProperty("--direction-ref", "rtl");
    } else if (newLanguage == "en") {
      // SidebarControl.style.direction = "ltr";
      document.documentElement.style.setProperty("--font", "var(--en-font)");
      // document.documentElement.style.setProperty("--direction", "rtl");
      // document.documentElement.style.setProperty("--direction-ref", "ltr");
    }
    setLanguage(newLanguage);
    // general_analysisCon(newLanguage);
  };
  const changeTheme = () => {
    var moonbutton = document.getElementById("moonbutton");
    var sunbutton = document.getElementById("sunbutton");
    var menuimg = document.getElementById("menuimg");
    var tickerTape = document.getElementById("tickerTape");

    const newtheme = theme === "light" ? "dark" : "light";

    tickerTape.src = `${base_link}/ticker_tape/${newtheme}`;

    localStorage.setItem("select_theme", newtheme);
    if (newtheme === "light") {
      sunbutton.style.display = "inline";
      moonbutton.style.display = "none";

      menuimg.src = menulight;

      document.documentElement.style.setProperty("--Text", "var(--light-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--light-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--light-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--light-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--light-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--light-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--light-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--light-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--light-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--light-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--light-code-img)"
      );
    } else if (newtheme === "dark") {
      moonbutton.style.display = "inline";
      sunbutton.style.display = "none";

      menuimg.src = menudark;

      document.documentElement.style.setProperty("--Text", "var(--dark-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--dark-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--dark-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--dark-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--dark-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--dark-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--dark-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--dark-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--dark-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--dark-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--dark-code-img)"
      );
    }
    setTheme(newtheme);
  };
  useEffect(() => {
    const selectLanguage = localStorage.getItem("select_language");
    const selectTheme = localStorage.getItem("select_theme");
    if (selectLanguage === "en") {
      changeLanguage();
    }
    if (selectTheme === "dark") {
      // changeTheme();
    }
    if (selectLanguage !== "en") {
      //   general_analysisCon(language);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  return (
    <div id="signalWraper" className={styled.signalWraper}>
      <div className={styled.main}>
        <div className={styled.navbarTap}></div>

        <div className={styled.landingWrapper}>
          <div className={styled.navbar}>
            <div className={styled.navbarLeft}>
              <img src={logo} />
            </div>
            <div className={styled.navbarRight}>
              <button onClick={gtLogin} className={styled.navbarLogin}>
                {language_json[language]["landing"]["login"]}
              </button>
              <button onClick={gtLogin} className={styled.navbarRegester}>
                {language_json[language]["landing"]["create_account"]}
              </button>
              <LanguageSquare onClick={changeLanguage} size="35" />
            </div>
          </div>

          <div className={styled.mainPart}>
            <div className={styled.coin}>
              <iframe
                className={styled.coinimg}
                src="https://lottie.host/embed/edc31436-a757-4783-9653-54f2a530ee4d/wU6zLBwN6C.json"
              ></iframe>

              {/* <img src={coin} /> */}
            </div>
            <div className={styled.mainText}>
              <img src={Vector} />
              <h1>{language_json[language]["landing"]["des"][0]}</h1>
              <h1>{language_json[language]["landing"]["des"][1]}</h1>
              <h1>{language_json[language]["landing"]["des"][2]}</h1>
              <div className={styled.mainButton}>
                <button onClick={gtLogin} className={styled.mainLogin}>
                  {language_json[language]["landing"]["login"]}
                </button>
                <button onClick={gtLogin} className={styled.mainRegester}>
                  {language_json[language]["landing"]["create_account"]}
                </button>
              </div>
            </div>
          </div>

          <div className={styled.freePart}>
            <div className={styled.subscribe}>
              {/* <img src={subscribe} /> */}
              <iframe src="https://lottie.host/embed/1cc2805b-5d2d-4d09-94d1-dac5005b4dee/ynrBafa6ri.json"></iframe>
            </div>
            <div className={styled.freeText}>
              <h1>{language_json[language]["landing"]["10_day"][0]}</h1>
              <h1>{language_json[language]["landing"]["10_day"][1]}</h1>
              <button onClick={gtLogin} className={styled.ferrButton}>
                {language_json[language]["landing"]["create_account"]}
              </button>
            </div>
          </div>

          <div className={styled.reportPart}>
            <div className={styled.img}>
              <img src={reportPart} />
            </div>
            <div className={styled.reportPartText}>
              <h1 className={styled.reportPartTextH}>
                {language_json[language]["landing"]["monthly_report"]}
              </h1>
              <div className={styled.reportBox}>
                <Swiper
                  slidesPerView={3}
                  spaceBetween={30}
                  modules={[FreeMode, Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className={styled.box}>
                      <h1>
                        {language_json[language]["landing"]["report"]}{" "}
                        {language_json[language]["landing"]["m_sh_11"]}{" "}
                        {language_json[language]["landing"]["1402"]}
                      </h1>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className={styled.box}>
                      <h1>
                        {language_json[language]["landing"]["report"]}{" "}
                        {language_json[language]["landing"]["m_sh_12"]}{" "}
                        {language_json[language]["landing"]["1402"]}
                      </h1>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className={styled.box}>
                      <h1>
                        {language_json[language]["landing"]["report"]}{" "}
                        {language_json[language]["landing"]["m_sh_1"]}{" "}
                        {language_json[language]["landing"]["1403"]}
                      </h1>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className={styled.box}>
                      <h1>
                        {language_json[language]["landing"]["report"]}{" "}
                        {language_json[language]["landing"]["m_sh_2"]}{" "}
                        {language_json[language]["landing"]["1403"]}
                      </h1>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>

          <div className={styled.introducePart}>
            <div className={styled.introduce}>
              <img className={styled.Blob} src={Blob} />
              {/* <img className={styled.introduceimg} src={introduce} /> */}
              <video
                className={styled.introduceimg}
                src="https://backyard.signalcurrency.com/videos/landvid.mp4"
                controls
              ></video>
            </div>
            <div className={styled.introduceText}>
              <h1>
                {
                  language_json[language]["landing"][
                    "introducing_signal_currency"
                  ]
                }
              </h1>
              <h2>
                <br />
                {language_json[language]["landing"]["introducing_option"][0]}
                <br />
                {language_json[language]["landing"]["introducing_option"][1]}
                <br />
                {language_json[language]["landing"]["introducing_option"][2]}
              </h2>

              <button onClick={gtLogin} className={styled.introducebutton}>
                {language_json[language]["landing"]["create_account"]}
              </button>
            </div>
          </div>

          <div className={styled.earnPart}>
            <div className={styled.layer}>
              <img className={styled.layer} src={layer} />
            </div>

            <div className={styled.earnMain}>
              <h1>
                {language_json[language]["landing"]["have_a_dollar_income"]}
              </h1>
              <h2>
                {language_json[language]["landing"]["click_for_dollar_income"]}
              </h2>
              <button className={styled.earnbutton}>
                {language_json[language]["landing"]["know_more"]}
              </button>
            </div>

            <div className={styled.layer}>
              <img className={styled.layer} src={layer} />
            </div>
          </div>

          <div className={styled.comments}>
            <div className={styled.commentsIcom}>
              <div className={styled.icom}></div>
              <div className={styled.text}>
                <h1>{language_json[language]["landing"]["video_comments"]}</h1>
              </div>
            </div>

            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              modules={[FreeMode, Pagination]}
              className="mySwiper"
              style={{ margin: 20 }}
              breakpoints={{
                200: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
                1500: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
            >
              <SwiperSlide>
                <img style={{ height: "20vh" }} src={nazar1} />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: "20vh" }} src={nazar2} />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: "20vh" }} src={nazar3} />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: "20vh" }} src={nazar4} />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: "20vh" }} src={nazar5} />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: "20vh" }} src={nazar6} />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: "20vh" }} src={nazar7} />
              </SwiperSlide>
            </Swiper>
          </div>

          <div div className={styled.download}>
            <div div className={styled.mobile}>
              <img className={styled.line2} src={line2} />
              <img className={styled.mobileimg} src={mobile} />
            </div>

            <div div className={styled.downloadtext}>
              <h1>
                {language_json[language]["landing"]["download_the_application"]}
              </h1>
              <div div className={styled.downloadBox}>
                <div div className={styled.app}>
                  <img onClick={gtAppStore} src={App} />
                </div>
                <div div className={styled.google}>
                  <img onClick={gtGoogle} src={google} />
                </div>
              </div>
            </div>
          </div>

          <div div className={styled.profit}>
            <div className={styled.commentsIcom}>
              <div className={styled.icom}></div>
              <div className={styled.text}>
                <h1> {language_json[language]["landing"]["profits"]}</h1>
              </div>
            </div>

            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              modules={[FreeMode, Pagination]}
              className="mySwiper"
              style={{ margin: 20 }}
              breakpoints={{
                200: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
                1500: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
            >
              <SwiperSlide>
                <img style={{ height: "20vh" }} src={sood1} />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: "20vh" }} src={sood2} />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: "20vh" }} src={sood3} />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: "20vh" }} src={sood4} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      {/* <Describing
        changeLanguage={changeLanguage}
        language={language}
        changeTheme={changeTheme}
        theme={theme}
      />
      <Loading active={isLoading} /> */}
    </div>
  );
}
export default Landing;
