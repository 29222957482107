
import React, { useState, useEffect } from "react";
import Describing from "../../component/menu/Menu";
import Loading from "../../component/loading/Loading";
import menulight from "../../assets/img/menu-light.png";
import menudark from "../../assets/img/menu-dark.png";
import base_link from "../../config/config";

import styled from "./Courseinfo.module.css";
import Courseinfoimg from "../../assets/img/Swiperimg.jpg";
import "swiper/css";
import { Link } from "react-router-dom";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { ArrowSquareLeft } from "iconsax-react";

var language_json = JSON.parse(localStorage.getItem("language"));
const token = localStorage.getItem("token");

function Courseinfo() {
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState("fa");
  const [theme, setTheme] = useState("light");
  const changeLanguage = () => {
    const newLanguage = language === "fa" ? "en" : "fa";

    var SidebarControl = document.getElementById("menucontrol");

    localStorage.setItem("select_language", newLanguage);
    if (newLanguage == "fa") {
      SidebarControl.style.direction = "rtl";
      document.documentElement.style.setProperty("--font", "var(--fa-font)");
      document.documentElement.style.setProperty("--direction", "ltr");
      document.documentElement.style.setProperty("--direction-ref", "rtl");
    } else if (newLanguage == "en") {
      SidebarControl.style.direction = "ltr";
      document.documentElement.style.setProperty("--font", "var(--en-font)");
      document.documentElement.style.setProperty("--direction", "rtl");
      document.documentElement.style.setProperty("--direction-ref", "ltr");
    }
    setLanguage(newLanguage);
    // general_analysisCon(newLanguage);
  };
  const changeTheme = () => {
    var moonbutton = document.getElementById("moonbutton");
    var sunbutton = document.getElementById("sunbutton");
    var menuimg = document.getElementById("menuimg");
    var tickerTape = document.getElementById("tickerTape");

    const newtheme = theme === "light" ? "dark" : "light";

    tickerTape.src = `${base_link}/ticker_tape/${newtheme}`;

    localStorage.setItem("select_theme", newtheme);
    if (newtheme === "light") {
      sunbutton.style.display = "inline";
      moonbutton.style.display = "none";

      menuimg.src = menulight;

      document.documentElement.style.setProperty("--Text", "var(--light-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--light-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--light-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--light-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--light-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--light-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--light-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--light-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--light-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--light-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--light-code-img)"
      );
    } else if (newtheme === "dark") {
      moonbutton.style.display = "inline";
      sunbutton.style.display = "none";

      menuimg.src = menudark;

      document.documentElement.style.setProperty("--Text", "var(--dark-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--dark-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--dark-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--dark-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--dark-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--dark-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--dark-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--dark-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--dark-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--dark-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--dark-code-img)"
      );
    }
    setTheme(newtheme);
  };
  useEffect(() => {
    const selectLanguage = localStorage.getItem("select_language");
    const selectTheme = localStorage.getItem("select_theme");
    if (selectLanguage === "en") {
      changeLanguage();
    }
    if (selectTheme === "dark") {
      changeTheme();
    }
    if (selectLanguage !== "en") {
      //   general_analysisCon(language);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);



  const iframeSrc = `${base_link}/ticker_tape/light`;


  return (
    <div id="CourseInfoWraper" className={styled.CourseInfoWraper}>
      <div className={styled.main}>
        <div className={styled.navbarTap}>
          <iframe id="tickerTape" src={iframeSrc} title="Ticker Tape" />
        </div>

        
        <div className={styled.Courseinfotitle}>
            <h1>{language_json[language]['lorem_title']}</h1>
        </div>
        <div className={styled.Courseinfomain}>
            <h4>{language_json[language]['lorem_des']}</h4>
            <div className={styled.Courseinfoprof}>
               <img src={Courseinfoimg} />
               <h5>@Mojipipel</h5>
            </div>
            <button>{language_json[language]['buy']}</button>
            <h2>{language_json[language]['bought']}</h2>
        </div>
        <h4 className={styled.Courseinfoh4}>{language_json[language]['course']}</h4>
        <div className={styled.Courseinfobox}>
          <div className={styled.Courseinfobox1}>
            <div className={styled.Courseinforight}>
            {language_json[language]['test_title']}
            </div>
            <div className={styled.Courseinfoleft}>
               <ArrowSquareLeft size="20" color="var(--Subtext)"/>
            </div>
          </div>
          <div className={styled.Courseinfobox1}>
            <div className={styled.Courseinforight}>
            {language_json[language]['test_title']}
            </div>
            <div className={styled.Courseinfoleft}>
               <ArrowSquareLeft size="20" color="var(--Subtext)"/>
            </div>
          </div>
          <div className={styled.Courseinfobox1}>
            <div className={styled.Courseinforight}>
            {language_json[language]['test_title']}
            </div>
            <div className={styled.Courseinfoleft}>
               <ArrowSquareLeft size="20" color="var(--Subtext)"/>
            </div>
          </div>
        </div>


      </div>
      <Describing
        changeLanguage={changeLanguage}
        language={language}
        changeTheme={changeTheme}
        theme={theme}
      />
      <Loading active={isLoading} />
    </div>
  );
}
export default Courseinfo;
