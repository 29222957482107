import styled from "./Menu.module.css";
import { ChartCircle } from "iconsax-react";
import { MessageQuestion } from "iconsax-react";
import { Diagram } from "iconsax-react";
import { LogoutCurve } from "iconsax-react";
import { BuyCrypto } from "iconsax-react";
import { Link } from "react-router-dom";
import { LanguageSquare } from "iconsax-react";
import { Home, Teacher, UserSquare, UserOctagon } from "iconsax-react";
import { Notification } from "iconsax-react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import menulight from "../../assets/img/menu-light.png";
import React from "react";
import { Sun1 } from "iconsax-react";
import { Moon, ChartSquare, Add, Export, Moneys } from "iconsax-react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "react-toastify/dist/ReactToastify.css";
import { user_info } from "../../component/api/user_info";

var language_json = JSON.parse(localStorage.getItem("language"));
const token = localStorage.getItem("token");
function gtAccount() {
  window.location.href = "/account";
}
function gtWithdraw() {
  window.location.href = "/withdraw";
}
function gtPlans() {
  window.location.href = "/plans";
}
async function userinfo() {
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "dashboard" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "patterns" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "general" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "academy" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "account" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "withdraw" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "withdraw_history" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "plans" ||
    window.location.pathname
      .toLowerCase()
      .replace("/", "")
      .replace("/", "")
      .slice(0, 10) === "signalinfo" ||
    window.location.pathname
      .toLowerCase()
      .replace("/", "")
      .replace("/", "")
      .slice(0, 10) === "courseinfo"
  ) {
    if (window.innerWidth < 990) {
      window.location.href = "https://webapp.signalcurrency.com/#/home";
    }
    const user_info_data = await user_info(token);
    if (user_info_data[1] !== 200) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return user_info_data;
  } else {
    return null;
  }
}
const user_info_data = await userinfo();
function logout() {
  localStorage.removeItem("token");
  window.location.href = "/login";
}
function Describing({ changeLanguage, language, changeTheme, theme }) {
  return (
    <div className={styled.menuWraper}>
      <div className={styled.dashboard}>
        {/* <div className={styled.collapsController}>
          <ArrowLeft2/>
        </div> */}
        <div>
          <h1>{language_json[language]["app_name_meno"]}</h1>
        </div>
        <div id="menucontrol" className={styled.menu}>
          <h6 id="menutitle">{language_json[language]["menu"]}</h6>
          <Sidebar id="SidebarControl">
            <Menu>
              <Link to="/dashboard">
                <MenuItem>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Home size="17" />
                    {language_json[language]["dashboard"]}
                  </div>
                </MenuItem>
              </Link>

              <SubMenu
                label={
                  <div style={{ display: "flex", gap: "5px" }}>
                    <ChartSquare size="17" />
                    {language_json[language]["signals"]}
                  </div>
                }
              >
                <div className={styled.submenumargin}>
                  <Link to="/patterns">
                    <MenuItem>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <Diagram size="17" />
                        {language_json[language]["chart_patterns"]}
                      </div>
                    </MenuItem>
                  </Link>

                  <Link to="/general">
                    <MenuItem>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <ChartCircle size="17" />
                        {language_json[language]["general_analysis"]}
                      </div>
                    </MenuItem>
                  </Link>
                </div>
              </SubMenu>
              <Link to="/academy">
                <MenuItem>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Teacher size="17" />
                    {language_json[language]["academy"]}
                  </div>
                </MenuItem>
              </Link>
              <Link to="/account">
                <MenuItem>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <UserSquare size="17" />
                    {language_json[language]["account"]}
                  </div>
                </MenuItem>
              </Link>
              {/* <Link to="/withdraw">
                <MenuItem>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <MoneySend size="17" />
                    {language_json[language]["withdraw"]}
                  </div>
                </MenuItem>
              </Link> */}
              {user_info_data[0]["data"]["admin"] === "true" ? (
                <Link to="/submit_signal">
                  <MenuItem>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <UserOctagon size="17" />
                      {language_json[language]["submit_signal"]}
                    </div>
                  </MenuItem>
                </Link>
              ) : null}
            </Menu>
          </Sidebar>
        </div>
        <div className={styled.BottomMenu}>
          <img alt="light-icon" id="menuimg" src={menulight} />
          <div className={styled.dashboardIcon}>
            {/* <Notification
              className={styled.icon}
              size="19"
              color="var(--Accent)"
            />
            <MessageQuestion
              className={styled.icon}
              size="19"
              color="var(--Accent)"
            /> */}

            <div id="moonbutton" className={styled.icon1}>
              <Moon onClick={changeTheme} size="19" color="var(--Accent)" />
            </div>

            <div id="sunbutton" className={styled.icon}>
              <Sun1 onClick={changeTheme} size="19" color="var(--Accent)" />
            </div>

            <LanguageSquare
              onClick={changeLanguage}
              className={styled.icon}
              size="19"
              color="var(--Accent)"
            />
          </div>
          <div style={{ backgroundColor: "var(--Dialog)" }}>
            <div className={styled.bottomProf}>
              <div onClick={gtWithdraw} className={styled.bottomIcon}>
                <Export className={styled.icon} size="25" color="var(--Text)" />
              </div>
              <div className={styled.bottomImg}>
                <Moneys size="22" />
                <div>
                  <h6>
                    {user_info_data[0]["data"]["balance"].toLocaleString() +
                      " " +
                      language_json[language]["usdt"]}
                  </h6>
                </div>
              </div>
            </div>
            <div className={styled.bottomProf}>
              <div onClick={gtPlans} className={styled.bottomIcon}>
                <Add className={styled.icon} size="25" color="var(--Text)" />
              </div>
              <div className={styled.bottomImg}>
                <BuyCrypto size="22" />
                <div>
                  <h6>
                    {user_info_data[0]["data"]["daysleft"] !== 0
                      ? user_info_data[0]["data"]["daysleft"] +
                        " " +
                        language_json[language]["days_left"]
                      : language_json[language]["free"]}
                  </h6>
                </div>
              </div>
            </div>
            <div className={styled.logout}>
              <div onClick={() => logout()} className={styled.logouticon}>
                <LogoutCurve color="var(--Subtext)" size="22" />
              </div>
              <div onClick={gtAccount} className={styled.logoutimg}>
                <div>
                  <h6 style={{ color: "var(--Text)", fontFamily: "Dana" }}>
                    {user_info_data[0]["data"]["name"]}
                  </h6>
                  <h6 style={{ color: "var(--Subtext)" }}>
                    {user_info_data[0]["data"]["email"]}
                  </h6>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="profile-icon"
                    style={{ borderRadius: "50px" }}
                    src={user_info_data[0]["data"]["profile_pic"]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Describing;
