import axios from "axios";
import base_url from "../../config/config";

const fetchDataFromLink = async (link, headers) => {
  try {
    const response = await axios.get(link, { headers });
    const result = response.data;
    return [result, response.status];
  } catch (error) {
    return [error["response"]["data"]["error"], error["response"]["status"]];
  }
};

export const chart_patterns = async (
  token,
  timeframe_fil,
  strategy_fil,
  position_fil,
  limit,
  full_data,
  page
) => {
  const headers = {
    "X-TOKEN": token,
    "Content-Type": "multipart/form-data; charset=utf-8",
  };
  const data = await fetchDataFromLink(
    `${base_url}/chart_patterns?timeframe_fil=${timeframe_fil}&strategy_fil=${strategy_fil}&position_fil=${position_fil}&limit=${limit}&full_data=${full_data}&page=${page}`,
    headers
  );
  return data;
};
export default chart_patterns;
