import axios from 'axios';
import base_url from "../../config/config";

const fetchDataFromLink = async (link,headers) => {
  try {
    const response = await axios.get(link,{headers});
    const result = response.data;
    return [result, response.status];
  } catch (error) {
    return [error['response']['data']['error'], error['response']['status']];
  }
};

export const notification = async (token) => {

  const headers ={'X-TOKEN': token,'Content-Type':'multipart/form-data; charset=utf-8'};
  const data = await fetchDataFromLink(`${base_url}/notification`,headers);
  return data;
};

export default notification;