import React, { useState, useEffect } from "react";
import Loading from "../../component/loading/Loading";
import base_link from "../../config/config";
import { ArrowLeft } from "iconsax-react";
import { Link } from "react-router-dom";

// import styled from "./submitSignal.module.css";
import styled2 from "./submitSignal.module.css";
import { styled } from "@mui/material/styles";
import { submit_signal_api } from "../../component/api/submit_signal";

import {
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  TextField,
  Box,
  Button,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { red } from "@mui/material/colors";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const token = localStorage.getItem("token");

const symbolsList = [
  "BTC",
  "ETH",
  "USDT",
  "BNB",
  "SOL",
  "USDC",
  "XRP",
  "DOGE",
  "TON",
  "ADA",
  "TRX",
  "AVAX",
  "SHIB",
  "DOT",
  "BCH",
  "LINK",
  "DAI",
  "LEO",
  "LTC",
  "NEAR",
  "MATIC",
  "KAS",
  "ICP",
  "UNI",
  "PEPE",
  "XMR",
  "XLM",
  "ETC",
  "APT",
  "FET",
  "CRO",
  "STX",
  "OKB",
  "FIL",
  "MNT",
  "HBAR",
  "ATOM",
  "TAO",
  "FDUSD",
  "RENDER",
  "VET",
  "IMX",
  "MKR",
  "WIF",
  "ARB",
  "SUI",
  "INJ",
  "OP",
  "AAVE",
  "BONK",
  "AR",
  "GRT",
  "BGB",
  "FLOKI",
  "NOT",
  "JUP",
  "THETA",
  "JASMY",
  "RUNE",
  "PYTH",
  "ONDO",
  "TIA",
  "LDO",
  "BRETT",
  "ALGO",
  "CORE",
  "FTM",
  "OM",
  "FLOW",
  "SEI",
  "HNT",
  "BSV",
  "QNT",
  "EGLD",
  "USDD",
  "KCS",
  "FLR",
  "BTT",
  "EOS",
  "AXS",
  "PYUSD",
  "XTZ",
  "NEO",
  "AKT",
  "GT",
  "BEAM",
  "DYDX",
  "STRK",
  "XEC",
  "ENS",
  "NEXO",
  "XAUt",
  "POPCAT",
  "GALA",
  "SAND",
  "AIOZ",
  "ORDI",
  "WLD",
  "ZEC",
  "CFX",
  "MEW",
  "MOG",
  "MANA",
  "W",
  "ENA",
  "BNX",
  "BOME",
  "TUSD",
  "RON",
  "1000SATS",
  "CHZ",
  "KLAY",
  "MINA",
  "RAY",
  "PAXG",
  "IOTA",
  "SNX",
  "PENDLE",
  "FTT",
  "DEXE",
  "ROSE",
  "GNO",
  "AXL",
  "ASTR",
  "ZK",
  "BTG",
  "SFP",
  "NFT",
  "XDC",
  "LUNC",
  "TFUEL",
  "ZRO",
  "LPT",
  "CAKE",
  "APE",
  "TWT",
  "SAFE",
  "CKB",
  "KAVA",
  "1INCH",
  "COMP",
  "MX",
  "ANT",
  "JTO",
  "IOTX",
  "WEMIX",
  "AEVO",
  "PEOPLE",
  "GAL",
  "CRV",
  "GLM",
  "DASH",
  "WOO",
  "XRD",
  "ELF",
  "LUNA",
  "KSM",
  "MANTA",
  "MEME",
  "JST",
  "BLUR",
  "OSMO",
  "ZRX",
  "ZIL",
  "ANKR",
  "SC",
  "RPL",
  "CELO",
  "GMT",
  "HOT",
  "BAT",
  "DYM",
  "SUPER",
  "CVX",
  "ID",
  "ENJ",
  "ETHDYDX",
  "QTUM",
  "RVN",
  "PRIME",
  "ARKM",
  "XEM",
  "TRAC",
  "GMX",
  "ILV",
  "PONKE",
  "GAS",
  "ETHW",
  "SKL",
  "ALT",
  "MASK",
  "RSR",
  "METIS",
  "T",
  "BICO",
  "SSV",
  "BLAST",
  "CSPR",
  "IO",
  "CFG",
  "EDLC",
  "USDe",
  "ZBU",
  "CHEEL",
  "FTN",
  "FLZ",
  "FRAX",
  "AERO",
  "IPV",
  "H2O",
  "BDX",
  "USDY",
  "TRIBE",
  "DOG",
  "USDB",
  "TURBO",
  "G",
  "NEIRO",
  "ATH",
  "PEPECOIN",
  "DSYNC",
  "MGC",
  "ORBR",
  "MWC",
  "PAAL",
  "POLYX",
  "LRC",
  "MUMU",
  "TRUMP",
  "HONEY",
  "XCH",
  "YFI",
  "USDJ",
  "DCR",
  "FLUX",
  "QUBIC",
  "ETHFI",
  "BORG",
  "ABT",
  "UMA",
  "RLB",
  "VTHO",
  "BAND",
  "EDU",
  "ZIG",
  "NPC",
  "AMP",
  "STORJ",
  "ONE",
  "BabyDoge",
  "VENOM",
  "FXS",
  "GIGA",
  "ACH",
  "TEL",
  "TRB",
  "CTC",
  "ZETA",
  "AUDIO",
  "SUSHI",
  "GLMR",
  "ONT",
  "FNSA",
  "ALPH",
  "CHR",
  "EURS",
  "VANRY",
  "BB",
  "ICX",
  "KDA",
  "ORCA",
  "COTI",
  "0x0",
  "SXP",
  "NTRN",
  "ZEN",
  "AZERO",
  "API3",
  "YGG",
  "LSK",
  "PRO",
  "BANANA",
  "TAIKO",
  "XAI",
  "PCI",
  "OZO",
  "WAVES",
  "VVS",
  "Gomining",
  "USDP",
  "MRS",
  "POLY",
  "BAL",
  "LCX",
  "APU",
  "$MICHI",
  "DGB",
  "XNO",
  "WAXP",
  "ONG",
  "ONG",
  "BITCOIN",
  "WHY",
  "IOST",
  "TRU",
  "PIXEL",
  "JOE",
  "CTSI",
  "XYM",
  "MOBILE",
  "EVER",
  "RSS3",
  "GUSD",
  "RLC",
  "LON",
  "SAGA",
  "ULTIMA",
  "COQ",
  "POL",
  "POND",
  "BMX",
  "PUNDIX",
  "AGI",
  "BONE",
  "PROM",
  "POWR",
  "KUB",
  "SLP",
  "C98",
  "XVS",
  "IQ",
  "HIVE",
  "ANDY",
  "APEX",
  "SURE",
  "AUCTION",
  "MVL",
  "VRSC",
  "WOLF",
  "WMT",
  "ORBS",
  "USDX",
  "SUN",
  "DUSK",
  "NMR",
  "BAKE",
  "GNS",
  "SDEX",
  "BORA",
  "MERL",
  "KEEP",
  "MAGIC",
  "HIGH",
  "STRAX",
  "SLERF",
  "CVC",
  "MLK",
  "USTC",
  "MYRO",
  "DAR",
  "ZENT",
  "MCOIN",
  "NOS",
  "HASHAI",
  "GPU",
  "CELR",
  "BZR",
  "KNC",
  "AI",
  "SFUND",
  "LISTA",
  "PEIPEI",
  "NYM",
  "DKA",
  "SNT",
  "WIN",
  "NMT",
  "ORAI",
  "ANYONE",
  "ACE",
  "ZKJ",
  "STPT",
  "DENT",
  "PHA",
  "LUSD",
  "SPELL",
  "MED",
  "STEEM",
  "BIGTIME",
  "ELON",
  "MOB",
  "CTK",
  "MOVR",
  "PYR",
  "LMWR",
  "DOP",
  "RDNT",
  "STMX",
  "STRD",
  "FLEX",
  "RIF",
  "CYBER",
  "BUSD",
  "OAS",
  "XPLA",
  "LADYS",
  "VELO",
  "GODS",
  "WEN",
  "ALEX",
  "REQ",
  "OMNI",
  "NAKA",
  "OXT",
  "DODO",
  "WILD",
  "XYO",
  "GFI",
  "UQC",
  "KWD",
  "KUJI",
  "MPL",
  "DESO",
  "BILLY",
  "SYN",
  "PORTAL",
  "DAG",
  "AITECH",
  "HFT",
  "HOOK",
  "AURORA",
  "MPLX",
  "AEUR",
  "CGPT",
  "EUL",
  "ERG",
  "STG",
  "METFI",
  "LOOM",
  "SHIBTC",
  "ARDR",
  "vBUSD",
  "PHB",
  "LQTY",
  "MTL",
  "DRIFT",
  "ALICE",
  "SCRT",
  "SHDW",
  "STC",
  "MBX",
  "BOBO",
  "BNT",
  "BLZ",
  "AGLD",
  "HT",
  "STIK",
  "QI",
  "SYS",
  "SNEK",
  "CPOOL",
  "COL",
  "FORT",
  "LEVER",
  "HIFI",
  "HPO",
  "OCTA",
  "CXT",
  "XVG",
  "WISLM",
  "MOTHER",
  "LOCUS",
  "ARK",
  "FX",
  "BFC",
  "TLOS",
  "ACA",
  "FRONT",
  "ACS",
  "DIONE",
  "RACA",
  "POKT",
  "GAME",
  "ALI",
  "DADDY",
  "RAD",
  "LAT",
  "DAO",
  "BADGER",
  "BTCP",
  "ZBCN",
  "PNG",
  "NFP",
  "AOG",
  "CUDOS",
  "TOKEN",
  "WRX",
  "GTC",
  "EWT",
  "NEOM",
  "ARPA",
  "HYPC",
  "VELO",
  "MCT",
  "QKC",
  "REZ",
  "MANEKI",
  "PIRATE",
  "DEGEN",
  "GHX",
  "KARRAT",
  "ZCX",
  "CET",
  "WXT",
  "VCNT",
  "RARE",
  "NKN",
  "MEME",
  "TKO",
  "ACX",
  "MBOX",
  "ALPHA",
  "MYRIA",
  "MBL",
  "TOSHI",
  "MAVIA",
  "RBN",
  "LYX",
  "OGN",
  "MAPO",
  "VERUM",
  "TON",
  "CREAM",
  "CBK",
  "BWB",
  "RAMP",
  "OGY",
  "TNSR",
  "LTO",
  "CAW",
  "META",
  "GHST",
  "SWEAT",
  "ATOR",
  "XPRT",
  "MLN",
  "EURC",
  "ELA",
  "FORTH",
  "DEXT",
  "TLM",
  "BRISE",
  "VR",
  "ORN",
  "ERN",
  "REI",
  "ETN",
  "CLV",
  "PROPS",
  "TET",
  "AIDOGE",
  "EURt",
  "SOLO",
  "AIAT",
  "WZRD",
  "ZANO",
  "ULTI",
  "NOIA",
  "VIRTUAL",
  "GF",
  "AERGO",
  "WHITE",
  "XCN",
  "ATA",
  "GEAR",
  "RARI",
  "MAV",
  "DPI",
  "DNT",
  "SLND",
  "CUSD",
  "UDS",
  "REN",
  "DNX",
  "TT",
  "BETA",
  "GAFI",
  "PSTAKE",
  "OMG",
  "BOSON",
  "HUNT",
  "DIMO",
  "DIA",
  "LOOKS",
  "STRX",
  "STT",
  "BOBA",
  "COS",
  "CTXC",
  "POLIS",
  "ROUTE",
  "SMT",
  "DMTR",
  "LIT",
  "PERP",
  "LOVELY",
  "ISIKC",
  "GOG",
  "FUN",
  "ALEPH",
  "PALM",
  "DEP",
  "BFIC",
  "CCD",
  "LEASH",
  "TRAC",
  "GROK",
  "SBD",
  "KMD",
  "ROG",
  "DORA",
  "CROWN",
  "GFT",
  "VIC",
  "AL",
  "AURA",
  "ARRR",
  "UOS",
  "FLM",
  "ZUKI",
  "KIN",
  "MPT",
  "LNQ",
  "DATA",
  "RLY",
  "FIS",
  "WAN",
  "GFAL",
  "CWIF",
  "FIDA",
  "QUACK",
  "BIM",
  "VRTX",
  "RSV",
  "SAUCE",
  "DEL",
  "STRK",
  "UXLINK",
  "ALCX",
  "PSG",
  "ROOT",
  "CSWAP",
  "LINA",
  "ATLAS",
  "AVA",
  "VRA",
  "VAI",
  "PDA",
  "COREUM",
  "AMPL",
  "FCT",
  "KISHU",
  "DEGO",
  "XCHNG",
  "CONX",
  "BEL",
  "SOV",
  "SAMO",
  "NULS",
  "ASD",
  "COMBO",
  "NUM",
  "COPI",
  "GRS",
  "AMB",
  "IDEX",
  "REEF",
  "PORK",
  "HOPPY",
  "AGRS",
  "KEY",
  "FLIX",
  "HAI",
  "CEEK",
  "FARM",
  "ASM",
  "XSGD",
  "IAG",
  "UPP",
  "BFG",
  "SIDUS",
  "LOKA",
  "UNFI",
  "CERE",
  "PUPS",
  "MDT",
  "NXRA",
  "GST",
  "USDK",
  "XNC",
  "BSW",
  "SABAI",
  "POLS",
  "QUICK",
  "TRADE",
  "SHX",
  "GMEE",
  "NPT",
  "IRIS",
  "COMAI",
  "BLENDR",
  "MOC",
  "ICE",
  "SDAO",
  "BURN",
  "AKRO",
  "NEON",
  "PROPC",
  "GEL",
  "SD",
  "AHT",
  "CAGA",
  "MATH",
  "DVPN",
  "ZTX",
  "CAH",
  "AZUR",
  "ZKML",
  "BOTTO",
  "BIFI",
  "ADX",
  "VOXEL",
  "CHEQ",
  "MNW",
  "SMOG",
  "NCT",
  "GEOD",
  "IDIA",
  "KLV",
  "AXEL",
  "SC",
  "DMD",
  "QUICK",
  "AIT",
  "GME",
  "XPR",
  "MON",
  "SRX",
  "MAGA",
  "FIRE",
  "APRS",
  "TARA",
  "UTK",
  "ZUSD",
  "PRCL",
  "MONA",
  "AQT",
  "KP3R",
  "UNCX",
  "RSC",
  "BAR",
  "EXRD",
  "DFI",
  "TONIC",
  "DF",
  "OPUL",
  "DERO",
  "ZKL",
  "HEZ",
  "MUBI",
  "DFG",
  "HOPR",
  "MXC",
  "BENDOG",
  "ALU",
  "WING",
  "DUKO",
  "WE",
  "SGT",
  "FOXSY",
  "BURGER",
  "NEIRO",
  "APX",
  "VGX",
  "KLIMA",
  "AURY",
  "SIX",
  "KYVE",
  "PANDORA",
  "USDV",
  "CHESS",
  "CLORE",
  "A8",
  "BOB",
  "IOT",
  "WNCG",
  "PACE",
  "DEAI",
  "XCAD",
  "SCP",
  "AVINOC",
  "BOND",
  "SHPING",
  "UFT",
  "SHFL",
  "PAID",
  "TPT",
  "STOS",
  "GLQ",
  "SPX",
  "XCP",
  "NGL",
  "NIM",
  "NTX",
  "CITY",
  "OORT",
  "PIVX",
  "EPS",
  "FOX",
  "FEI",
  "TAI",
  "PDT",
  "INV",
  "MIN",
  "VXV",
  "MCB",
  "MTRG",
  "CAT",
  "UNS",
  "BOOK",
  "OHO",
  "PEPE2.0",
  "EL",
  "MDX",
  "PRQ",
  "PAW",
  "MNGO",
  "GRG",
  "DMAIL",
  "NIBI",
  "SUSD",
  "FIO",
  "HGPT",
  "LOBO",
  "PIT",
  "BCA",
  "GAL",
  "FIRO",
  "ZYPTO TOKEN",
  "$WAFFLES",
  "PNK",
  "BENJI",
  "CRTS",
  "BZZ",
  "SLIM",
  "ALPACA",
  "BLOK",
  "DFC",
  "QOM",
  "KRL",
  "VLX",
  "GYEN",
  "VIDT",
  "MOON",
  "TOMI",
  "SANTOS",
  "WIFI",
  "SN",
  "FON",
  "CETUS",
  "SHC",
  "UPC",
  "VOLT",
  "BOMB",
  "GTAI",
  "CREDI",
  "LMR",
  "CTX",
  "ZERC",
  "CATGIRL",
  "NEXA",
  "ARIA20",
  "PSP",
  "VITE",
  "HELLO",
  "VNO",
  "SHIDO",
  "KAI",
  "STEP",
  "PMG",
  "MMIT",
  "SPA",
  "ASR",
  "SPECTRE",
  "10SET",
  "TOKE",
  "OG",
  "SOIL",
  "BCD",
  "BST",
  "BST",
  "QRL",
  "AST",
  "IVPAY",
  "SLOTH",
  "DLC",
  "VARA",
  "KATA",
  "POOLX",
  "DIME",
  "ALPINE",
  "DCB",
  "QTCON",
  "DVF",
  "CAST",
  "PPC",
  "HARD",
  "CHAT",
  "IDRT",
  "JEWEL",
  "DACXI",
  "ICHI",
  "CWEB",
  "TROY",
  "OOKI",
  "WRLD",
  "CENNZ",
  "HEGIC",
  "FLT",
  "JUV",
  "EPIC",
  "VIB",
  "ISLM",
  "DBC",
  "OTK",
  "CAIR",
  "TIME",
  "FOR",
  "NWC",
  "UFO",
  "STRUMP",
  "ALT",
  "BRAINLET",
  "BLOCK",
  "BXN",
  "METAL",
  "SQUIDGROW",
  "TAVA",
  "CGO",
  "NODL",
  "YFII",
  "SHFT",
  "GALEON",
  "CVP",
  "VEGA",
  "WALLET",
  "MVC",
  "SCA",
  "QORPO",
  "RBX",
  "SUKU",
  "VINU",
  "TORN",
  "OAX",
  "DCK",
  "SCLP",
  "BREED",
  "HERO",
  "RPG",
  "SHRAP",
  "MAGAA",
  "ZEON",
  "AVT",
  "POLA",
  "MAN",
  "AYA",
  "POX",
  "LIKE",
  "LAZIO",
  "BTCXT",
  "RXD",
  "WAGMIGAMES",
  "JESUS",
  "DVI",
  "FAR",
  "SAITO",
  "RVF",
  "AFC",
  "SWAP",
  "GRND",
  "PROS",
  "HTR",
  "PORTO",
  "XEP",
  "YOOSHI",
  "ZKF",
  "OMNOM",
  "SKEY",
  "HEGE",
  "AMO",
  "AGLA",
  "ELIX",
  "AE",
  "WNZ",
  "GPT",
  "EVZ",
  "NFTX",
  "ACM",
  "HTM",
  "NCDT",
  "SIS",
  "ATM",
  "OFN",
  "KOMPETE",
  "TADA",
  "MCADE",
  "DEVVE",
  "SDN",
  "PAJAMAS",
  "SILLY",
  "ADS",
  "BTM",
  "ARIX",
  "MIX",
  "EGG",
  "LA",
  "THREE",
  "ATR",
  "NYAN",
  "BIOT",
  "SRM",
  "ZYN",
  "COIN",
  "ORNJ",
  "TRVL",
  "CAPS",
  "CSIX",
  "AKITA",
  "ARG",
  "GET",
  "KSP",
  "CELL",
  "BKN",
  "HUSD",
  "FITFI",
  "ARTY",
  "HAPI",
  "GMRX",
  "EFI",
  "FUL",
  "LRDS",
  "ASTRAFER",
  "FUSE",
  "MDAO",
  "EPX",
  "DC",
  "CATE",
  "BLS",
  "KASTA",
  "DOAI",
  "VCHF",
  "SOUL",
  "TAROT",
  "KIMBO",
  "CTA",
  "INTER",
  "OBSR",
  "RAIN",
  "AREA",
  "BABYBONK",
  "KAN",
  "OVR",
  "EXE",
  "COLX",
  "ABBC",
  "PIB",
  "TSUKA",
  "NOW",
  "MLT",
  "KILT",
  "KAR",
  "CEL",
  "HYDRA",
  "PIKA",
  "MNTC",
  "CULT",
  "$MONG",
  "BAD",
  "BNC",
  "STBU",
  "DUEL",
  "HNS",
  "OUSD",
  "CQT",
  "PIKA",
  "BDP",
  "JOY",
  "WIKEN",
  "MEV",
  "LM",
  "GENE",
  "IMGNAI",
  "NRG",
  "BCUT",
  "NMC",
  "XNA",
  "SPS",
  "SNS",
  "PALLA",
  "GZONE",
  "BEER",
  "BRICK",
  "AIX",
  "EQUAD",
  "BUY",
  "DIVI",
  "TRC",
  "BTC2",
  "vDAI",
  "vLINK",
  "KEX",
  "GMM",
  "TRA",
  "AIPAD",
  "CRU",
  "OPTI",
  "SPURS",
  "STARL",
  "EMRX",
  "NAVX",
  "SOLVE",
  "ABEL",
  "FNCY",
  "RIO",
  "XDEFI",
  "BITCI",
  "IZI",
  "CUBE",
  "UBXS",
  "ML",
  "GBYTE",
  "ZKB",
  "UNIBOT",
  "REVV",
  "KUNCI",
  "5IRE",
  "DIO",
  "SOLAMA",
  "FANC",
  "SWTH",
  "SQR",
  "XTM",
  "EPIK",
  "BTS",
  "GLEEC",
  "DPR",
  "TKP",
  "HEMULE",
  "ZERO",
  "CHO",
  "MV",
  "HOGE",
  "BYTE",
  "BLOCK",
  "LAND",
  "SWASH",
  "3ULL",
  "NAVI",
  "TEMCO",
  "ID",
  "BIDR",
  "OPEN",
  "OGV",
  "ROA",
  "CHAT",
  "AZIT",
  "LNDX",
  "BRWL",
  "M87",
  "SWCH",
  "VTC",
  "PIP",
  "ES",
  "PSPS",
  "KDAG",
  "BAN",
  "APM",
  "CVTX",
  "XDB",
  "BOO",
  "KOKO",
  "PBX",
  "ASTX",
  "SPC",
  "BEFE",
  "ZF",
  "PLANET",
  "BCCOIN",
  "FRM",
  "SHI",
  "REF",
  "COVAL",
  "FRA",
  "CREO",
  "SMILEK",
  "CATCH",
  "GSWIFT",
  "MANC",
  "MKUSD",
  "BABY",
  "NEX",
  "WSM",
  "torsy",
  "BAX",
  "PRNT",
  "vLTC",
  "PZP",
  "VAL",
  "ONE",
  "ADP",
  "POOH",
  "VALOR",
  "ETHAX",
  "CRASH",
  "ORB",
  "CCC",
  "AMU",
  "SNC",
  "NETVR",
  "COT",
  "TKS",
  "PTU",
  "CEUR",
  "RWA",
  "COOKIE",
  "SOFI",
  "ELON",
  "WAT",
  "REP",
  "BBF",
  "DHT",
  "BROCK",
  "FER",
  "MENGO",
  "BEAM",
  "CHKN",
  "RADAR",
  "OOKS",
  "DOME",
  "PZM",
  "AMON",
  "DFL",
  "DEFIT",
  "ESE",
  "XWC",
  "SHIRYO",
  "BOOT",
  "BOLT",
  "XRT",
  "BTR",
  "PUSH",
  "MCRT",
  "WHALE",
  "FYN",
  "CATBOY",
  "OXB",
  "ANDY",
  "BLOCX",
  "HEFI",
  "GARI",
  "UX",
  "UPO",
  "SCPT",
  "PLU",
  "STAT",
  "ZNN",
  "BSCPAD",
  "NVIR",
  "BAG",
  "CRP",
  "KWENTA",
  "VAI",
  "BCN",
  "DARK",
  "TYPE",
  "GMCOIN",
  "MASQ",
  "ASK",
  "BEPRO",
  "PLF",
  "DRGN",
  "CUMMIES",
  "DOBO",
  "DUREV",
  "EVERY",
  "OOE",
  "TOWER",
  "REV3L",
  "UBT",
  "CHEEMS",
  "VIX",
  "OBI",
  "FSN",
  "MOVE",
  "CBG",
  "MEE",
  "GOAL",
  "MUSE",
  "MBS",
  "LBL",
  "MAHA",
  "KCT",
  "CPU",
  "SHEZMU",
  "BRN",
  "INTELLIQUE",
  "BOA",
  "LSS",
  "UMT",
  "VMINT",
  "SHOPX",
  "RAI",
  "SAKAI",
  "SMURFCAT",
  "WOM",
  "AVL",
  "RMV",
  "GSWAP",
  "GRACY",
  "BRG",
  "GXA",
  "UFC",
  "JETTON",
  "PMPY",
  "SAM",
  "DICE",
  "NHT",
  "ARTFI",
  "PYI",
  "PRE",
  "SENATE",
  "CGV",
  "MOZ",
  "EFC",
  "RMRK",
  "MTD",
  "RING",
  "RISE",
  "CU",
  "PLN",
  "RITE",
  "TPY",
  "SKBDI",
  "HAM",
  "PERC",
  "BTCBAM",
  "IMT",
  "BIZA",
  "QDFI",
  "HVH",
  "SHR",
  "CAS",
  "DJED",
  "LADYF",
  "PKF",
  "ASTA",
  "RBC",
  "BLY",
  "THE",
  "ADM",
  "SFD",
  "JOB",
  "MYST",
  "LCC",
  "GO",
  "ROOST",
  "BBL",
  "NAP",
  "EML",
  "RFOX",
  "SLN",
  "MARSH",
  "TENT",
  "CRPT",
  "WOW",
  "WWY",
  "DUST",
  "XFC",
  "VAB",
  "LPM",
  "ISK",
  "OLE",
  "HMND",
  "RJV",
  "GG",
  "APL",
  "ANALOS",
  "XELS",
  "CCT",
  "DAPPX",
  "TRIX",
  "PLI",
  "ALGB",
  "PART",
  "THG",
  "TABOO",
  "HSF",
  "MOUTAI",
  "HI",
  "KYL",
  "LITH",
  "CPR",
  "BAAS",
  "PERI",
  "LAMB",
  "SPH",
  "INDY",
  "NOM",
  "MVP",
  "HUND",
  "EFX",
  "TES",
  "VERSE",
  "HDN",
  "ANC",
  "ORC",
  "BIS",
  "OMAX",
  "ISP",
  "COK",
  "LVN",
  "BTCST",
  "VSP",
  "ACA",
  "GHUB",
  "SAUBER",
  "WSI",
  "MINT",
  "LAVA",
  "GAME",
  "VEUR",
  "TAMA",
  "IGNIS",
  "LIME",
  "EMC",
  "IMPT",
  "DIP",
  "CHI",
  "SERO",
  "PKT",
  "IDLE",
  "BABYTRUMP",
  "FUND",
  "UNICE",
  "XRPH",
  "PPT",
  "SSWP",
  "NEVER",
  "WOZX",
  "SOLCEX",
  "KIBSHI",
  "FCON",
  "KLEVA",
  "POR",
  "BF",
  "STRP",
  "SYLO",
  "DAD",
  "AXIS",
  "XED",
  "PORNROCKET",
  "CONV",
  "LNC",
  "GRIN",
  "MTV",
  "CHRP",
  "IHC",
  "RGT",
  "HZN",
  "ECLD",
  "NEST",
  "XAR",
  "DYP",
  "BABY",
  "PBR",
  "VEST",
  "GOVI",
  "GAINS",
  "DOGA",
  "LUCA",
  "MOBY",
  "WAIT",
  "LTX",
  "BEAT",
  "PMT",
  "BONDLY",
  "WOMBAT",
  "PANDA",
  "DOCK",
  "INSP",
  "VEXT",
  "BASE",
  "GEEQ",
  "MORRA",
  "PNP",
  "UMB",
  "SENSO",
  "DERI",
  "NIZA",
  "GNY",
  "STRM",
  "SIGNA",
  "BIP",
  "JMPT",
  "ARKI",
  "KINE",
  "ZOO",
  "HOSKY",
  "BWO",
  "RIDE",
  "MTA",
  "BUBBLE",
  "LYNX",
  "SIN",
  "BIDZ",
  "LUSH",
  "MBD",
  "VC",
  "CARLO",
  "BCUBE",
  "DPET",
  "KNINE",
  "SPIKE",
  "VIDYA",
  "CA",
  "MCG",
  "LYM",
  "MNFT",
  "IPAD",
  "KICK",
  "SYL",
  "RBW",
  "GQ",
  "SALT",
  "YAI",
  "SUB",
  "SFI",
  "EBEN",
  "FLAME",
  "vDOT",
  "ROOBEE",
  "MOOV",
  "JGN",
  "STFX",
  "UFI",
  "MTLX",
  "NBLU",
  "CTI",
  "DFYN",
  "MILE",
  "HTD",
  "MULTI",
  "DINO",
  "CWAR",
  "UBDN",
  "FB",
  "ELAND",
  "ACE",
  "DX",
  "REV",
  "VCF",
  "HAWKTUAH",
  "SAFE",
  "LL",
  "NBT",
  "PAY",
  "KOM",
  "DHB",
  "SOY",
  "PICKLE",
  "MIR",
  "DG",
  "MAX",
  "STC",
  "XWG",
  "NVT",
  "LRT",
  "JUICE",
  "STND",
  "TUP",
  "GALAXIS",
  "DAMEX",
  "GORILLA",
  "SCCP",
  "TREEB",
  "SHEB",
  "UNB",
  "MARS4",
  "XTP",
  "WEFI",
  "QUINT",
  "CRWNY",
  "VSYS",
  "DEOD",
  "ROCO",
  "GOZ",
  "SKR",
  "ZIK",
  "MITX",
  "BBS",
  "KITTENWIF",
  "AEG",
  "WEST",
  "KMA",
  "MCO2",
  "HAKKA",
  "FREE",
  "XPM",
  "URUS",
  "XTN",
  "CBX",
  "IBS",
  "CTG",
  "LOOT",
  "PEEL",
  "WITCH",
  "HONK",
  "DSLA",
  "KROM",
  "HAMI",
  "ABOND",
  "BEFI",
  "BOZO",
  "GRV",
  "ATT",
  "WLKN",
  "ERTHA",
  "PDEX",
  "WAM",
  "POLK",
  "OTO",
  "TRV",
  "MINU",
  "NFE",
  "POLC",
  "LIGHT",
  "ORT",
  "GHOST",
  "UTYAB",
  "P3D",
  "AOA",
  "TOP",
  "MONI",
  "K21",
  "ARV",
  "CARR",
  "PCX",
  "GMMT",
  "HODL",
  "LQR",
  "SKEB",
  "GOC",
  "SELO",
  "CDT",
  "DECHAT",
  "PXP",
  "EXFI",
  "TSUGT",
  "MEMEAI",
  "GST",
  "XHV",
  "RAZOR",
  "MCHC",
  "GB",
  "RET",
  "FO",
  "CIV",
  "SSG",
  "ZUM",
  "WECAN",
  "MINTME",
  "SOS",
  "CTCN",
  "PLC",
  "DAFI",
  "XTAG",
  "CGG",
  "$OPHX",
  "USDs",
  "ALY",
  "UNO",
  "EDG",
  "ASTRO",
  "LBP",
  "AM",
  "SHROOM",
  "BIST",
  "POWSCHE",
  "RBLS",
  "vBCH",
  "JAM",
  "LAYER",
  "SOLCAT",
  "REVO",
  "INFRA",
  "TEER",
  "BOSS",
  "MDAI",
  "BEND",
  "ABYSS",
  "EUM",
  "BSCS",
  "KINGSHIB",
  "MEOW",
  "CWS",
  "JULD",
  "RPK",
  "FEAR",
  "NABOX",
  "HUDI",
  "NOGS",
  "HEC",
  "NEURA",
  "BIRD",
  "KZEN",
  "VEX",
  "WELT",
  "HONK",
  "ELMT",
  "MOJO",
  "GINUX",
  "COINYE",
  "DPS",
  "DDX",
  "PUSSY",
  "STM",
  "PLR",
  "BABYTRUMP",
  "WNT",
  "SHILL",
  "IDNA",
  "DEFROGS",
  "TIFI",
  "VISION",
  "WICC",
  "WOOF",
  "BLAST",
  "AUTO",
  "DINGO",
  "LUFC",
  "IOEN",
  "VEMP",
  "AX",
  "NFTB",
  "PEAK",
  "ONE",
  "EXM",
  "PLASTIK",
  "TRAVA",
  "VLXPAD",
  "XCV",
  "HIBS",
  "HAN",
  "PUT",
  "DOGGY",
  "JAM",
  "WIF",
  "XRUNE",
  "VEED",
  "FTC",
  "OLY",
  "LONG",
  "HGOLD",
  "BIFI",
  "INTR",
  "HOD",
  "O3",
  "GOAT",
  "PXC",
  "DOGEMOB",
  "HOTCROSS",
  "WOM",
  "FEX",
  "HC",
  "JENNER",
  "MONA",
  "VNXAU",
  "ZTG",
  "YAM",
  "DONUT",
  "CONE",
  "NetZ",
  "SKYA",
  "GRLC",
  "YBO",
  "DEFI",
  "LBC",
  "SXCH",
  "LITT",
  "SUTER",
  "SRK",
  "HIPENGUINS",
  "CRT",
  "ELDA",
  "$REKT",
  "KEKE",
  "SPIRIT",
  "VTS",
  "BIOFI",
  "EXA",
  "YBO",
  "BLP",
  "TOMS",
  "XMON",
  "ELMO",
  "TROG",
  "SMI",
  "AIRTNT",
  "EARN",
  "TKAI",
  "POR",
  "EGO",
  "AKI",
  "BGL",
  "BLT",
  "CEP",
  "TUNE",
  "ZELIX",
  "BXX",
  "WSG",
  "ILC",
  "ATS",
  "IDEA",
  "SPO",
  "FOMO",
  "KONO",
  "POLX",
  "NAVI",
  "EGG",
  "ITGR",
  "ARC",
  "ROOK",
  "SCOTTY",
  "ORARE",
  "DTORO",
  "BTX",
  "LING",
  "BFT",
  "EXVG",
  "SPORE",
  "SLICE",
  "SON",
  "OPEN",
  "ETHO",
  "AIMX",
  "TIPS",
  "THN",
  "1ART",
  "LEG",
  "VPP",
  "AART",
  "MTH",
  "BCMC",
  "SHA",
  "NEER",
  "PNT",
  "UNIX",
  "ZEE",
  "0XDEV",
  "SECOND",
  "MMA",
  "0xBTC",
  "OLT",
  "SYNC",
  "RODAI",
  "ZOON",
  "DREAMS",
  "RFUEL",
  "RPG",
  "L2",
  "PUMLX",
  "ODIN",
  "HORD",
  "XPC",
  "ETHPAD",
  "HMQ",
  "GALO",
  "LOE",
  "FWC",
  "BTCZ",
  "JOY",
  "OIN",
  "GLCH",
  "WOOP",
  "PIRATE",
  "LOCG",
  "BELT",
  "CLEO",
  "BLINK",
  "BHO",
  "WSG",
  "NFTART",
  "DRF",
  "SNFT",
  "DONS",
  "BASE",
  "DREP",
  "IBFK",
  "MAPS",
  "YLD",
  "AIRI",
  "COBE",
  "NSFW",
  "ZAP",
  "ADAPAD",
  "CLO",
  "SPFC",
  "AME",
  "OXBT",
  "RAIN",
  "ELMON",
  "GMAC",
  "FWT",
  "OMC",
  "INSUR",
  "NORD",
  "XBY",
  "BMON",
  "POSI",
  "CLOAK",
  "MSTR",
  "YEC",
  "RB",
  "ALLIN",
  "LYRA",
  "TH",
  "ZER",
  "RWA",
  "T99",
  "XMV",
  "CNB",
  "GTH",
  "KRIPTO",
  "DOKY",
  "ISLAMI",
  "BITS",
  "FLX",
  "NSBT",
  "VRT",
  "HPB",
  "PAC",
  "ZCL",
  "LOWB",
  "EMAGIC",
  "PVU",
  "MFC",
  "PLT",
  "LABS",
  "MEFA",
  "SPARTA",
  "CIRUS",
  "UBX",
  "MEMECUP",
  "2MOON",
  "NAFT",
  "STARSHIP",
  "BTR",
  "AZ",
  "EVRY",
  "NRK",
  "KING",
  "SHOOT",
  "AOG",
  "AVG",
  "CFi",
  "MTVT",
  "GRP",
  "CSM",
  "PRISM",
  "EDEN",
  "KALM",
  "PLOT",
  "DON",
  "PMON",
  "ALPHABET",
  "VEIL",
  "LABZ",
  "PLY",
  "IGU",
  "eRSDL",
  "KABY",
  "MF",
  "EPIKO",
  "ATOLO",
  "MOD",
  "GNX",
  "SELF",
  "BMI",
  "DRT",
  "MRST",
  "PAR",
  "MRST",
  "SCALE",
  "MARO",
  "FOUR",
  "QRK",
  "PFL",
  "FRIN",
  "AG8",
  "WFAI",
  "KUBE",
  "KCCPAD",
  "PLAY",
  "TONE",
  "XBC",
  "PAINT",
  "SUMO",
  "AIBB",
  "MEGALAND",
  "KTN",
  "SOV",
  "PYM",
  "KAT",
  "KLUB",
  "WTC",
  "ARCONA",
  "REGENT",
  "BWLD",
  "GFI",
  "XRP",
  "NEI",
  "RFR",
  "CATHEON",
  "OCTO",
  "SOULS",
  "PAPU",
  "XNL",
  "AIT",
  "SKILL",
  "TKING",
  "ND",
  "SYNC",
  "GLINT",
  "PAPI",
  "MITH",
  "PERL",
  "SATA",
  "FARA",
  "SHND",
  "SATT",
  "GOLD",
  "HYVE",
  "STRONG",
  "INEX",
  "MOVEZ",
  "CAT",
  "DXL",
  "DIGG",
  "QWAN",
  "AWC",
  "BLOX",
  "BLANK",
  "KMON",
  "CLEG",
  "MTC",
  "TOKO",
  "THC",
  "GIO",
  "CRX",
  "METAQ",
  "GLC",
  "NINU",
  "OPIUM",
  "BXC",
  "ALVA",
  "BSHIB",
  "PTS",
  "KNIGHT",
  "UDO",
  "SNM",
  "NUT",
  "MABA",
  "ALA",
  "HIENS3",
  "SHI",
  "SNB",
  "HUSH",
  "XY",
  "WATCH",
  "WNDR",
  "ACQ",
  "HYPER",
  "X",
  "CTT",
  "MASS",
  "GMPD",
  "CO",
  "FEVR",
  "XLD",
  "NUX",
  "QRT",
  "VV",
  "DOSE",
  "MRX",
  "OXY",
  "SPELLFIRE",
  "MSU",
  "VIT",
  "OGGY",
  "LFW",
  "WZRD",
  "HANDY",
  "OCE",
  "HELMET",
  "RPZX",
  "SOLZILLA",
  "BRKL",
  "XPNET",
  "MIST",
  "HNTR",
  "FRTS",
  "VXL",
  "HTML",
  "SCC",
  "DDIM",
  "OJA",
  "RVC",
  "RUFF",
  "MMAI",
  "TXL",
  "TIN",
  "SILVA",
  "OGGY",
  "NINO",
  "ESD",
  "WSPP",
  "ING",
  "LSD",
  "BANANA",
  "KART",
  "DUCKIES",
  "BALPHA",
  "ZAI",
  "LNR",
  "CTT",
  "IOI",
  "CINU",
  "AGATA",
  "STARS",
  "PLSPAD",
  "CSPN",
  "ACK",
  "COT",
  "EM",
  "SALD",
  "SHRED",
  "MPH",
  "CVT",
  "X42",
  "KANGAL",
  "PETOSHI",
  "FTG",
  "DEC",
  "erowan",
  "SPIKE",
  "BIN",
  "SWP",
  "MAT",
  "STV",
  "EXGO",
  "INXT",
  "EUNO",
  "STEP",
  "PEX",
  "TEM",
  "PTF",
  "MOTA",
  "BLKC",
  "LTRBT",
  "LIFE",
  "GLS",
  "IDRX",
  "EVA",
  "BANK",
  "PACOCA",
  "NFTD",
  "COMBO",
  "CRA",
  "SHACK",
  "404A",
  "JET",
  "CAI",
  "PCNT",
  "NSO",
  "MAGE",
  "NBS",
  "UPX",
  "PKR",
  "TME",
  "LINKA",
  "DCN",
  "HIENS4",
  "SDT",
  "HBX",
  "FCL",
  "IDV",
  "FOR",
  "HGET",
  "DRC",
  "DEXA",
  "KDG",
  "TIDAL",
  "DXGM",
  "GOB",
  "KING",
  "SBABE",
  "ZLW",
  "ROUSH",
  "AGA",
  "POS",
  "LDOGE",
  "DFA",
  "TROLL",
  "CNS",
  "DDOS",
  "ASPO",
  "DTX",
  "CARBON",
  "KSN",
  "LANC",
  "POLIS",
  "RUSH",
  "DZG",
  "SOUL",
  "TKB",
  "8PAY",
  "SLRS",
  "COR",
  "RAZE",
  "ALL",
  "NAX",
  "PEL",
  "HIUNDEAD",
  "GMAT",
  "PLAY",
  "BAC",
  "SWRV",
  "FINA",
  "MAKI",
  "XNO",
  "SAKE",
  "CZGOAT",
  "C20",
  "LEV",
  "ACN",
  "KOK",
  "UBEX",
  "ADF",
  "MUDOL2",
  "MYRA",
  "CLS",
  "NYZO",
  "JRT",
  "NCash",
  "NRV",
  "IGG",
  "SWOP",
  "TFBX",
  "CNTR",
  "VALUE",
  "KLO",
  "TIK",
  "KTON",
  "SFX",
  "PASC",
  "TRAXX",
  "ARES",
  "FANX",
  "ROCKI",
  "FYD",
  "SNOW",
  "HIMFERS",
  "RITO",
  "ATP",
  "RVM",
  "FIN",
  "CRP",
  "TITAN",
  "DONA",
  "HIFRIENDS",
  "TULIP",
  "HUSL",
  "TELOS",
  "UCH",
  "SWINGBY",
  "YF-DAI",
  "COOK",
  "DEM",
  "MOMA",
  "ORION",
  "XCASH",
  "TRL",
  "GDAO",
  "FREED",
  "DBZ",
  "GSTS",
  "SHARE",
  "DINO",
  "APL",
  "POLI",
  "PWAR",
  "FXF",
  "GARD",
  "APRIL",
  "DOWS",
  "EBA",
  "FBX",
  "EVY",
  "APYS",
  "GOF",
  "BP",
  "OBX",
  "AXIS",
  "APN",
  "DAVIS",
  "FINE",
  "MHUNT",
  "TRR",
  "DESU",
  "XOR",
  "ALN",
  "GS",
  "FPFT",
  "KEYFI",
  "GC",
  "VDR",
  "IQT",
  "HIBEANZ",
  "TCP",
  "PHX",
  "ECO",
  "NGM",
  "DLORD",
  "CRE8",
  "WOW",
  "HAWK",
  "REAP",
  "DHV",
  "ZYB",
  "NEXT",
  "GEM",
  "PPAY",
  "AD",
  "GMD",
  "XCUR",
  "CATMAN",
  "CTT",
  "SENC",
  "PHNX",
  "NOV",
  "PETS",
  "ARGON",
  "BAKE",
  "BOLI",
  "MSWAP",
  "HAWK",
  "NFTY",
  "GCOIN",
  "NEBL",
  "ZUM",
  "DUCK",
  "XLAB",
  "UPI",
  "EZ",
  "BUNI",
  "KAMPAY",
  "NERD",
  "NOR",
  "YTN",
  "BDP",
  "BHC",
  "ZNZ",
  "BURN",
  "WAG",
  "MSB",
  "SKU",
  "SHARD",
  "SMART",
  "SHIELD",
  "PRCY",
  "XWP",
  "CORGIB",
  "FXDX",
  "FOC",
  "YIELD",
  "GENESIS",
  "DAWN",
  "BUSY",
  "BNANA",
  "SYP",
  "OPCT",
  "NEVA",
  "FTX",
  "BRY",
  "ASW",
  "ORAO",
  "HIMOONBIRDS",
  "GAME",
  "SPORT",
  "AZY",
  "RBLX",
  "COGI",
  "SOHOT",
  "BCUG",
  "DNXC",
  "S4F",
  "TOTM",
  "SAO",
  "BPS",
  "DOGES",
  "DAPP",
  "BTT",
  "SHOP",
  "VSO",
  "TIP",
  "METAX",
  "BTB",
  "RGP",
  "FS",
  "JADE",
  "LYRA",
  "BUNNY",
  "HISEALS",
  "FLIXX",
  "FBN",
  "LKR",
  "SABLE",
  "ZORA",
  "AIRT",
  "TANK",
  "PRARE",
  "SKP",
  "STAK",
  "BTZC",
  "SMTY",
  "ETHA",
  "RED",
  "SNY",
  "EKTA",
  "MMO",
  "MILK2",
  "CORX",
  "BOUTS",
  "FLASH",
  "SHOE",
  "PHR",
  "1EARTH",
  "NTRN",
  "MEL",
  "AMA",
  "GUM",
  "MEVRV2",
  "HYPE",
  "KWT",
  "EGG",
  "PYRO",
  "SPUME",
  "GOSS",
  "SNOB",
  "TZC",
  "LFG",
  "A",
  "TXA",
  "BEM",
  "NSURE",
  "WQT",
  "SOLX",
  "KFT",
  "BETU",
  "HERO",
  "RUNE",
  "TWIN",
  "NNI",
  "DOP",
  "AZUKI",
  "VAL",
  "DOM",
  "SFUEL",
  "ERA",
  "ARCO",
  "N8V",
  "1UP",
  "ALPHR",
  "TCS",
  "NEU",
  "FORM",
  "BeAI",
  "BYN",
  "SPWN",
  "SCAP",
  "INNBC",
  "UNI",
  "RAGE",
  "GEO",
  "TBX",
  "FIU",
  "SHIA",
  "BNF",
  "JOOPS",
  "ATMOS",
  "PRVC",
  "LGCY",
  "IPX",
  "INN",
  "ISA",
  "NRFB",
  "HTZ",
  "MOWA",
  "SCHO",
  "LUC",
  "GAJ",
  "BBANK",
  "LITHO",
  "FUZE",
  "VIVO",
  "PLANETS",
  "GXT",
  "PXT",
  "CPO",
  "SWG",
  "ELET",
  "MDTK",
  "RENA",
  "SKYRIM",
  "NDX",
  "LUS",
  "ICE",
  "AFEN",
  "XDNA",
  "BTRS",
  "SNN",
  "ROOM",
  "GRIMM",
  "PTP",
  "UFARM",
  "KWS",
  "LAND",
  "PAYB",
  "GDR",
  "SXS",
  "HIBAKC",
  "XGM",
  "AXE",
  "MARTK",
  "DONU",
  "XUI",
  "ACOIN",
  "SWP",
  "HXA",
  "EGG",
  "SCONEX",
  "KUV",
  "MATE",
  "BSL",
  "CRDT",
  "CNTM",
  "CARE",
  "KEMA",
  "KZC",
  "LEAD",
  "CYL",
  "SEFA",
  "ECU",
  "CBIX-P",
  "MEMEMINT",
  "FBX",
  "PIAS",
  "YVS",
  "AIM",
  "STAKE",
  "PRMX",
  "ETNA",
  "SFG",
  "ZCR",
  "ARNX",
  "WSPP",
  "RNBW",
  "YFFII",
  "SATS",
  "MAX",
  "JLP",
  "GINNAN",
  "ULD",
  "HTX",
  "rats",
  "PIXFI",
  "FTR",
  "MOCA",
  "BILL",
  "SDAI",
  "MMXN",
  "L3",
  "NEIRO",
  "USD+",
  "CHITAN",
  "HARRIS",
  "CNX",
  "HUGE",
  "MASA",
  "FMC",
  "WSTUSDT",
  "XT",
  "PIZZA",
  "AVAIL",
  "MSN",
  "BPLC",
  "XTUSD",
  "TREMP",
  "1CAT",
  "UZX",
  "ZEUS",
  "FIGHT",
  "AURA",
  "WBS",
  "PUGGY",
  "VNDC",
  "ONI",
  "GUMMY",
  "ASTO",
  "SMILEY",
  "ROCKY",
  "RETARDIO",
  "WOJAK",
  "GHO",
  "ELU",
  "GEMS",
  "MTC",
  "SMOLE",
  "HOLD",
  "WSH",
  "NADA",
  "JSM",
  "HABIBI",
  "FMB",
  "CLOUD",
  "AVACN",
  "TYBENG",
  "BMTC",
  "STC",
  "RYO",
  "MUSIC",
  "PEPE",
  "MIGGLES",
  "DJT",
  "BOOM",
  "WBT",
  "SAROS",
  "P2PS",
  "BSSB",
  "DD",
  "PUMP",
  "LSD",
  "XR",
  "RNT",
  "X",
  "TOX",
  "XPET",
  "KEN",
  "ORDS",
  "SYNT",
  "SELFIE",
  "FOXY",
  "NFM",
  "MICE",
  "PUNDU",
  "PEW",
  "BPX",
  "ZERO",
  "PEANIE",
  "KMNO",
  "NEIRO",
  "COAI",
  "TURT",
  "lisUSD",
  "MAPE",
  "DMAGA",
  "TRUNK",
  "FOG",
  "SCT",
  "QAI",
  "SLEX",
  "LBK",
  "XUSD",
  "DOOGLE",
  "BAR",
  "PDX",
  "BPT",
  "XODEX",
  "ZUZALU",
  "SERSH",
  "DOOGLE",
  "SORA",
  "WHALES",
  "NEXT",
  "DOGA",
  "TROLL",
  "SMH",
  "RSIC",
  "SIGMA",
  "XZK",
  "MINI",
  "PBX",
  "ZETRIX",
  "GENO",
  "HEHE",
  "KENDU",
  "FROSTY",
  "NUB",
  "CLFI",
  "MONGY",
  "KAMA",
  "NOTE",
  "MOEW",
  "LAI",
  "GRAM",
  "FAC",
  "TLC",
  "APFC",
  "CAD",
  "WOLF",
  "AGIX",
  "FRXETH",
  "UB",
  "UP",
  ".COM",
  "BABY",
  "PENG",
  "GYD",
  "HARAMBE",
  "ZCD",
  "CRETA",
  "SOLS",
  "GRAIL",
  "JASON",
  "Dovi",
  "bPRIVA",
  "RETIK",
  "$YAWN",
  "MAD",
  "MAGANOMICS",
  "DOODOO",
  "$mfer",
  "CENS",
  "USDC(WormHole)",
  "IQ50",
  "NIM",
  "DOLLAR",
  "MONOPOLY",
  "RAB",
  "SOURCE",
  "USDM",
  "BECX",
  "MODE",
  "AGG",
  "TBC",
  "FURY",
  "IRO",
  "KITTY",
  "$WATER",
  "$NAP",
  "LFT",
  "ROCK",
  "DUSD",
  "MAW",
  "VIA",
  "ORE",
  "WSDM",
  "RCH",
  "LOCK",
  "BTY",
  "HAMMY",
  "ZACK",
  "MEME",
  "$TIME",
  "SRC",
  "MAX",
  "RTF",
  "MAV",
  "RGOAT",
  "MTS",
  "PLSX",
  "AVIVE",
  "DEXNET",
  "LEE",
  "SVN",
  "FRBK",
  "WUSD",
  "PPT",
  "WAI",
  "BVM",
  "SEED",
  "PT",
  "SWITCH",
  "LIXX",
  "RIZO",
  "DNY",
  "USD0",
  "MELD",
  "MSI",
  "TYBG",
  "PBUX",
  "CR",
  "OLAS",
  "TX20",
  "HRT",
  "FEARNOT",
  "FJO",
  "THR",
  "BODEN",
  "CREPE",
  "cats",
  "PNIC",
  "DEVE",
  "CATGPT",
  "FIGHT",
  "ORBT",
  "BORING",
  "DKS",
  "AXM",
  "MMSS",
  "MPAA",
  "RAKE",
  "NIGELLA",
  "NUTS",
  "SKI",
  "SBCC",
  "PANDO",
  "NLK",
  "DOG",
  "DIFX",
  "DOG",
  "RBT",
  "UBIT",
  "MATAR",
  "VPAD",
  "LHINU",
  "MTK",
  "APEPE",
  "XFI",
  "VOW",
  "WUF",
  "BITBEDR",
  "TRIAS",
  "ZEX",
  "MOXIE",
  "EGGY",
  "FTI",
  "MMUI",
  "OCEAN",
  "PCD",
  "LB",
  "ITO",
  "SRT",
  "ELG",
  "BIT",
  "BNSx",
  "WELL",
  "MGG",
  "PORT3",
  "BTCS",
  "ONUS",
  "MMX",
  "RC",
  "LIF3",
  "USDZ",
  "XEN",
  "DOLA",
  "FOUR",
  "SPOT",
  "TUBES",
  "KEYCAT",
  "MILO",
  "TBC",
  "BIT",
  "BOOMER",
  "BOOM",
  "CENX",
  "FP",
  "FISH",
  "FLIP",
  "IVEX",
  "CAW",
  "UNM",
  "MTC",
  "SPACE",
  "LAIKA",
  "OZONE",
  "TOOKER",
  "ZYN",
  "CATWIF",
  "GRAPE",
  "BLUE",
  "MUSK",
  "STON",
  "PACK",
  "DTEC",
  "VSC",
  "SNS",
  "BLAZE",
  "SKOP",
  "PTH",
  "VRC",
  "TAKI",
  "EPIK",
  "REDO",
  "QUARK",
  "AXT",
  "PLX",
  "FRIEND",
  "MPC",
  "WYS",
  "SAVM",
  "MUNITY",
  "BSC",
  "DOGINME",
  "XOXNO",
  "MOXIE",
  "TAP",
  "XETA",
  "WTK",
  "MATT",
  "CKP",
  "RGAME",
  "HEX",
  "TORI",
  "Liza",
  "HIPP",
  "NEW",
  "LKI",
  "$PURPE",
  "MTC",
  "LBR",
  "THB",
  "UNA",
  "SUDO",
  "QUACK",
  "GGG",
  "DMT",
  "RFL",
  "KEKEC",
  "IXS",
  "MICHI",
  "SOON",
  "CELL",
  "VELA",
  "LETIT",
  "BBC",
  "ETHS",
  "BMAX",
  "LCR",
  "CANTO",
  "APTR",
  "PUPPIES",
  "FXC",
  "$LANDLORD",
  "CAT",
  "CAJ",
  "KOIN",
  "CHIPPY",
  "DAV",
  "JOE",
  "JUNGLE",
  "MAF",
  "QANX",
  "GONDOLA",
  "CHINU",
  "ABEY",
  "GAU",
  "SDCRV",
  "SONIC",
  "ZEDXION",
  "ANDY",
  "ICNX",
  "FAKT",
  "BUB",
  "UP",
  "HIP",
  "CAU",
  "APE",
  "WIGO",
  "KTC",
  "PUNKAI",
  "DZOO",
  "DECENTRALIZED",
  "PONZIO",
  "PEPE",
  "N64",
  "BAG",
  "GEKKO",
  "FLAPPY",
];
const pairList = ["USDT"];
const typeList = [
  {
    label: "General Analysis",
    key: "general_analysis",
  },
  {
    label: "Chart Patterns",
    key: "chart_patterns",
  },
];
const positionList = [
  {
    label: "Buy / Long",
    key: "buy",
  },
  {
    label: "Sell / Short",
    key: "sell",
  },
  {
    label: "Neutral",
    key: "neutral",
  },
];
const strategyList = [
  {
    key: "strategy_general_analysis",
    label: "General Analysis",
  },
  {
    key: "strategy_resistance",
    label: "Resistance",
  },
  {
    key: "strategy_support",
    label: "Support",
  },
  {
    key: "strategy_ascending_triangle",
    label: "Ascending Triangle",
  },
  {
    key: "strategy_descending_triangle",
    label: "Descending Triangle",
  },
  {
    key: "strategy_channel_down",
    label: "Channel Down",
  },
  {
    key: "strategy_channel_up",
    label: "Channel Up",
  },
  {
    key: "strategy_falling_wedge",
    label: "Falling Wedge",
  },
  {
    key: "strategy_rising_wedge",
    label: "Rising Wedge",
  },
  {
    key: "strategy_rectangle",
    label: "Rectangle",
  },
  {
    key: "strategy_head_and_shoulders",
    label: "Head and Shoulders",
  },
  {
    key: "strategy_inverse_head_and_shoulders",
    label: "Inverse Head and Shoulders",
  },
  {
    key: "strategy_triple_top",
    label: "Triple Top",
  },
  {
    key: "strategy_triple_bottom",
    label: "Triple Bottom",
  },
  {
    key: "strategy_triangle",
    label: "Triangle",
  },
  {
    key: "strategy_flag",
    label: "Flag",
  },
  {
    key: "strategy_pennant",
    label: "Pennant",
  },
  {
    key: "strategy_double_top",
    label: "Double Top",
  },
  {
    key: "strategy_double_bottom",
    label: "Double Bottom",
  },
  {
    key: "strategy_3_point_retracement",
    label: "3 Point Retracement",
  },
  {
    key: "strategy_3_point_extension",
    label: "3 Point Extension",
  },
  {
    key: "strategy_abcd",
    label: "ABCD",
  },
  {
    key: "strategy_gartley",
    label: "Gartley",
  },
  {
    key: "strategy_butterfly",
    label: "Butterfly",
  },
  {
    key: "strategy_3_drive",
    label: "3 Drive",
  },
  {
    key: "strategy_consecutive_candles",
    label: "Consecutive Candles",
  },
  {
    key: "strategy_big_movement",
    label: "Big Movement",
  },
];
const timeframeList = [
  {
    key: "1m",
    label: "1 Minute",
  },
  {
    key: "3m",
    label: "3 Minute",
  },
  {
    key: "5m",
    label: "5 Minute",
  },
  {
    key: "10m",
    label: "10 Minute",
  },
  {
    key: "15m",
    label: "15 Minute",
  },
  {
    key: "30m",
    label: "30 Minute",
  },
  {
    key: "1h",
    label: "1 Hours",
  },
  {
    key: "2h",
    label: "2 Hours",
  },
  {
    key: "4h",
    label: "4 Hours",
  },
  {
    key: "12h",
    label: "12 Hours",
  },
  {
    key: "1d",
    label: "1 Day",
  },
  {
    key: "3d",
    label: "3 Day",
  },
  {
    key: "1w",
    label: "1 Week",
  },
];
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function SubmitSignal() {
  const [isLoading, setIsLoading] = useState(true);
  const [symbol, setSymbol] = useState("BTC");
  const [pair, setPair] = useState("USDT");
  const [fadesc, setFaDesc] = useState("");
  const [enndesc, setEnDesc] = useState("");
  const [price, setPrice] = useState(0.0);
  const [type, setType] = useState("");
  const [position, setPosition] = useState("");
  const [strategy, setStrategy] = useState("");
  const [timeframe, setTimeframe] = useState("");
  const [lightchart, setLightChart] = useState("");
  const [darkchart, setDarkChart] = useState("");
  const [botanalysis, setBotAnalysis] = useState(false);
  const [expertanalysis, setExpertAnalysis] = useState(false);
  const [freesignal, setFreeSignal] = useState(false);
  const [logoUrl, setLogoUrl] = useState(
    "https://s3-symbol-logo.tradingview.com/crypto/XTVCBTC.svg"
  );

  async function submit_signal_method() {
    if (
      window.location.pathname
        .toLowerCase()
        .replace("/", "")
        .replace("/", "") === "submit_signal"
    ) {
      const submit_signal_data = await submit_signal_api(
        token,
        symbol,
        pair,
        fadesc,
        enndesc,
        price,
        logoUrl,
        type,
        position,
        strategy,
        timeframe,
        lightchart,
        darkchart,
        botanalysis,
        expertanalysis,
        freesignal
      );
      // if (submit_signal_data[1] !== 200) {
      //   window.location.href = "/account";
      // }
      return submit_signal_data;
    } else {
      return null;
    }
  }

  const handleLogoUrlChange = (event) => {
    setLogoUrl(event.target.value);
  };
  const handleSymbolChange = (label) => {
    setSymbol(label.target.innerHTML);
    console.log(label.target.innerHTML);
    setLogoUrl(
      `https://s3-symbol-logo.tradingview.com/crypto/XTVC${label.target.innerHTML}.svg`
    );
    document.getElementById(
      "combo-box-logo"
    ).value = `https://s3-symbol-logo.tradingview.com/crypto/XTVC${label.target.innerHTML}.svg`;
  };
  const handlePairChange = (label) => {
    setPair(label.target.innerHTML);
  };
  const handleFaDescChange = (label) => {
    setFaDesc(label.target.value);
  };
  const handleEnDescChange = (label) => {
    setEnDesc(label.target.value);
  };
  const handlePriceChange = (event) => {
    var price = event.target.value;
    if (isNaN(price)) {
      toast.error("Price should be a number", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      document.getElementById("combo-box-price").value = "";
      setPrice(0.0);
    } else {
      setPrice(price);
    }
  };
  const handleTypeChange = (option) => {
    let data;
    typeList.forEach((item) => {
      if (item.label === option.target.innerHTML) {
        data = item;
      }
    });

    setType(data["key"]);
  };
  const handlePositionChange = (option) => {
    let data;
    positionList.forEach((item) => {
      if (item.label === option.target.innerHTML) {
        data = item;
      }
    });

    setPosition(data["key"]);
  };
  const handleStrategyChange = (option) => {
    let data;
    strategyList.forEach((item) => {
      if (item.label === option.target.innerHTML) {
        data = item;
      }
    });

    setStrategy(data["key"]);
  };
  const handleTimeframeChange = (option) => {
    let data;
    timeframeList.forEach((item) => {
      if (item.label === option.target.innerHTML) {
        data = item;
      }
    });

    setTimeframe(data["key"]);
  };
  const handleLightChartChange = (event) => {
    if (event.target.files[0].size < 10000000) {
      setLightChart(event.target.files[0]);
    } else {
      toast.error("Image size must be less than 10 MB", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleDarkChartChange = (event) => {
    if (event.target.files[0].size < 10000000) {
      setDarkChart(event.target.files[0]);
    } else {
      toast.error("Image size must be less than 10 MB", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleBotAnalysisChange = (option) => {
    setBotAnalysis(option.target.checked);
  };
  const handleExpertAnalysisChange = (option) => {
    setExpertAnalysis(option.target.checked);
  };
  const handleFreeSignalChange = (option) => {
    setFreeSignal(option.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submit_signal_data = await submit_signal_method();
    console.log(submit_signal_data[1]);
    if (submit_signal_data[1] == 200) {
      toast.success("success", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // setTimeout(() => {
      //   window.location.href = "/submit_signal";
      // }, 2000);
    } else {
      toast.error("Oh we have error", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <div id="submitSignalStyle" className={styled2.WithdrawWraper}>
      <div className={styled2.main}>
        <div className={styled2.navbarTap}>
          <Link to="../dashboard">
            <ArrowLeft size={25} />
          </Link>
          <h2>ثبت سیگنال</h2>
        </div>
        <div className={styled2.withdrawHistoryTable}>
          <form onSubmit={handleSubmit} className={styled2.formSystem}>
            <Autocomplete
              disablePortal
              id="combo-box-symbol"
              options={symbolsList}
              fullWidth
              onChange={(option) => handleSymbolChange(option)}
              renderInput={(params) => (
                <TextField required {...params} label="Symbol" />
              )}
            />
            <Autocomplete
              disablePortal
              id="combo-box-pair"
              options={pairList}
              fullWidth
              onChange={(option) => handlePairChange(option)}
              renderInput={(params) => (
                <TextField required {...params} label="Pair" />
              )}
            />
            <TextField
              id="combo-box-persian-description"
              label="Persian description"
              required
              multiline
              fullWidth
              rows={7}
              onChange={(option) => handleFaDescChange(option)}
            />
            <TextField
              id="combo-box-english-description"
              label="English description"
              required
              multiline
              fullWidth
              rows={7}
              onChange={(option) => handleEnDescChange(option)}
            />
            <FormControl fullWidth>
              <InputLabel fullWidth htmlFor="outlined-adornment-amount">
                Price
              </InputLabel>
              <OutlinedInput
                required
                fullWidth
                id="combo-box-price"
                onChange={(event) => handlePriceChange(event)}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Price"
              />
            </FormControl>
            <div className={styled2.showlogo}>
              <img src={logoUrl} alt="" />
              <TextField
                required
                fullWidth
                id="combo-box-logo"
                label="Logo url"
                variant="outlined"
                defaultValue={logoUrl}
                onChange={handleLogoUrlChange}
              />
            </div>
            <Autocomplete
              disablePortal
              id="combo-box-type"
              options={typeList}
              fullWidth
              onChange={(option) => handleTypeChange(option)}
              renderInput={(params) => (
                <TextField required {...params} label="Type" />
              )}
            />
            <Autocomplete
              disablePortal
              id="combo-box-position"
              options={positionList}
              fullWidth
              onChange={(option) => handlePositionChange(option)}
              renderInput={(params) => (
                <TextField required {...params} label="Position Side" />
              )}
            />
            <Autocomplete
              disablePortal
              id="combo-box-strategy"
              options={strategyList}
              fullWidth
              onChange={(option) => handleStrategyChange(option)}
              renderInput={(params) => (
                <TextField required {...params} label="Strategy" />
              )}
            />
            <Autocomplete
              disablePortal
              id="combo-box-timeframe"
              options={timeframeList}
              fullWidth
              onChange={(option) => handleTimeframeChange(option)}
              renderInput={(params) => (
                <TextField required {...params} label="Timeframe" />
              )}
            />
            <Button
              component="label"
              required
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Light Mode chart
              <VisuallyHiddenInput
                onChange={(event) => handleLightChartChange(event)}
                id="combo-box-light-chart"
                required
                type="file"
                accept=".jpg, .jpeg"
              />
            </Button>
            <Button
              component="label"
              required
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Dark Mode chart
              <VisuallyHiddenInput
                onChange={(event) => handleDarkChartChange(event)}
                id="combo-box-dark-chart"
                required
                type="file"
                accept=".jpg, .jpeg"
              />
            </Button>
            <div className={styled2.checkes}>
              <FormControlLabel
                onChange={(event) => handleBotAnalysisChange(event)}
                control={<Checkbox />}
                label="Bot Analysis"
              />
              <FormControlLabel
                onChange={(event) => handleExpertAnalysisChange(event)}
                control={<Checkbox />}
                label="Experts Analysis"
              />
            </div>
            <div className={styled2.checkes}>
              <FormControlLabel
                onChange={(event) => handleFreeSignalChange(event)}
                control={<Checkbox />}
                label="Free Signal"
              />
            </div>
            <Button
              component="label"
              required
              role={undefined}
              variant="contained"
              tabIndex={-1}
            >
              Submit
              <VisuallyHiddenInput type="submit" />
            </Button>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </form>
          <ToastContainer />
        </div>
      </div>
      <Loading active={isLoading} />
    </div>
  );
}
export default SubmitSignal;
