import React, { useState, useEffect } from "react";
import Describing from "../../component/menu/Menu";
import Loading from "../../component/loading/Loading";
import menulight from "../../assets/img/menu-light.png";
import menudark from "../../assets/img/menu-dark.png";
import base_link from "../../config/config";
import styled2 from "./plans.module.css";
import { plans } from "../../component/api/plans";
import buy_plan_api from "../../component/api/buy_plan";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "react-toastify/dist/ReactToastify.css";
import { FlipWords } from "../../component/ui/flip-words";
import { ShieldCross, ShieldTick, Wallet2, Coin1, MirroringScreen} from "iconsax-react";
import { flexbox, style, width } from "@mui/system";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MultiStepLoader as Loader } from "../../component/ui2/multi-step-loader";
import { IconSquareRoundedX } from "@tabler/icons-react";

const label = { inputProps: { "aria-label": "var(--font)" } };
var language_json = JSON.parse(localStorage.getItem("language"));
const token = localStorage.getItem("token");

async function get_plans_data() {
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
    "plans"
  ) {
    return await plans(token);
  } else {
    return null;
  }
}

async function get_buy_plan_data(txid, for_what, plan_name, plan_index) {
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
    "plans"
  ) {
    return await buy_plan_api(token, txid, for_what, plan_name, plan_index);
  } else {
    return null;
  }
}

function PlansInfo() {
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState("fa");
  const [theme, setTheme] = useState("light");
  const [plan_datas, setPlansData] = useState([]);
  const [chart_plan_slides, setPlansSlide] = useState([]);
  const [firstDirection, setFirstDirection] = useState(false);
  const [openTXID, setOpenTXID] = useState(false);
  const [wallet_address_text, SetWalletAddress] = useState(<div></div>);
  const [wallet_coin_text, SetWalletCoin] = useState(<div></div>);
  const [wallet_network_text, SetWalletnetwork] = useState(<div></div>);
  const [openRule, setOpenRule] = useState(false);
  const [checked, setChecked] = useState(false);
  const [TXID, setTXID] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [for_what_status, setForWhatStatus] = useState("buyplan");
  const [plan_name_status, setPlanNameStatus] = useState("Diamond");
  const [plan_index_status, setPlanIndexStatus] = useState("1");
  const [loading, setLoading] = useState(false);

  if (!firstDirection) {
    document.documentElement.style.setProperty("--direction", "ltr");
    document.documentElement.style.setProperty("--direction-ref", "rtl");
    setFirstDirection(true);
  }

  const DialogContentTextWrapper = styled("DialogContentText")(({ theme }) => ({
    fontFamily: "var(--font)",
  }));
  const DialogTitleWrapper = styled("DialogTitle")(({ theme }) => ({
    fontFamily: "var(--font)",
    textAlign: "center",
  }));

  const handleChangeTXID = (event) => {
    setTXID(event.target.value);
  };
  const handleChangeCheckBox = (event) => {
    if (!event.target.checked) {
      document.getElementById("wallet_address").classList.add("fader");
      document.getElementById("wallet_coin").classList.add("fader");
      document.getElementById("wallet_network").classList.add("fader");
    } else {
      document.getElementById("wallet_address").classList.remove("fader");
      document.getElementById("wallet_coin").classList.remove("fader");
      document.getElementById("wallet_network").classList.remove("fader");
    }
    setChecked(event.target.checked);
  };
  const handleClickOpenTXID1 = () => {
    setPlanIndexStatus(String(1));
    setOpenTXID(true);
  };
  const handleClickOpenTXID2 = () => {
    setPlanIndexStatus(String(2));
    setOpenTXID(true);
  };
  const handleClickOpenTXID3 = () => {
    setPlanIndexStatus(String(3));
    setOpenTXID(true);
  };
  const handleCloseTXID = () => {
    setOpenTXID(false);
  };
  const handleBuyWithTXID = () => {
    if (TXID !== "" && TXID !== " ") {
      if (checked) {
        handleOpenBackdrop();
        setOpenTXID(false);
        const fetchData = async () => {
          const buy_plan_data = await get_buy_plan_data(
            TXID,
            for_what_status,
            plan_name_status,
            plan_index_status
          );
          if (buy_plan_data[1] === 200) {
            const buy_plan_json = buy_plan_data[0];
            handleCloseBackdrop();
            setLoading(true);
            // toast.success(
            //   language_json[language][String(buy_plan_json["message"])],
            //   {
            //     position:
            //       language === "fa"
            //         ? "bottom-right"
            //         : language === "en"
            //         ? "bottom-left"
            //         : "bottom-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     rtl:
            //       language === "fa" ? true : language === "en" ? false : true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: theme,
            //   }
            // );
          } else {
            handleCloseBackdrop();
            toast.error(
              language_json[language][String(buy_plan_data[0]["message"])],
              {
                position:
                  language === "fa"
                    ? "bottom-right"
                    : language === "en"
                    ? "bottom-left"
                    : "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                rtl:
                  language === "fa" ? true : language === "en" ? false : true,
                draggable: true,
                progress: undefined,
                theme: theme,
              }
            );
          }
        };
        fetchData();
      } else {
        toast.error(language_json[language]["should_agree_with_rule"], {
          position:
            language === "fa"
              ? "bottom-right"
              : language === "en"
              ? "bottom-left"
              : "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: language === "fa" ? true : language === "en" ? false : true,
          draggable: true,
          progress: undefined,
          theme: theme,
        });
      }
    } else {
      toast.error(language_json[language]["txid_should_not_be_empty"], {
        position:
          language === "fa"
            ? "bottom-right"
            : language === "en"
            ? "bottom-left"
            : "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: language === "fa" ? true : language === "en" ? false : true,
        draggable: true,
        progress: undefined,
        theme: theme,
      });
    }
  };
  const handleClickOpenRule = () => {
    setOpenRule(true);
  };
  const handleCloseRule = () => {
    setOpenRule(false);
  };
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    console.log("hellobro");
    setOpenBackdrop(true);
  };

  const changeLanguage = () => {
    const newLanguage = language === "fa" ? "en" : "fa";

    var SidebarControl = document.getElementById("menucontrol");

    localStorage.setItem("select_language", newLanguage);
    if (newLanguage === "fa") {
      SidebarControl.style.direction = "rtl";
      document.documentElement.style.setProperty("--font", "var(--fa-font)");
      document.documentElement.style.setProperty("--direction", "ltr");
      document.documentElement.style.setProperty("--direction-ref", "rtl");
    } else if (newLanguage === "en") {
      SidebarControl.style.direction = "ltr";
      document.documentElement.style.setProperty("--font", "var(--en-font)");
      document.documentElement.style.setProperty("--direction", "rtl");
      document.documentElement.style.setProperty("--direction-ref", "ltr");
    }
    setLanguage(newLanguage);
    planCon(newLanguage);
  };
  const changeTheme = () => {
    var moonbutton = document.getElementById("moonbutton");
    var sunbutton = document.getElementById("sunbutton");
    var menuimg = document.getElementById("menuimg");
    var tickerTape = document.getElementById("tickerTape");

    const newtheme = theme === "light" ? "dark" : "light";

    tickerTape.src = `${base_link}/ticker_tape/${newtheme}`;

    localStorage.setItem("select_theme", newtheme);
    if (newtheme === "light") {
      sunbutton.style.display = "inline";
      moonbutton.style.display = "none";

      menuimg.src = menulight;

      document.documentElement.style.setProperty("--Text", "var(--light-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--light-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--light-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--light-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--light-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--light-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--light-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--light-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--light-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--light-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--light-code-img)"
      );
    } else if (newtheme === "dark") {
      moonbutton.style.display = "inline";
      sunbutton.style.display = "none";

      menuimg.src = menudark;

      document.documentElement.style.setProperty("--Text", "var(--dark-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--dark-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--dark-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--dark-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--dark-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--dark-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--dark-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--dark-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--dark-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--dark-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--dark-code-img)"
      );
    }
    setTheme(newtheme);
  };
  useEffect(() => {
    const selectLanguage = localStorage.getItem("select_language");
    const selectTheme = localStorage.getItem("select_theme");
    if (selectLanguage === "en") {
      changeLanguage();
    }
    if (selectTheme === "dark") {
      changeTheme();
    }
    if (selectLanguage !== "en") {
      planCon(language);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);
  const planCon = (lang) => {
    if (plan_datas.length === 0) {
      const fetchData = async () => {
        const strategy_data = await get_plans_data();
        const strategy_json =
          strategy_data[1] === 200 ? strategy_data[0]["data"] : [];
        SetWalletAddress(
          strategy_data[1] === 200
            ? strategy_data[0]["wallet_address"]
            : "Error"
        );
        SetWalletCoin(
          strategy_data[1] === 200 ? strategy_data[0]["coin"] : "Error"
        );
        SetWalletnetwork(
          strategy_data[1] === 200 ? strategy_data[0]["network"] : "Error"
        );

        setPlansData(strategy_json);
        const plan_slides = (
          <div className={styled2.planDiv}>
            <h2 className={styled2.planFName}>
              {strategy_json[0][lang]["name"]}
            </h2>
            <span className={styled2.planFDes}>
              {strategy_json[0][lang]["des"]}
            </span>
            <div className={styled2.planCardP}>
              {strategy_json[0]["pt"].map((item, index) => (
                <div className={styled2.planCard}>
                  <h3 className={styled2.planCardName}>{item[lang]["name"]}</h3>
                  <h4 className={styled2.planCardPrice}>
                    {item["day"]} {language_json[lang]["days"]} /{item["price"]}{" "}
                    {language_json[lang]["usdt"]}
                  </h4>
                  <div className={styled2.planCardItem}>
                    {item[lang]["option"].map((oplanItem, index) => (
                      <span className={styled2.planCardItemSpan}>
                        {oplanItem[1] === "true" ? (
                          <ShieldTick color="var(--Success)"></ShieldTick>
                        ) : (
                          <ShieldCross color="var(--Error)"></ShieldCross>
                        )}{" "}
                        {oplanItem[0]}
                      </span>
                    ))}
                  </div>
                  <div className={styled2.planCardButtonP}>
                    <button
                      onClick={
                        index === 0
                          ? handleClickOpenTXID1
                          : index === 1
                          ? handleClickOpenTXID2
                          : index === 2
                          ? handleClickOpenTXID3
                          : handleClickOpenTXID1
                      }
                      className={styled2.planCardButton}
                    >
                      {language_json[lang]["buy"]}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
        setPlansSlide(plan_slides);
      };
      fetchData();
    } else {
      const strategy_data = plan_datas;
      const strategy_json = strategy_data;
      setPlansData(strategy_json);
      // const plan_slides = strategy_json.map((slide, index) => <></>);
      const plan_slides = (
        <div className={styled2.planDiv}>
          <h2 className={styled2.planFName}>
            {strategy_json[0][lang]["name"]}
          </h2>
          <span className={styled2.planFDes}>
            {strategy_json[0][lang]["des"]}
          </span>
          <div className={styled2.planCardP}>
            {strategy_json[0]["pt"].map((item, index) => (
              <div className={styled2.planCard}>
                <h3 className={styled2.planCardName}>{item[lang]["name"]}</h3>
                <h4 className={styled2.planCardPrice}>
                  {item["day"]} {language_json[lang]["days"]} / {item["price"]}{" "}
                  {language_json[lang]["usdt"]}
                </h4>
                <div className={styled2.planCardItem}>
                  {item[lang]["option"].map((oplanItem, index) => (
                    <span className={styled2.planCardItemSpan}>
                      {oplanItem[1] === "true" ? (
                        <ShieldTick color="var(--Success)"></ShieldTick>
                      ) : (
                        <ShieldCross color="var(--Error)"></ShieldCross>
                      )}{" "}
                      {oplanItem[0]}
                    </span>
                  ))}
                </div>
                <div className={styled2.planCardButtonP}>
                  <button
                    onClick={
                      index === 0
                        ? handleClickOpenTXID1
                        : index === 1
                        ? handleClickOpenTXID2
                        : index === 2
                        ? handleClickOpenTXID3
                        : handleClickOpenTXID1
                    }
                    className={styled2.planCardButton}
                  >
                    {language_json[lang]["buy"]}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
      setPlansSlide(plan_slides);
    }
  };

  const iframeSrc = `${base_link}/ticker_tape/light`;
  return (
    <div id="PlansInfoWraper" className={styled2.PlansInfoWraper}>
      {/* BACKDROP */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* RULE DIALOG */}
      <Dialog
        open={openRule}
        onClose={handleCloseRule}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitleWrapper id="alert-dialog-title">
          <h3>{language_json[language]["payment_rule"]}</h3>
        </DialogTitleWrapper>
        <DialogContent>
          <p
            style={{ direction: "var(--direction-ref)" }}
            id="alert-dialog-description"
            dangerouslySetInnerHTML={{
              __html: String(
                language_json[language]["payment_rule_text"]
              ).replaceAll("\n", "<br />"),
            }}
          ></p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRule} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {/* TXID DIALOG */}
      <Dialog
        open={openTXID}
        onClose={handleCloseTXID}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitleWrapper id="alert-dialog-title">
          <h3>{language_json[language]["buy_with_crypto_title"]}</h3>
        </DialogTitleWrapper>
        <DialogContent>
          <div className={styled2.TXIDDialogElements}>
            <img
              style={{ width: "40%" }}
              src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&margin=10&data=${wallet_address_text}`}
              alt=""
            />
            <Chip
              id="wallet_address"
              variant="outlined"
              icon={<Wallet2 />}
              label={wallet_address_text}
            />
            <div style={{ display: "flex", gap: "10px" }}>
              <Chip
                id="wallet_coin"
                variant="outlined"
                icon={<Coin1 />}
                label={wallet_coin_text}
              />
              <Chip
                id="wallet_network"
                variant="outlined"
                icon={<MirroringScreen />}
                label={wallet_network_text}
              />
            </div>
          </div>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="TXID (HASH)"
            type="email"
            fullWidth
            variant="standard"
            onChange={handleChangeTXID}
          />
          <DialogContentTextWrapper id="alert-dialog-description">
            <Checkbox
              checked={checked}
              onChange={handleChangeCheckBox}
              inputProps={{ "aria-label": "controlled" }}
              {...label}
              sx={{
                color: "var(--Accent)",
                "&.Mui-checked": {
                  color: "var(--Accent)",
                },
              }}
            />
            <h5Wrapper
              className={styled2.textpointer}
              onClick={handleClickOpenRule}
            >
              {language_json[language]["i_agree_with_the_rules"]}
            </h5Wrapper>
          </DialogContentTextWrapper>
        </DialogContent>
        <DialogActions>
          <button onClick={handleBuyWithTXID} autoFocus>
            {language_json[language]["check"]}
          </button>
        </DialogActions>
      </Dialog>
      {/* LOADING */}
      <Loader
        theme={theme}
        loadingStates={language_json[language]["loading_payment"]}
        loading={loading}
        duration={2000}
      />

      <div className={styled2.main}>
        <div className={styled2.navbarTap}>
          <iframe id="tickerTape" src={iframeSrc} title="Ticker Tape" />
        </div>
        <div className={styled2.slogan}>
          <div className={styled2.sloganInner}>
            {language_json[language]["with_signalCurrency"]}
            <b className={styled2.sloganInner2}>
              <FlipWords words={language_json[language]["slogan_plan"]} />
            </b>
          </div>
          {language_json[language]["do_trade"]}
        </div>

        {loading && (
          <button onClick={() => setLoading(false)}>
            <IconSquareRoundedX className="h-10 w-10" />
          </button>
        )}

        {chart_plan_slides}
        <br />
        <br />
      </div>
      <Describing
        changeLanguage={changeLanguage}
        language={language}
        changeTheme={changeTheme}
        theme={theme}
      />
      <Loading active={isLoading} />
      <ToastContainer />
    </div>
  );
}
export default PlansInfo;
