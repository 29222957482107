import { Flag2 } from "iconsax-react";
import { Link } from "react-router-dom";
import button from "../../assets/img/buttonText.png";
import styled from "./Signalinfo.module.css";
import React, { useEffect, useState } from "react";
import Describing from "../../component/menu/Menu";
import base_link from "../../config/config";
import moment from "moment";
import menulight from "../../assets/img/menu-light.png";
import menudark from "../../assets/img/menu-dark.png";
import { signal_info } from "../../component/api/signal_info";
import { useParams } from "react-router";
import Loading from "../../component/loading/Loading";

var language_json = JSON.parse(localStorage.getItem("language"));
const token = localStorage.getItem("token");

function timeCalcu(time, lang) {
  const now = moment();
  const targetDate = moment(time);
  const duration = moment.duration(now.diff(targetDate));
  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths());
  const weeks = Math.floor(duration.asWeeks());
  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes());

  let result = [0, ""];
  if (years > 0) {
    result[0] = years;
    result[1] = "years";
  } else if (months > 0) {
    result[0] = months;
    result[1] = "months";
  } else if (weeks > 0) {
    result[0] = weeks;
    result[1] = "weeks";
  } else if (days > 0) {
    result[0] = days;
    result[1] = "days";
  } else if (hours > 0) {
    result[0] = hours;
    result[1] = "hours";
  } else if (minutes > 0) {
    result[0] = minutes;
    result[1] = "minutes";
  } else {
    result[0] = 0;
    result[1] = "now";
  }

  return result[1] !== "now"
    ? result[0] +
        " " +
        language_json[lang][result[1]] +
        " " +
        language_json[lang]["ago"]
    : language_json[lang][result[1]];
}
function OpenPage(link, target = "blank") {
  window.open(link, `_${target}`);
}
async function get_strategy_info(signal_id) {
  if (
    window.location.pathname
      .toLowerCase()
      .replace("/", "")
      .replace("/", "")
      .slice(0, 10) === "signalinfo"
  ) {
    const data = await signal_info(token, signal_id);
    if (data[1] === 200) {
      return data;
    } else if (data[1] === 401) {
      window.location.href = "/login";
    } else if (data[1] === 404) {
      window.location.href = "/404";
    }
  } else {
    return null;
  }
}

function Signalinfo() {
  const { id } = useParams();
  const iframeSrc = `${base_link}/ticker_tape/light`;

  const [language, setLanguage] = useState("fa");
  const [theme, setTheme] = useState("light");

  const [signal_info_slides, setSignalInfo] = useState([]);
  const [signal_info_datas, setSignalInfoData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const changeLanguage = (check = true) => {
    const newLanguage = language === "fa" ? "en" : "fa";

    var SidebarControl = document.getElementById("menucontrol");

    localStorage.setItem("select_language", newLanguage);
    if (newLanguage == "fa") {
      SidebarControl.style.direction = "rtl";
      document.documentElement.style.setProperty("--font", "var(--fa-font)");
      document.documentElement.style.setProperty("--direction", "ltr");
      document.documentElement.style.setProperty("--direction-ref", "rtl");
    } else if (newLanguage == "en") {
      SidebarControl.style.direction = "ltr";
      document.documentElement.style.setProperty("--font", "var(--en-font)");
      document.documentElement.style.setProperty("--direction", "rtl");
      document.documentElement.style.setProperty("--direction-ref", "ltr");
    }
    setLanguage(newLanguage);
    if (check) {
      signal_infoCon(newLanguage, theme);
    }
  };
  const changeTheme = (check = true) => {
    var moonbutton = document.getElementById("moonbutton");
    var sunbutton = document.getElementById("sunbutton");
    var menuimg = document.getElementById("menuimg");
    var tickerTape = document.getElementById("tickerTape");

    const newtheme = theme === "light" ? "dark" : "light";

    tickerTape.src = `${base_link}/ticker_tape/${newtheme}`;

    localStorage.setItem("select_theme", newtheme);
    if (newtheme === "light") {
      sunbutton.style.display = "inline";
      moonbutton.style.display = "none";

      menuimg.src = menulight;

      document.documentElement.style.setProperty("--Text", "var(--light-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--light-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--light-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--light-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--light-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--light-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--light-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--light-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--light-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--light-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--light-code-img)"
      );
    } else if (newtheme === "dark") {
      moonbutton.style.display = "inline";
      sunbutton.style.display = "none";

      menuimg.src = menudark;

      document.documentElement.style.setProperty("--Text", "var(--dark-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--dark-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--dark-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--dark-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--dark-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--dark-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--dark-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--dark-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--dark-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--dark-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--dark-code-img)"
      );
    }
    setTheme(newtheme);
    if (check) {
      signal_infoCon(language, newtheme);
    }
  };
  const signal_infoCon = (lang, tem) => {
    if (signal_info_datas.length === 0) {
      const fetchData = async () => {
        const signal_info_data = await get_strategy_info(id);
        if (signal_info_data[1] === 200) {
          const signal_info_json =
            signal_info_data[1] === 200 ? signal_info_data[0]["data"] : [];
          setSignalInfoData(signal_info_json);
          const signal_info_slide = (
            <div className={styled.SignalinfoBox}>
              <div className={styled.img}>
                <img src={signal_info_json[lang][tem]["screen"]} />
              </div>

              <div className={styled.SignalinfoText}>
                <div className={styled.textBtc}>
                  <h6 style={{ direction: lang === "fa" ? "rtl" : "ltr" }}>
                    <span>{language_json[lang]["description"]} :</span>
                    <br />
                    {signal_info_json[lang]["des"]}
                  </h6>
                </div>
                <div className={styled.box}>
                  <div className={styled.textbox}>
                    <div className={styled.text}>
                      <div className={styled.time}>
                        <h6>
                          {timeCalcu(signal_info_json["timestamp"], lang)}
                        </h6>
                      </div>
                      <div className={styled.iconbox}>
                        <div className={styled.red}>
                          <button
                            style={{
                              backgroundColor:
                                signal_info_json["position"] === "buy"
                                  ? "var(--Success)"
                                  : signal_info_json["position"] === "sell"
                                  ? "var(--Error)"
                                  : signal_info_json["position"] === "neutral"
                                  ? "var(--Stroke)"
                                  : "var(--Stroke)" + ")",
                            }}
                          >
                            {language_json[lang][signal_info_json["position"]]}
                          </button>
                        </div>
                        <h6>
                          {signal_info_json["symbol"]}
                          <span>/{signal_info_json["pair"]}</span>
                        </h6>
                        <img
                          className={styled.cryptoIcon}
                          src={signal_info_json["logo"]}
                        />
                      </div>
                    </div>
                    <div className={styled.text}>
                      <div>
                        <h6>
                          {language_json[lang]["strategy"]}/{" "}
                          {language_json[lang]["pattern"]} :
                        </h6>
                      </div>
                      <div className={styled.Flag}>
                        <h6>
                          {" "}
                          {language_json[lang][signal_info_json["strategy"]]}
                          <Flag2 size="15" />
                        </h6>
                      </div>
                    </div>
                    <div className={styled.text}>
                      <div>
                        <h6>{language_json[lang]["timeframe"]} :</h6>
                      </div>
                      <div>
                        <h6>
                          {language_json[lang][signal_info_json["timeframe"]]}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <Link
                    to={`https://www.lbank.com/futures/${signal_info_json["symbol"]}${signal_info_json["pair"]}`}
                    target="_blank"
                    className={styled.button}
                  >
                    {" "}
                    <img src={button} />
                  </Link>
                </div>
              </div>
            </div>
          );
          setSignalInfo(signal_info_slide);
        } else if (signal_info_data[1] === 401) {
          window.location.href = "/login";
        } else if (signal_info_data[1] === 404) {
          window.location.href = "/404";
        }
      };
      fetchData();
    } else {
      const signal_info_slide = (
        <div className={styled.SignalinfoBox}>
          <div className={styled.img}>
            <img src={signal_info_datas[lang][tem]["screen"]} />
          </div>

          <div className={styled.SignalinfoText}>
            <div className={styled.textBtc}>
              <h6 style={{ direction: lang === "fa" ? "rtl" : "ltr" }}>
                <span>{language_json[lang]["description"]} :</span>
                <br />
                {signal_info_datas[lang]["des"]}
              </h6>
            </div>
            <div className={styled.box}>
              <div className={styled.textbox}>
                <div className={styled.text}>
                  <div className={styled.time}>
                    <h6>{timeCalcu(signal_info_datas["timestamp"], lang)}</h6>
                  </div>
                  <div className={styled.iconbox}>
                    <div className={styled.red}>
                      <button
                        style={{
                          backgroundColor:
                            signal_info_datas["position"] === "buy"
                              ? "var(--Success)"
                              : signal_info_datas["position"] === "sell"
                              ? "var(--Error)"
                              : signal_info_datas["position"] === "neutral"
                              ? "var(--Stroke)"
                              : "var(--Stroke)" + ")",
                        }}
                      >
                        {language_json[lang][signal_info_datas["position"]]}
                      </button>
                    </div>
                    <h6>
                      {signal_info_datas["symbol"]}
                      <span>/{signal_info_datas["pair"]}</span>
                    </h6>
                    <img
                      className={styled.cryptoIcon}
                      src={signal_info_datas["logo"]}
                    />
                  </div>
                </div>
                <div className={styled.text}>
                  <div>
                    <h6>
                      {language_json[lang]["strategy"]}/{" "}
                      {language_json[lang]["pattern"]} :
                    </h6>
                  </div>
                  <div className={styled.Flag}>
                    <h6>
                      {" "}
                      {language_json[lang][signal_info_datas["strategy"]]}
                      <Flag2 size="15" />
                    </h6>
                  </div>
                </div>
                <div className={styled.text}>
                  <div>
                    <h6>{language_json[lang]["timeframe"]} :</h6>
                  </div>
                  <div>
                    <h6>
                      {language_json[lang][signal_info_datas["timeframe"]]}
                    </h6>
                  </div>
                </div>
              </div>
              <Link
                to={`https://www.binance.com/en/trade/${signal_info_datas["symbol"]}_${signal_info_datas["pair"]}`}
                target="_blank"
                className={styled.button}
              >
                {" "}
                <img src={button} />
              </Link>
            </div>
          </div>
        </div>
      );
      setSignalInfo(signal_info_slide);
    }
  };
  useEffect(() => {
    const selectLanguage = localStorage.getItem("select_language");
    const selectTheme = localStorage.getItem("select_theme");
    if (selectLanguage === "en" && selectTheme === "dark") {
      signal_infoCon("en", "dark");
      changeLanguage(false);
      changeTheme(false);
    } else if (selectLanguage === "en") {
      changeLanguage();
    } else if (selectTheme === "dark") {
      changeTheme();
    } else if (selectLanguage !== "en" && selectTheme !== "dark") {
      signal_infoCon(language, theme);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);
  return (
    <div id="signalWraper" className={styled.signalWraper}>
      <div className={styled.main}>
        <div className={styled.navbarTap}>
          <iframe id="tickerTape" src={iframeSrc} title="Ticker Tape" />
        </div>
        {signal_info_slides}
        {/* <div className={styled.SignalinfoBox}>
          <div className={styled.img}>
            <img src={img} />
          </div>

          <div className={styled.SignalinfoText}>
            <div className={styled.textBtc}>
              <h6 style={{direction: language==="fa"?"rtl":"ltr"}}>
                <span>{language_json[language]['description']} :</span>
                <br />
                {signal_info_datas[language]['des']}
              </h6>
            </div>
            <div className={styled.box}>
              <div className={styled.textbox}>
                <div className={styled.text}>
                  <div className={styled.time}>
                    <h6>{timeCalcu(signal_info_datas['timestamp'],language)}</h6>
                  </div>
                  <div className={styled.iconbox}>
                    <div className={styled.red}>
                      <button>فروش</button>
                    </div>
                    <h6>
                      BTC
                      <span>/USDT</span>
                    </h6>
                    <img src={btc} />
                  </div>
                </div>
                <div className={styled.text}>
                  <div>
                    <h6>استراتژی/ الگو :</h6>
                  </div>
                  <div className={styled.Flag}>
                    <h6>
                      {" "}
                      الگو پرچم <Flag2 size="15" />
                    </h6>
                  </div>
                </div>
                <div className={styled.text}>
                  <div>
                    <h6>تایم فریم :</h6>
                  </div>
                  <div>
                    <h6>1 ساعت</h6>
                  </div>
                </div>
              </div>
              <a className={styled.button}>
                {" "}
                <img src={button} />
              </a>
            </div>
          </div>
        </div> */}
      </div>

      <Describing
        changeLanguage={changeLanguage}
        language={language}
        changeTheme={changeTheme}
        theme={theme}
      />
      <Loading active={isLoading} />
    </div>
  );
}
export default Signalinfo;
