import { Link } from "react-router-dom";
import styled from "./General.module.css";
import React, { useState, useEffect } from "react";
import Describing from "../../component/menu/Menu";
import base_link from "../../config/config";
import moment from "moment";
import menulight from "../../assets/img/menu-light.png";
import menudark from "../../assets/img/menu-dark.png";
import { general_analysis } from "../../component/api/general_analysis";
import Loading from "../../component/loading/Loading";

var language_json = JSON.parse(localStorage.getItem("language"));
const token = localStorage.getItem("token");

function timeCalcu(time, lang) {
  const now = moment();
  const targetDate = moment(time);
  const duration = moment.duration(now.diff(targetDate));
  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths());
  const weeks = Math.floor(duration.asWeeks());
  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes());

  let result = [0, ""];
  if (years > 0) {
    result[0] = years;
    result[1] = "years";
  } else if (months > 0) {
    result[0] = months;
    result[1] = "months";
  } else if (weeks > 0) {
    result[0] = weeks;
    result[1] = "weeks";
  } else if (days > 0) {
    result[0] = days;
    result[1] = "days";
  } else if (hours > 0) {
    result[0] = hours;
    result[1] = "hours";
  } else if (minutes > 0) {
    result[0] = minutes;
    result[1] = "minutes";
  } else {
    result[0] = 0;
    result[1] = "now";
  }

  return result[1] !== "now"
    ? result[0] +
        " " +
        language_json[lang][result[1]] +
        " " +
        language_json[lang]["ago"]
    : language_json[lang][result[1]];
}
function OpenPage(link, target = "blank") {
  window.open(link, `_${target}`);
}
async function get_general_analysis(limit, full_data, page) {
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
    "general"
  ) {
    return await general_analysis(token, limit, full_data, page);
  } else {
    return null;
  }
}

function General() {
  const iframeSrc = `${base_link}/ticker_tape/light`;

  const [language, setLanguage] = useState("fa");
  const [theme, setTheme] = useState("light");

  const [general_analysis_slides, setGeneralAnalysis] = useState([]);
  const [general_analysis_datas, setGeneralAnalysisData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [firstDirection, setFirstDirection] = useState(false);

  if (!firstDirection) {
    document.documentElement.style.setProperty("--direction", "ltr");
    document.documentElement.style.setProperty("--direction-ref", "rtl");
    setFirstDirection(true);
  }
  const changeLanguage = () => {
    const newLanguage = language === "fa" ? "en" : "fa";

    var SidebarControl = document.getElementById("menucontrol");

    localStorage.setItem("select_language", newLanguage);
    if (newLanguage == "fa") {
      SidebarControl.style.direction = "rtl";
      document.documentElement.style.setProperty("--font", "var(--fa-font)");
      document.documentElement.style.setProperty("--direction", "ltr");
      document.documentElement.style.setProperty("--direction-ref", "rtl");
    } else if (newLanguage == "en") {
      SidebarControl.style.direction = "ltr";
      document.documentElement.style.setProperty("--font", "var(--en-font)");
      document.documentElement.style.setProperty("--direction", "rtl");
      document.documentElement.style.setProperty("--direction-ref", "ltr");
    }
    setLanguage(newLanguage);
    general_analysisCon(newLanguage);
  };
  const changeTheme = () => {
    var moonbutton = document.getElementById("moonbutton");
    var sunbutton = document.getElementById("sunbutton");
    var menuimg = document.getElementById("menuimg");
    var tickerTape = document.getElementById("tickerTape");

    const newtheme = theme === "light" ? "dark" : "light";

    tickerTape.src = `${base_link}/ticker_tape/${newtheme}`;

    localStorage.setItem("select_theme", newtheme);
    if (newtheme === "light") {
      sunbutton.style.display = "inline";
      moonbutton.style.display = "none";

      menuimg.src = menulight;

      document.documentElement.style.setProperty("--Text", "var(--light-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--light-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--light-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--light-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--light-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--light-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--light-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--light-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--light-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--light-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--light-code-img)"
      );
    } else if (newtheme === "dark") {
      moonbutton.style.display = "inline";
      sunbutton.style.display = "none";

      menuimg.src = menudark;

      document.documentElement.style.setProperty("--Text", "var(--dark-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--dark-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--dark-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--dark-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--dark-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--dark-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--dark-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--dark-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--dark-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--dark-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--dark-code-img)"
      );
    }
    setTheme(newtheme);
  };
  const general_analysisCon = (lang) => {
    if (general_analysis_datas.length === 0) {
      const fetchData = async () => {
        const general_analysis_data = await get_general_analysis(
          100,
          "true",
          1
        );
        const general_analysis_json =
          general_analysis_data[1] === 200
            ? general_analysis_data[0]["data"]
            : [];
        setGeneralAnalysisData(general_analysis_json);
        const general_analysis_slide = general_analysis_json.map(
          (slide, index) => (
            <Link to={`/signalinfo/${slide["signal_id"]}`} target="_blank">
              <div className={styled.text}>
                <div className={styled.time}>
                  <h6>{timeCalcu(slide["timestamp"], lang)}</h6>
                </div>
                <div className={styled.iconbox}>
                  <div className={styled.black}>
                    <button
                      style={{
                        backgroundColor:
                          slide["position"] === "buy"
                            ? "var(--Success)"
                            : slide["position"] === "sell"
                            ? "var(--Error)"
                            : slide["position"] === "neutral"
                            ? "var(--Stroke)"
                            : "var(--Stroke)" + ")",
                      }}
                    >
                      {language_json[lang][slide["position"]]}
                    </button>
                  </div>

                  <h6>
                    {slide["symbol"]}
                    <span>/{slide["pair"]}</span>
                  </h6>
                  <img className={styled.cryptoIcon} src={slide["logo"]} />
                </div>
              </div>
            </Link>
          )
        );
        setGeneralAnalysis(general_analysis_slide);
      };
      fetchData();
    } else {
      const general_analysis_slide = general_analysis_datas.map(
        (slide, index) => (
          <Link to={`/signalinfo/${slide["signal_id"]}`} target="_blank">
            <div className={styled.text}>
              <div className={styled.time}>
                <h6>{timeCalcu(slide["timestamp"], lang)}</h6>
              </div>
              <div className={styled.iconbox}>
                <div className={styled.black}>
                  <button
                    style={{
                      backgroundColor:
                        slide["position"] === "buy"
                          ? "var(--Success)"
                          : slide["position"] === "sell"
                          ? "var(--Error)"
                          : slide["position"] === "neutral"
                          ? "var(--Stroke)"
                          : "var(--Stroke)" + ")",
                    }}
                  >
                    {language_json[lang][slide["position"]]}
                  </button>
                </div>

                <h6>
                  {slide["symbol"]}
                  <span>/{slide["pair"]}</span>
                </h6>
                <img className={styled.cryptoIcon} src={slide["logo"]} />
              </div>
            </div>
          </Link>
        )
      );
      setGeneralAnalysis(general_analysis_slide);
    }
  };

  useEffect(() => {
    const selectLanguage = localStorage.getItem("select_language");
    const selectTheme = localStorage.getItem("select_theme");
    if (selectLanguage === "en") {
      changeLanguage();
    }
    if (selectTheme === "dark") {
      changeTheme();
    }
    if (selectLanguage !== "en") {
      general_analysisCon(language);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);
  return (
    <div id="signalWraper" className={styled.signalWraper}>
      <div className={styled.main}>
        <div className={styled.navbarTap}>
          <iframe id="tickerTape" src={iframeSrc} title="Ticker Tape" />
        </div>

        <div className={styled.mainbox}>{general_analysis_slides}</div>
      </div>

      <Describing
        changeLanguage={changeLanguage}
        language={language}
        changeTheme={changeTheme}
        theme={theme}
      />
      <Loading active={isLoading} />
    </div>
  );
}
export default General;
