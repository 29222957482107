import styled from "./Loading.module.css";
import React from "react";

export default function Loading({ active }) {
  return (
    <div
      style={{ display: active === true ? "block" : "none" }}
      className={styled.container}
    >
      <iframe
        title="loading"
        src={
          active === true
            ? "https://lottie.host/embed/9e74cbe9-e7b1-4b4b-9e6d-3896720bf953/XKCXeSJlc2.json"
            : ""
        }
      ></iframe>
    </div>
  );
}
