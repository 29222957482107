import { Teacher } from "iconsax-react";
import { Youtube } from "iconsax-react";
import { ChartCircle } from "iconsax-react";
import { Diagram } from "iconsax-react";
import { BitcoinConvert } from "iconsax-react";
import styled from "./Dashboard.module.css";
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";
import Describing from "../../component/menu/Menu";
import base_link from "../../config/config";
import { tutorial } from "../../component/api/tutorial";
import { notification } from "../../component/api/notification";
import { general_analysis } from "../../component/api/general_analysis";
import { chart_patterns } from "../../component/api/chart_patterns";
import moment from "moment";
import menulight from "../../assets/img/menu-light.png";
import menudark from "../../assets/img/menu-dark.png";
import Loading from "../../component/loading/Loading";

var language_json = JSON.parse(localStorage.getItem("language"));
const token = localStorage.getItem("token");

async function get_tutorial() {
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
    "dashboard"
  ) {
    return await tutorial(token);
  } else {
    return null;
  }
}
async function get_notification() {
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
    "dashboard"
  ) {
    return await notification(token);
  } else {
    return null;
  }
}
async function get_general_analysis(limit, full_data, page) {
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
    "dashboard"
  ) {
    return await general_analysis(token, limit, full_data, page);
  } else {
    return null;
  }
}
async function get_chart_patterns(limit, full_data, page) {
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
    "dashboard"
  ) {
    return await chart_patterns(
      token,
      "all",
      "all",
      "all",
      limit,
      full_data,
      page
    );
  } else {
    return null;
  }
}
function timeCalcu(time, lang) {
  const now = moment();
  const targetDate = moment(time);
  const duration = moment.duration(now.diff(targetDate));
  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths());
  const weeks = Math.floor(duration.asWeeks());
  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes());

  let result = [0, ""];
  if (years > 0) {
    result[0] = years;
    result[1] = "years";
  } else if (months > 0) {
    result[0] = months;
    result[1] = "months";
  } else if (weeks > 0) {
    result[0] = weeks;
    result[1] = "weeks";
  } else if (days > 0) {
    result[0] = days;
    result[1] = "days";
  } else if (hours > 0) {
    result[0] = hours;
    result[1] = "hours";
  } else {
    result[0] = minutes;
    result[1] = "minutes";
  }

  return (
    result[0] +
    " " +
    language_json[lang][result[1]] +
    " " +
    language_json[lang]["ago"]
  );
}

function OpenPage(link, target = "blank") {
  window.open(link, `_${target}`);
}
function Dashboard() {
  const [language, setLanguage] = useState("fa");
  const [theme, setTheme] = useState("light");

  const [tutorial_slides, setTutorial] = useState([]);
  const [tutorial_datas, setTutorialData] = useState([]);
  const [notification_slides, setNotification] = useState([]);
  const [notification_datas, setNotificationData] = useState([]);
  const [general_analysis_slides, setGeneralAnalysis] = useState([]);
  const [general_analysis_datas, setGeneralAnalysisData] = useState([]);
  const [chart_patterns_slides, setChartPatterns] = useState([]);
  const [chart_patterns_datas, setChartPatternsData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [firstDirection, setFirstDirection] = useState(false);

  if (!firstDirection) {
    document.documentElement.style.setProperty("--direction", "ltr");
    document.documentElement.style.setProperty("--direction-ref", "rtl");
    setFirstDirection(true);
  }
  const changeLanguage = () => {
    var SidebarControl = document.getElementById("menucontrol");

    const newLanguage = language === "fa" ? "en" : "fa";
    localStorage.setItem("select_language", newLanguage);
    if (newLanguage == "fa") {
      SidebarControl.style.direction = "rtl";
      document.documentElement.style.setProperty("--font", "var(--fa-font)");
      document.documentElement.style.setProperty("--direction", "ltr");
      document.documentElement.style.setProperty("--direction-ref", "rtl");
    } else if (newLanguage == "en") {
      SidebarControl.style.direction = "ltr";
      document.documentElement.style.setProperty("--font", "var(--en-font)");
      document.documentElement.style.setProperty("--direction", "rtl");
      document.documentElement.style.setProperty("--direction-ref", "ltr");
    }
    setLanguage(newLanguage);
    turotailCon(newLanguage);
    notificationCon(newLanguage);
    general_analysisCon(newLanguage);
    chart_patternsCon(newLanguage);
  };
  const changeTheme = () => {
    var moonbutton = document.getElementById("moonbutton");
    var sunbutton = document.getElementById("sunbutton");
    var menuimg = document.getElementById("menuimg");
    var tickerTape = document.getElementById("tickerTape");
    var cryptocurrencyMarket = document.getElementById("cryptocurrencyMarket");

    const newtheme = theme === "light" ? "dark" : "light";

    tickerTape.src = `${base_link}/ticker_tape/${newtheme}`;
    cryptocurrencyMarket.src = `${base_link}/cryptocurrency_market/${newtheme}`;

    localStorage.setItem("select_theme", newtheme);
    if (newtheme === "light") {
      sunbutton.style.display = "inline";
      moonbutton.style.display = "none";

      menuimg.src = menulight;

      document.documentElement.style.setProperty("--Text", "var(--light-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--light-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--light-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--light-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--light-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--light-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--light-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--light-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--light-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--light-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--light-code-img)"
      );
    } else if (newtheme === "dark") {
      moonbutton.style.display = "inline";
      sunbutton.style.display = "none";

      menuimg.src = menudark;

      document.documentElement.style.setProperty("--Text", "var(--dark-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--dark-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--dark-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--dark-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--dark-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--dark-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--dark-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--dark-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--dark-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--dark-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--dark-code-img)"
      );
    }
    setTheme(newtheme);
  };
  const turotailCon = (lang) => {
    if (tutorial_datas.length === 0) {
      const fetchData = async () => {
        const tutorial_data = await get_tutorial();
        const tutorual_json =
          tutorial_data[1] === 200 ? tutorial_data[0]["data"] : [];
        setTutorialData(tutorual_json);
        const tutorial_slide = tutorual_json.map((slide, index) => (
          <SwiperSlide
            style={{
              boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.10)",
              background: "var(--Dialog)",
              borderRadius: "20px",
            }}
            key={index}
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => OpenPage(slide["video_link"])}
            >
              <div className={styled.minbox}>
                <div
                  style={{ backgroundImage: `url(${slide["banner"]})` }}
                  className={styled.Youtube}
                >
                  <div className={styled.YoutubeText}>
                    <h5>{slide[lang]["provider"]}</h5>
                    <Youtube color="var(--Text)" />
                  </div>
                </div>
                <h2>{slide[lang]["title"]}</h2>
                <h3>{slide[lang]["des"]}</h3>
              </div>
            </div>
          </SwiperSlide>
        ));
        setTutorial(tutorial_slide);
      };
      fetchData();
    } else {
      const tutorial_slide = tutorial_datas.map((slide, index) => (
        <SwiperSlide
          style={{
            boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.10)",
            background: "var(--Dialog)",
            borderRadius: "20px",
          }}
          key={index}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => OpenPage(slide["video_link"])}
          >
            <div className={styled.minbox}>
              <div
                style={{ backgroundImage: `url(${slide["banner"]})` }}
                className={styled.Youtube}
              >
                <div className={styled.YoutubeText}>
                  <h5>{slide[lang]["provider"]}</h5>
                  <Youtube color="var(--Text)" />
                </div>
              </div>
              <h2>{slide[lang]["title"]}</h2>
              <h3>{slide[lang]["des"]}</h3>
            </div>
          </div>
        </SwiperSlide>
      ));
      setTutorial(tutorial_slide);
    }
  };
  const notificationCon = (lang) => {
    if (notification_datas.length === 0) {
      const fetchData = async () => {
        const notification_data = await get_notification();
        const notification_json =
          notification_data[1] === 200 ? notification_data[0]["data"] : [];
        setNotificationData(notification_json);
        const notification_slide = notification_json
          .slice(0, 5)
          .map((slide, index) => (
            <React.Fragment key={index}>
              <div
                className={styled.profil}
                style={{ cursor: "pointer" }}
                onClick={() => OpenPage(slide["link"])}
              >
                <div className={styled.app}>
                  <h5>{slide[lang]["title"]}</h5>
                  <h6>{slide[lang]["des"]}</h6>
                </div>
                <img
                  style={{ height: 25, borderRadius: 70 }}
                  src={slide["logo"]}
                  alt=""
                />
                {/* <div className={styled.khali}>
                  <div></div>
                </div> */}
              </div>
              {index !== notification_json.slice(0, 5).length - 1 ? (
                <div className={styled.tohi}></div>
              ) : null}
            </React.Fragment>
          ));
        setNotification(notification_slide);
      };
      fetchData();
    } else {
      const notification_slide = notification_datas
        .slice(0, 4)
        .map((slide, index) => (
          <React.Fragment key={index}>
            <div
              className={styled.profil}
              style={{ cursor: "pointer" }}
              onClick={() => OpenPage(slide["link"])}
            >
              <div className={styled.app}>
                <h5>{slide[lang]["title"]}</h5>
                <h6>{slide[lang]["des"]}</h6>
              </div>
              {/* <div className={styled.khali}>
                <div></div>
              </div> */}
            </div>
            {index !== notification_datas.slice(0, 5).length - 1 ? (
              <div className={styled.tohi}></div>
            ) : null}
          </React.Fragment>
        ));
      setNotification(notification_slide);
    }
  };
  const general_analysisCon = (lang) => {
    if (general_analysis_datas.length === 0) {
      const fetchData = async () => {
        const general_analysis_data = await get_general_analysis(4, "false", 1);
        const general_analysis_json =
          general_analysis_data[1] === 200
            ? general_analysis_data[0]["data"]
            : [];
        setGeneralAnalysisData(general_analysis_json);
        const general_analysis_slide = general_analysis_json
          .slice(0, 4)
          .map((slide, index) => (
            <React.Fragment key={index}>
              <div
                className={styled.profil}
                style={{ cursor: "pointer" }}
                onClick={() => OpenPage(`/signalinfo/${slide["signal_id"]}`)}
              >
                <div className={styled.textProf}>
                  <h5>{timeCalcu(slide["timestamp"], lang)}</h5>
                </div>
                <div className={styled.imgProf}>
                  <h5>
                    {slide["symbol"]}
                    <span>/{slide["pair"]}</span>
                  </h5>
                  <img className={styled.cryptoIcon} src={slide["logo"]} />
                </div>
              </div>
              {index !== general_analysis_json.slice(0, 4).length - 1 ? (
                <div className={styled.tohi}></div>
              ) : null}
            </React.Fragment>
          ));
        setGeneralAnalysis(general_analysis_slide);
      };
      fetchData();
    } else {
      const general_analysis_slide = general_analysis_datas
        .slice(0, 4)
        .map((slide, index) => (
          <React.Fragment key={index}>
            <div
              className={styled.profil}
              style={{ cursor: "pointer" }}
              onClick={() => OpenPage(`/signalinfo/${slide["signal_id"]}`)}
            >
              <div className={styled.textProf}>
                <h5>{timeCalcu(slide["timestamp"], lang)}</h5>
              </div>
              <div className={styled.imgProf}>
                <h5>
                  {slide["symbol"]}
                  <span>/{slide["pair"]}</span>
                </h5>
                <img className={styled.cryptoIcon} src={slide["logo"]} />
              </div>
            </div>
            {index !== general_analysis_datas.slice(0, 4).length - 1 ? (
              <div className={styled.tohi}></div>
            ) : null}
          </React.Fragment>
        ));
      setGeneralAnalysis(general_analysis_slide);
    }
  };
  const chart_patternsCon = (lang) => {
    if (chart_patterns_datas.length === 0) {
      const fetchData = async () => {
        const chart_patterns_data = await get_chart_patterns(4, "false", 1);
        const chart_patterns_json =
          chart_patterns_data[1] === 200 ? chart_patterns_data[0]["data"] : [];
        setChartPatternsData(chart_patterns_json);
        const chart_patterns_slide = chart_patterns_json
          .slice(0, 4)
          .map((slide, index) => (
            <React.Fragment key={index}>
              <div
                className={styled.profil}
                style={{ cursor: "pointer" }}
                onClick={() => OpenPage(`/signalinfo/${slide["signal_id"]}`)}
              >
                <div className={styled.textProf}>
                  <h5>{timeCalcu(slide["timestamp"], lang)}</h5>
                </div>
                <div className={styled.imgProf}>
                  <h5>
                    {slide["symbol"]}
                    <span>/{slide["pair"]}</span>
                  </h5>
                  <img className={styled.cryptoIcon} src={slide["logo"]} />
                </div>
              </div>
              {index !== chart_patterns_json.slice(0, 4).length - 1 ? (
                <div className={styled.tohi}></div>
              ) : null}
            </React.Fragment>
          ));
        setChartPatterns(chart_patterns_slide);
      };
      fetchData();
    } else {
      const chart_patterns_slide = chart_patterns_datas
        .slice(0, 4)
        .map((slide, index) => (
          <React.Fragment key={index}>
            <div
              className={styled.profil}
              style={{ cursor: "pointer" }}
              onClick={() => OpenPage(`/signalinfo/${slide["signal_id"]}`)}
            >
              <div className={styled.textProf}>
                <h5>{timeCalcu(slide["timestamp"], lang)}</h5>
              </div>
              <div className={styled.imgProf}>
                <h5>
                  {slide["symbol"]}
                  <span>/{slide["pair"]}</span>
                </h5>
                <img className={styled.cryptoIcon} src={slide["logo"]} />
              </div>
            </div>
            {index !== chart_patterns_datas.slice(0, 4).length - 1 ? (
              <div className={styled.tohi}></div>
            ) : null}
          </React.Fragment>
        ));
      setChartPatterns(chart_patterns_slide);
    }
  };
  useEffect(() => {
    const selectLanguage = localStorage.getItem("select_language");
    const selectTheme = localStorage.getItem("select_theme");

    if (selectTheme === "dark") {
      changeTheme();
    }
    if (selectLanguage === "en") {
      changeLanguage();
    } else {
      turotailCon(language);
      notificationCon(language);
      general_analysisCon(language);
      chart_patternsCon(language);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  const iframeSrc = `${base_link}/ticker_tape/light`;
  const iframeSrcScreener = `${base_link}/cryptocurrency_market/light`;

  return (
    <div id="signalWraper" className={styled.signalWraper}>
      <div className={styled.main}>
        <div className={styled.navbarTap}>
          <iframe id="tickerTape" src={iframeSrc} title="Ticker Tape" />
        </div>
        <div style={{ height: "94%", overflow: "auto" }}>
          <div className={styled.education}>
            <div className={styled.teacher}>
              <h2>{language_json[language]["tutorial"]}</h2>
              <Teacher color="var(--Text)" />
            </div>

            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              modules={[FreeMode, Pagination]}
              className="mySwiper"
              style={{ padding: "20px" }}
            >
              {tutorial_slides}
            </Swiper>
          </div>
          <div className={styled.navbox}>
            <div className={styled.nemodar}>
              <div className={styled.textnemodar}>
                <h4>{language_json[language]["chart_patterns"]}</h4>
                <Diagram color="var(--Text)" />
              </div>
              <div id="nemodarBox" className={styled.nemodarBox}>
                {chart_patterns_slides}
              </div>
            </div>

            <div className={styled.nemodar}>
              <div className={styled.textnemodar}>
                <h4>{language_json[language]["general_analysis"]}</h4>
                <ChartCircle color="var(--Text)" />
              </div>
              <div id="nemodarBox" className={styled.nemodarBox}>
                {general_analysis_slides}
              </div>
            </div>

            <div className={styled.nemodar}>
              <div className={styled.textnemodar}>
                <h4>{language_json[language]["exchanges"]}</h4>
                <BitcoinConvert color="var(--Text)" />
              </div>
              <div id="nemodarBox" className={styled.nemodarBox}>
                {notification_slides}
              </div>
            </div>
          </div>
          <div className={styled.CryptoScreener}>
            <iframe
              id="cryptocurrencyMarket"
              src={iframeSrcScreener}
              title="Cryptocurrency Market"
            />
          </div>
        </div>
      </div>
      <Describing
        changeLanguage={changeLanguage}
        language={language}
        changeTheme={changeTheme}
        theme={theme}
      />
      <Loading active={isLoading} />
    </div>
  );
}
export default Dashboard;
