import axios from 'axios';
import base_url from "../../config/config";

const fetchDataFromLink = async (link,headers) => {
  try {
    const response = await axios.get(link,{headers});
    const result = response.data;
    return [result, response.status];
  } catch (error) {
    return [error['response']['data']['error'], error['response']['status']];
  }
};

export const general_analysis = async (token,limit,full_data,page) => {
  const headers ={'X-TOKEN': token,'Content-Type':'multipart/form-data; charset=utf-8'};
  const data = await fetchDataFromLink(`${base_url}/general_analysis?limit=${limit}&full_data=${full_data}&page=${page}`,headers);
  return data;
};
export default general_analysis;