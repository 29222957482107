import React from "react";
import axios from "axios";
import base_url from "../../config/config";

const fetchDataFromLink = async (link) => {
  try {
    const response = await axios.get(link);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const language_data = async () => {
  const data = await fetchDataFromLink(`${base_url}/language`);
  var language = localStorage.getItem("language");
  var select_theme = localStorage.getItem("select_theme");
  var select_language = localStorage.getItem("select_language");
  if (language === null) {
    localStorage.setItem("language", JSON.stringify(data));
  } else {
    localStorage.setItem("language", JSON.stringify(data));
  }
  if (select_theme === null) {
    localStorage.setItem("select_theme", "light");
  }
  if (select_language === null) {
    localStorage.setItem("select_language", "fa");
  }
  if (language === null || select_theme === null || select_language === null) {
    window.location.reload();
  }
  return data;
};

export default language_data;
