import { Flag2 } from "iconsax-react";
import { Link } from "react-router-dom";
import styled2 from "./patterns.module.css";
import React, { useState, useEffect } from "react";
import { Eye, Verify } from "iconsax-react";
import Describing from "../../component/menu/Menu";
import moment from "moment";
import base_link from "../../config/config";
import menulight from "../../assets/img/menu-light.png";
import menudark from "../../assets/img/menu-dark.png";
import { chart_patterns } from "../../component/api/chart_patterns";
import { strategy } from "../../component/api/strategy";
import Loading from "../../component/loading/Loading";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
import Chip from "@mui/material/Chip";

var language_json = JSON.parse(localStorage.getItem("language"));
const token = localStorage.getItem("token");

function timeCalcu(time, lang) {
  const now = moment();
  const targetDate = moment(time);
  const duration = moment.duration(now.diff(targetDate));
  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths());
  const weeks = Math.floor(duration.asWeeks());
  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes());

  let result = [0, ""];
  if (years > 0) {
    result[0] = years;
    result[1] = "years";
  } else if (months > 0) {
    result[0] = months;
    result[1] = "months";
  } else if (weeks > 0) {
    result[0] = weeks;
    result[1] = "weeks";
  } else if (days > 0) {
    result[0] = days;
    result[1] = "days";
  } else if (hours > 0) {
    result[0] = hours;
    result[1] = "hours";
  } else if (minutes > 0) {
    result[0] = minutes;
    result[1] = "minutes";
  } else {
    result[0] = 0;
    result[1] = "now";
  }

  return result[1] !== "now"
    ? result[0] +
        " " +
        language_json[lang][result[1]] +
        " " +
        language_json[lang]["ago"]
    : language_json[lang][result[1]];
}
function OpenPage(link, target = "blank") {
  window.open(link, `_${target}`);
}
async function get_chart_patterns(
  timeframe_fil,
  strategy_fil,
  position_fil,
  limit,
  full_data,
  page
) {
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
    "patterns"
  ) {
    return await chart_patterns(
      token,
      timeframe_fil,
      strategy_fil,
      position_fil,
      limit,
      full_data,
      page
    );
  } else {
    return null;
  }
}
async function get_strategy_data() {
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
    "patterns"
  ) {
    return await strategy(token);
  } else {
    return null;
  }
}
const InputLabelElementStyle = styled(InputLabel)(({ theme }) => ({
  direction: "rtl",
  fontFamily: "var(--font)",
  color: "var(--Text)",
}));
const SelectElementStyles = styled(Select)(({ theme }) => ({
  direction: "rtl",
  fontFamily: "var(--font)",
  color: "var(--Text)",
}));
const MenuItemElementStyles = styled(MenuItem)(({ theme }) => ({
  direction: "rtl",
  fontFamily: "var(--font)",
}));

const ChipElementStyles = styled(Chip)(({ theme, botORexpert, free }) => ({
  direction: "ltr",
  fontFamily: "var(--font)",
  color: "var(--Primary)",
  backgroundColor:
    free === "true"
      ? `rgba(82, 255, 0, 1)`
      : botORexpert === "bot"
      ? "rgba(255, 199, 0, 1)"
      : "rgba(0, 163, 255, 1)",
  boxShadow: `0px 0px 15px 0px ${
    free === "true"
      ? `rgba(82, 255, 0, 0.35)`
      : botORexpert === "bot"
      ? "rgba(255, 199, 0, 0.35)"
      : "rgba(0, 163, 255, 0.35)"
  }`,
}));

function Patterns() {
  const [language, setLanguage] = useState("fa");
  const [theme, setTheme] = useState("light");

  const [chart_patterns_slides, setChartPatterns] = useState([]);
  const [chart_patterns_datas, setChartPatternsData] = useState([]);

  const [strategy_datas_slides, setStrategies] = useState([]);
  const [strategy_datas, setStrategyData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [timeframe_filter, setTimeframeFilter] = useState("all");
  const [strategy_filter, setStrategyFilter] = useState("all");
  const [position_filter, setPositionFilter] = useState("all");

  const [isImgLoading, setIsImgLoading] = useState(false);
  const [firstDirection, setFirstDirection] = useState(false);

  if (!firstDirection) {
    document.documentElement.style.setProperty("--direction", "ltr");
    document.documentElement.style.setProperty("--direction-ref", "rtl");
    setFirstDirection(true);
  }

  const handleImageLoad = () => {
    console.log("loaded");
    setIsImgLoading(false);
  };

  const changeLanguage = (check = true) => {
    const newLanguage = language === "fa" ? "en" : "fa";

    var SidebarControl = document.getElementById("menucontrol");

    localStorage.setItem("select_language", newLanguage);
    if (newLanguage == "fa") {
      SidebarControl.style.direction = "rtl";
      document.documentElement.style.setProperty("--font", "var(--fa-font)");
      document.documentElement.style.setProperty("--direction", "ltr");
      document.documentElement.style.setProperty("--direction-ref", "rtl");
    } else if (newLanguage == "en") {
      SidebarControl.style.direction = "ltr";
      document.documentElement.style.setProperty("--font", "var(--en-font)");
      document.documentElement.style.setProperty("--direction", "rtl");
      document.documentElement.style.setProperty("--direction-ref", "ltr");
    }
    setLanguage(newLanguage);
    if (check) {
      chart_patternsCon(
        timeframe_filter,
        strategy_filter,
        position_filter,
        newLanguage,
        theme
      );
      strategyCon(newLanguage);
    }
  };
  const changeTheme = (check = true) => {
    var moonbutton = document.getElementById("moonbutton");
    var sunbutton = document.getElementById("sunbutton");
    var menuimg = document.getElementById("menuimg");
    var tickerTape = document.getElementById("tickerTape");

    const newtheme = theme === "light" ? "dark" : "light";

    tickerTape.src = `${base_link}/ticker_tape/${newtheme}`;

    localStorage.setItem("select_theme", newtheme);
    if (newtheme === "light") {
      sunbutton.style.display = "inline";
      moonbutton.style.display = "none";

      menuimg.src = menulight;

      document.documentElement.style.setProperty("--Text", "var(--light-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--light-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--light-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--light-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--light-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--light-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--light-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--light-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--light-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--light-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--light-code-img)"
      );
    } else if (newtheme === "dark") {
      moonbutton.style.display = "inline";
      sunbutton.style.display = "none";

      menuimg.src = menudark;

      document.documentElement.style.setProperty("--Text", "var(--dark-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--dark-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--dark-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--dark-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--dark-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--dark-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--dark-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--dark-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--dark-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--dark-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--dark-code-img)"
      );
    }
    setTheme(newtheme);
    if (check) {
      chart_patternsCon(
        timeframe_filter,
        strategy_filter,
        position_filter,
        language,
        newtheme
      );
      strategyCon(language);
    }
  };
  const strategyCon = (lang) => {
    console.log(strategy_datas);
    if (strategy_datas.length == 0) {
      const fetchData = async () => {
        const strategy_data = await get_strategy_data();
        const strategy_json =
          strategy_data[1] === 200 ? strategy_data[0]["data"] : [];
        setStrategyData(strategy_json);
        const strategies_slide = strategy_json.map((slide, index) => (
          <MenuItemElementStyles value={slide["key"]}>
            {language_json[lang][slide["key"]]}
          </MenuItemElementStyles>
        ));
        setStrategies(strategies_slide);
      };
      fetchData();
    } else {
      const strategy_data = strategy_datas;
      const strategy_json = strategy_data;
      setStrategyData(strategy_json);
      const strategies_slide = strategy_json.map((slide, index) => (
        <MenuItemElementStyles value={slide["key"]}>
          {language_json[lang][slide["key"]]}
        </MenuItemElementStyles>
      ));
      setStrategies(strategies_slide);
    }
  };
  const handleTimeFrameChange = (event) => {
    setTimeframeFilter(event.target.value);
    setChartPatterns([]);
    setChartPatternsData([]);
    chart_patternsCon(
      event.target.value,
      strategy_filter,
      position_filter,
      language,
      theme
    );
  };
  const handleStrategyChange = (event) => {
    setStrategyFilter(event.target.value);
    setChartPatterns([]);
    setChartPatternsData([]);
    chart_patternsCon(
      timeframe_filter,
      event.target.value,
      position_filter,
      language,
      theme
    );
  };
  const handlePositionChange = (event) => {
    setPositionFilter(event.target.value);
    setChartPatterns([]);
    setChartPatternsData([]);
    chart_patternsCon(
      timeframe_filter,
      strategy_filter,
      event.target.value,
      language,
      theme
    );
  };
  const chart_patternsCon = (
    tf_f = "all",
    st_f = "all",
    pt_f = "all",
    lang,
    tem
  ) => {
    // if (chart_patterns_datas.length === 0) {
    const empty_items = Array(8).fill(null);
    const chart_patterns_slide_empty = (
      <>
        {empty_items.map((item, index) => (
          <div className={styled2.box}>
            <Skeleton
              style={{ borderTopLeftRadius: 25, borderTopRightRadius: 25 }}
              variant="rectangular"
              width={300}
              height={168}
            />
            <div className={styled2.textbox}>
              <div className={styled2.text}>
                <div className={styled2.time}>
                  <Skeleton
                    width={60}
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                  />
                </div>
                <div className={styled2.iconbox}>
                  <Skeleton
                    width={90}
                    variant="text"
                    sx={{ fontSize: "1.2rem" }}
                  />
                  <div style={{ width: 10 }}></div>
                  <Skeleton variant="circular" width={30} height={30} />
                </div>
              </div>
              <div className={styled2.text}>
                <div>
                  <Skeleton
                    width={60}
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                  />
                </div>
                <div className={styled2.Flag}>
                  <Skeleton
                    width={60}
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                  />
                </div>
              </div>
              <div className={styled2.text}>
                <div>
                  <Skeleton
                    width={60}
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                  />
                </div>
                <div>
                  <Skeleton
                    width={60}
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                  />{" "}
                </div>
              </div>
            </div>
            <div to="" target="_blank">
              <button className={styled2.button}>
                <Skeleton width={60} variant="text" sx={{ fontSize: "1rem" }} />
              </button>
            </div>
          </div>
        ))}
      </>
    );
    setChartPatterns(chart_patterns_slide_empty);

    const fetchData = async () => {
      const chart_patterns_data = await get_chart_patterns(
        tf_f,
        st_f,
        pt_f,
        100,
        "true",
        1
      );
      const chart_patterns_json =
        chart_patterns_data[1] === 200 ? chart_patterns_data[0]["data"] : [];
      if (chart_patterns_json.length !== 0) {
        setChartPatternsData(chart_patterns_json);
        const chart_patterns_slide = chart_patterns_json.map((slide, index) => (
          <div className={styled2.box}>
            <div className={styled2.divOptions}>
              {slide["bot_analysis"] === "true" ? (
                <ChipElementStyles
                  botORexpert="bot"
                  free="false"
                  icon={<Verify />}
                  label={language_json[lang]["bot"]}
                />
              ) : null}
              {slide["experts_analysis"] === "true" ? (
                <ChipElementStyles
                  botORexpert="expert"
                  free="false"
                  icon={<Verify />}
                  label={language_json[lang]["experts"]}
                />
              ) : null}
              {slide["free_signal"] === "true" ? (
                <ChipElementStyles
                  botORexpert="expert"
                  free="true"
                  icon={<Verify />}
                  label={language_json[lang]["free"]}
                />
              ) : null}
            </div>
            <Skeleton
              style={{
                borderTopLeftRadius: 25,
                borderTopRightRadius: 25,
                display: isImgLoading ? "block" : "none",
              }}
              variant="rectangular"
              width={300}
              height={168}
            />

            <img
              alt="signal"
              className={styled2.img}
              onLoad={handleImageLoad}
              style={{ display: isImgLoading ? "none" : "block" }}
              src={slide[lang][tem]["screen"]}
            />
            <div className={styled2.textbox}>
              <div className={styled2.text}>
                <div className={styled2.time}>
                  <h6>{timeCalcu(slide["timestamp"], lang)}</h6>
                </div>
                <div className={styled2.iconbox}>
                  <button
                    className={
                      slide["position"] === "buy"
                        ? styled2.buybutton
                        : slide["position"] === "sell"
                        ? styled2.sellbutton
                        : styled2.buybutton
                    }
                  >
                    {language_json[lang][slide["position"]]}
                  </button>

                  <h6
                    style={{
                      display: "flex",
                      direction: "ltr",
                    }}
                  >
                    {slide["symbol"]}
                    <span>/{slide["pair"]}</span>
                  </h6>
                  <img className={styled2.cryptoIcon} src={slide["logo"]} />
                </div>
              </div>
              <div className={styled2.text}>
                <div>
                  <h6>
                    {language_json[lang]["strategy"]}/{" "}
                    {language_json[lang]["pattern"]} :
                  </h6>
                </div>
                <div className={styled2.Flag}>
                  <h6>
                    {" "}
                    {language_json[lang][slide["strategy"]]}
                    <Flag2 size="15" />
                  </h6>
                </div>
              </div>
              <div className={styled2.text}>
                <div>
                  <h6>{language_json[lang]["timeframe"]} :</h6>
                </div>
                <div>
                  <h6>{language_json[lang][slide["timeframe"]]}</h6>
                </div>
              </div>
            </div>
            <Link to={`/signalinfo/${slide["signal_id"]}`} target="_blank">
              <button className={styled2.button}>
                {language_json[lang]["view"]}{" "}
                <Eye className={styled2.Eye} size="18" />
              </button>
            </Link>
          </div>
        ));
        setChartPatterns(chart_patterns_slide);
      } else {
        const empty_list = (
          <div>
            <h3>{language_json[lang]["not_found"]}</h3>
          </div>
        );
        setChartPatterns(empty_list);
      }
    };
    fetchData();
    // } else {
    //   const chart_patterns_slide = chart_patterns_datas.map((slide, index) => (
    //     <div className={styled2.box}>
    //       <img className={styled2.img} src={slide[lang][tem]["screen"]} />
    //       <div className={styled2.textbox}>
    //         <div className={styled2.text}>
    //           <div className={styled2.time}>
    //             <h6>{timeCalcu(slide["timestamp"], lang)}</h6>
    //           </div>
    //           <div className={styled2.iconbox}>
    //             <button
    //               className={
    //                 slide["position"] === "buy"
    //                   ? styled2.buybutton
    //                   : slide["position"] === "sell"
    //                   ? styled2.sellbutton
    //                   : styled2.buybutton
    //               }
    //             >
    //               {language_json[lang][slide["position"]]}
    //             </button>

    //             <h6>
    //               {slide["symbol"]}
    //               <span>/{slide["pair"]}</span>
    //             </h6>
    //             <img className={styled2.cryptoIcon} src={slide["logo"]} />
    //           </div>
    //         </div>
    //         <div className={styled2.text}>
    //           <div>
    //             <h6>
    //               {language_json[lang]["strategy"]}/{" "}
    //               {language_json[lang]["pattern"]} :
    //             </h6>
    //           </div>
    //           <div className={styled2.Flag}>
    //             <h6>
    //               {" "}
    //               {language_json[lang][slide["strategy"]]}
    //               <Flag2 size="15" />
    //             </h6>
    //           </div>
    //         </div>
    //         <div className={styled2.text}>
    //           <div>
    //             <h6>{language_json[lang]["timeframe"]} :</h6>
    //           </div>
    //           <div>
    //             <h6>{language_json[lang][slide["timeframe"]]}</h6>
    //           </div>
    //         </div>
    //       </div>
    //       <Link to={`/signalinfo/${slide["signal_id"]}`} target="_blank">
    //         <button className={styled2.button}>
    //           {language_json[lang]["view"]}{" "}
    //           <Eye className={styled2.Eye} size="18" />
    //         </button>
    //       </Link>
    //     </div>
    //   ));
    //   setChartPatterns(chart_patterns_slide);
    // }
  };

  useEffect(() => {
    const selectLanguage = localStorage.getItem("select_language");
    const selectTheme = localStorage.getItem("select_theme");
    if (selectLanguage === "en" && selectTheme === "dark") {
      chart_patternsCon("all", "all", "all", "en", "dark");
      strategyCon("en");
      changeLanguage(false);
      changeTheme(false);
    } else if (selectLanguage === "en") {
      changeLanguage();
    } else if (selectTheme === "dark") {
      changeTheme();
    } else if (selectLanguage !== "en" && selectTheme !== "dark") {
      chart_patternsCon("all", "all", "all", language, theme);
      strategyCon(language);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  const iframeSrc = `${base_link}/ticker_tape/light`;
  return (
    <div id="signalWraper" className={styled2.signalWraper}>
      <div className={styled2.main}>
        <div className={styled2.navbarTap}>
          <iframe id="tickerTape" src={iframeSrc} title="Ticker Tape" />
        </div>
        <div className={styled2.filterRoot}>
          <FormControl sx={{ m: 1, minWidth: 130 }}>
            <InputLabelElementStyle id="timeframe-select-label">
              {language_json[language]["timeframe"]}
            </InputLabelElementStyle>
            <SelectElementStyles
              labelId="timeframe-select-label"
              id="timeframe-select"
              value={timeframe_filter}
              label="Timeframe"
              onChange={handleTimeFrameChange}
            >
              <MenuItemElementStyles value={"all"}>
                {language_json[language]["all"]}
              </MenuItemElementStyles>
              <MenuItemElementStyles value={"15m"}>
                {language_json[language]["15m"]}
              </MenuItemElementStyles>
              <MenuItemElementStyles value={"1h"}>
                {language_json[language]["1h"]}
              </MenuItemElementStyles>
              <MenuItemElementStyles value={"4h"}>
                {language_json[language]["4h"]}
              </MenuItemElementStyles>
              <MenuItemElementStyles value={"1d"}>
                {language_json[language]["1d"]}
              </MenuItemElementStyles>
            </SelectElementStyles>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 130 }}>
            <InputLabelElementStyle id="strategy-select-label">
              {language_json[language]["strategy"]}
            </InputLabelElementStyle>
            <SelectElementStyles
              labelId="strategy-select-label"
              id="strategy-select"
              value={strategy_filter}
              label="Strategy"
              onChange={handleStrategyChange}
            >
              <MenuItemElementStyles value={"all"}>
                {language_json[language]["all"]}
              </MenuItemElementStyles>
              {strategy_datas_slides}
            </SelectElementStyles>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 130 }}>
            <InputLabelElementStyle id="position-select-label">
              {language_json[language]["position"]}
            </InputLabelElementStyle>
            <SelectElementStyles
              labelId="position-select-label"
              id="position-select"
              value={position_filter}
              label="Position"
              onChange={handlePositionChange}
            >
              <MenuItemElementStyles value={"all"}>
                {language_json[language]["all"]}
              </MenuItemElementStyles>
              <MenuItemElementStyles value={"buy"}>
                {language_json[language]["buy"]}
              </MenuItemElementStyles>
              <MenuItemElementStyles value={"sell"}>
                {language_json[language]["sell"]}
              </MenuItemElementStyles>
            </SelectElementStyles>
          </FormControl>
        </div>
        <div className={styled2.boxbar}>{chart_patterns_slides}</div>
      </div>
      <Describing
        changeLanguage={changeLanguage}
        language={language}
        changeTheme={changeTheme}
        theme={theme}
      />
      <Loading active={isLoading} />
    </div>
  );
}
export default Patterns;
