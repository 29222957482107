import axios from 'axios';
import base_url from "../../config/config";

const fetchDataFromLink = async (link, data) => {
  try {
    const response = await axios.post(link, data);
    const result = response.data;
    return [result, response.status];
  } catch (error) {
    return [error['response']['data']['error'], error['response']['status']];
  }
};

export const code_api = async (email,code) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('code', code);
  const data = await fetchDataFromLink(`${base_url}/check_code`, formData);
  return data;
};

export default code_api;