import React, { useState, useEffect } from "react";
import Describing from "../../component/menu/Menu";
import Loading from "../../component/loading/Loading";
import menulight from "../../assets/img/menu-light.png";
import menudark from "../../assets/img/menu-dark.png";
import base_link from "../../config/config";

import styled from "./Withdraw.module.css";
import Withdrawimg from "../../assets/img/Swiperimg.jpg";
import "swiper/css";
import { Link } from "react-router-dom";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { ArrowSquareLeft } from "iconsax-react";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { user_info } from "../../component/api/user_info";
import { withdraw_api } from "../../component/api/withdraw";

var language_json = JSON.parse(localStorage.getItem("language"));
const token = localStorage.getItem("token");

async function userinfo() {
  if (
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "dashboard" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "patterns" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "general" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "academy" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "account" ||
    window.location.pathname.toLowerCase().replace("/", "").replace("/", "") ===
      "withdraw" ||
    window.location.pathname
      .toLowerCase()
      .replace("/", "")
      .replace("/", "")
      .slice(0, 10) === "signalinfo"
  ) {
    const user_info_data = await user_info(token);
    if (user_info_data[1] !== 200) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return user_info_data;
  } else {
    return null;
  }
}
const user_info_data = await userinfo();

function Withdraw() {
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState("fa");
  const [theme, setTheme] = useState("light");

  const [textFieldAddress, setTextFieldAddress] = useState("");
  const [textFieldAmount, setTextFieldAmount] = useState("");

  const handleTextFieldAddressChange = (event) => {
    setTextFieldAddress(event.target.value);
  };
  const handleTextFieldAmountChange = (event) => {
    setTextFieldAmount(event.target.value);
  };
  function convertToInt(input) {
    const number = parseInt(input, 10);

    if (isNaN(number)) {
      return null;
    } else {
      return number;
    }
  }

  const handlePrintValue = async () => {
    if ((textFieldAddress != "") & (textFieldAmount != "")) {
      if (convertToInt(textFieldAmount) != null) {
        if (
          convertToInt(textFieldAmount) <=
          convertToInt(user_info_data[0]["data"]["balance"])
        ) {
          const withdraw_data = await withdraw_api(
            token,
            textFieldAddress,
            textFieldAmount
          );
          if (withdraw_data[1] == 403) {
            toast.error(
              language_json[language]["err_cannot_withdraw_under_24_hours"],
              {
                position:
                  language === "fa"
                    ? "bottom-right"
                    : language === "en"
                    ? "bottom-left"
                    : "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                rtl:
                  language === "fa" ? true : language === "en" ? false : true,
                draggable: true,
                progress: undefined,
                theme: theme,
              }
            );
          } else if (withdraw_data[1] !== 200) {
            toast.error(language_json[language]["problem_with_withdraw"], {
              position:
                language === "fa"
                  ? "bottom-right"
                  : language === "en"
                  ? "bottom-left"
                  : "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              rtl: language === "fa" ? true : language === "en" ? false : true,
              draggable: true,
              progress: undefined,
              theme: theme,
            });
          } else {
            toast.success(language_json[language]["withdraw_successful"], {
              position:
                language === "fa"
                  ? "bottom-right"
                  : language === "en"
                  ? "bottom-left"
                  : "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              rtl: language === "fa" ? true : language === "en" ? false : true,
              draggable: true,
              progress: undefined,
              theme: theme,
            });
            setTimeout(() => {
              window.location.href = "/withdraw_history";
            }, 2000);
          }
        } else {
          toast.error(language_json[language]["balance_is_not_enough"], {
            position:
              language === "fa"
                ? "bottom-right"
                : language === "en"
                ? "bottom-left"
                : "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            rtl: language === "fa" ? true : language === "en" ? false : true,
            draggable: true,
            progress: undefined,
            theme: theme,
          });
        }
      } else {
        toast.error(language_json[language]["format_number_wrong"], {
          position:
            language === "fa"
              ? "bottom-right"
              : language === "en"
              ? "bottom-left"
              : "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: language === "fa" ? true : language === "en" ? false : true,
          draggable: true,
          progress: undefined,
          theme: theme,
        });
      }
    } else {
      toast.error(language_json[language]["should_not_be_empty"], {
        position:
          language === "fa"
            ? "bottom-right"
            : language === "en"
            ? "bottom-left"
            : "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: language === "fa" ? true : language === "en" ? false : true,
        draggable: true,
        progress: undefined,
        theme: theme,
      });
    }
  };

  const changeLanguage = () => {
    const newLanguage = language === "fa" ? "en" : "fa";

    var SidebarControl = document.getElementById("menucontrol");

    localStorage.setItem("select_language", newLanguage);
    if (newLanguage == "fa") {
      SidebarControl.style.direction = "rtl";
      document.documentElement.style.setProperty("--font", "var(--fa-font)");
      document.documentElement.style.setProperty("--direction", "ltr");
      document.documentElement.style.setProperty("--direction-ref", "rtl");
    } else if (newLanguage == "en") {
      SidebarControl.style.direction = "ltr";
      document.documentElement.style.setProperty("--font", "var(--en-font)");
      document.documentElement.style.setProperty("--direction", "rtl");
      document.documentElement.style.setProperty("--direction-ref", "ltr");
    }
    setLanguage(newLanguage);
    // general_analysisCon(newLanguage);
  };
  const changeTheme = () => {
    var moonbutton = document.getElementById("moonbutton");
    var sunbutton = document.getElementById("sunbutton");
    var menuimg = document.getElementById("menuimg");
    var tickerTape = document.getElementById("tickerTape");

    const newtheme = theme === "light" ? "dark" : "light";

    tickerTape.src = `${base_link}/ticker_tape/${newtheme}`;

    localStorage.setItem("select_theme", newtheme);
    if (newtheme === "light") {
      sunbutton.style.display = "inline";
      moonbutton.style.display = "none";

      menuimg.src = menulight;

      document.documentElement.style.setProperty("--Text", "var(--light-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--light-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--light-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--light-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--light-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--light-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--light-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--light-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--light-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--light-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--light-code-img)"
      );
    } else if (newtheme === "dark") {
      moonbutton.style.display = "inline";
      sunbutton.style.display = "none";

      menuimg.src = menudark;

      document.documentElement.style.setProperty("--Text", "var(--dark-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--dark-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--dark-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--dark-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--dark-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--dark-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--dark-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--dark-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--dark-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--dark-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--dark-code-img)"
      );
    }
    setTheme(newtheme);
  };
  useEffect(() => {
    const selectLanguage = localStorage.getItem("select_language");
    const selectTheme = localStorage.getItem("select_theme");
    if (selectLanguage === "en") {
      changeLanguage();
    }
    if (selectTheme === "dark") {
      changeTheme();
    }
    if (selectLanguage !== "en") {
      //   general_analysisCon(language);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  const iframeSrc = `${base_link}/ticker_tape/light`;

  return (
    <div id="WithdrawWraper" className={styled.WithdrawWraper}>
      <div className={styled.main}>
        <div className={styled.navbarTap}>
          <iframe id="tickerTape" src={iframeSrc} title="Ticker Tape" />
        </div>
        <div className={styled.WithdrawForm}>
          <h2>{language_json[language]["withdraw_title"]}</h2>
          <TextField
            className={styled.WithdrawWalletAddress}
            InputProps={{
              style: {
                color: "var(--Subtext)",
              },
            }}
            InputLabelProps={{
              style: {
                color: "var(--Subtext)",
              },
            }}
            value={textFieldAddress}
            onChange={handleTextFieldAddressChange}
            id="outlined-basic"
            label={language_json[language]["wallet_address"]}
            variant="outlined"
          />
          <TextField
            className={styled.WithdrawWalletAddress}
            InputProps={{
              style: {
                color: "var(--Subtext)",
              },
            }}
            InputLabelProps={{
              style: {
                color: "var(--Subtext)",
              },
            }}
            value={textFieldAmount}
            onChange={handleTextFieldAmountChange}
            id="outlined-basic"
            label={language_json[language]["withdraw_amount"] + " USDT"}
            variant="outlined"
          />
          <h5>
            {language_json[language]["balance"]}:{" "}
            {user_info_data[0]["data"]["balance"].toLocaleString()} USDT
          </h5>
          <button className={styled.WithdrawButton} onClick={handlePrintValue}>
            {language_json[language]["withdraw"]}
          </button>
          <ToastContainer />
        </div>
      </div>
      <Describing
        changeLanguage={changeLanguage}
        language={language}
        changeTheme={changeTheme}
        theme={theme}
      />
      <Loading active={isLoading} />
    </div>
  );
}
export default Withdraw;
