import { Route, Routes } from "react-router";
import React from "react";

import "./App.css";
import Login from "./pages/login/Login";
import Completion from "./pages/completion/Completion";
import CodeLogin from "./pages/code/CodeLogin";
import Dashboard from "./pages/dashboard/Dashboard";
import Patterns from "./pages/patterns/Patterns";
import General from "./pages/general/General";
import Signalinfo from "./pages/signalinfo/Signalinfo";
import Academy from "./pages/academy/Academy";
import NotFound from "./pages/notFound/notFound";
import Courseinfo from "./pages/courseinfo/Courseinfo";
import Account from "./pages/account/Account";
import Landing from "./pages/landing/Landing";
import Withdraw from "./pages/withdraw/Withdraw";
import WithdrawHistory from "./pages/withdrawHistory/withdrawHistory";
import SubmitSignal from "./pages/submitSignal/submitSignal";
import PlansInfo from "./pages/plans/plans";

function App() {
  return (
    <div>
      
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route
          path="/Login"
          element={<Login />}
          children={<Route path="/Login/Referral/:referral_id" />}
        />
        <Route path="/completion" element={<Completion />} />
        <Route path="/courseinfo" element={<Courseinfo />} />
        <Route path="/code" element={<CodeLogin />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/patterns" element={<Patterns />} />
        <Route path="/general" element={<General />} />
        <Route
          path="/signalinfo"
          element={<Signalinfo />}
          children={<Route path="/signalinfo/:id" />}
        />
        <Route path="/academy" element={<Academy />} />
        <Route path="/account" element={<Account />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/plans" element={<PlansInfo />} />
        <Route path="/withdraw_history" element={<WithdrawHistory />} />
        <Route path="/submit_signal" element={<SubmitSignal />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
