import styled from "./Academy.module.css";
import React, { useState, useEffect } from "react";
import Describing from "../../component/menu/Menu";
import Loading from "../../component/loading/Loading";
import menulight from "../../assets/img/menu-light.png";
import menudark from "../../assets/img/menu-dark.png";
import base_link from "../../config/config";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "react-router-dom";
import Swiperimg from "../../assets/img/Swiperimg.jpg";
import freeimg from "../../assets/img/freeimg.png";
import monyimg from "../../assets/img/monyimg.png";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Mousewheel, Pagination } from "swiper/modules";
import { Clock, Eye, Tag, UserAdd } from "iconsax-react";
import axios from "axios";

var language_json = JSON.parse(localStorage.getItem("language"));

function Academy() {
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState("fa");
  const [theme, setTheme] = useState("light");
  const changeLanguage = () => {
    const newLanguage = language === "fa" ? "en" : "fa";

    var SidebarControl = document.getElementById("menucontrol");

    localStorage.setItem("select_language", newLanguage);
    if (newLanguage == "fa") {
      SidebarControl.style.direction = "rtl";
      document.documentElement.style.setProperty("--font", "var(--fa-font)");
      document.documentElement.style.setProperty("--direction", "ltr");
      document.documentElement.style.setProperty("--direction-ref", "rtl");
      const elements = document.getElementsByClassName("SwiperSlideporf");
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.flexDirection = "row";
      }
    } else if (newLanguage == "en") {
      SidebarControl.style.direction = "ltr";
      document.documentElement.style.setProperty("--font", "var(--en-font)");
      document.documentElement.style.setProperty("--direction", "rtl");
      document.documentElement.style.setProperty("--direction-ref", "ltr");
      const elements = document.getElementsByClassName("SwiperSlideporf");
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.flexDirection = "row-reverse";
      }
    }
    setLanguage(newLanguage);
    // general_analysisCon(newLanguage);
  };
  const changeTheme = () => {
    var moonbutton = document.getElementById("moonbutton");
    var sunbutton = document.getElementById("sunbutton");
    var menuimg = document.getElementById("menuimg");
    var tickerTape = document.getElementById("tickerTape");

    const newtheme = theme === "light" ? "dark" : "light";

    tickerTape.src = `${base_link}/ticker_tape/${newtheme}`;

    localStorage.setItem("select_theme", newtheme);
    if (newtheme === "light") {
      sunbutton.style.display = "inline";
      moonbutton.style.display = "none";

      menuimg.src = menulight;

      document.documentElement.style.setProperty("--Text", "var(--light-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--light-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--light-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--light-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--light-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--light-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--light-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--light-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--light-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--light-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--light-code-img)"
      );
    } else if (newtheme === "dark") {
      moonbutton.style.display = "inline";
      sunbutton.style.display = "none";

      menuimg.src = menudark;

      document.documentElement.style.setProperty("--Text", "var(--dark-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--dark-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--dark-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--dark-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--dark-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--dark-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--dark-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--dark-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--dark-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--dark-Gradient${language === "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--dark-code-img)"
      );
    }
    setTheme(newtheme);
  };
  useEffect(() => {
    const selectLanguage = localStorage.getItem("select_language");
    const selectTheme = localStorage.getItem("select_theme");
    if (selectLanguage === "en") {
      changeLanguage();
    }
    if (selectTheme === "dark") {
      changeTheme();
    }
    if (selectLanguage !== "en") {
      //   general_analysisCon(language);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);
  const iframeSrc = `${base_link}/ticker_tape/light`;
  return (
    <div id="signalWraper" className={styled.signalWraper}>
      <div className={styled.main}>
        <div className={styled.navbarTap}>
          <iframe id="tickerTape" src={iframeSrc} title="Ticker Tape" />
        </div>
        <h2 style={{ textAlign: "center" }}>
          {language_json[language]["coming_soon"]}
        </h2>
        <div className={styled.blurer}>
          <div className={styled.boxbarTap}>
            <h2>{language_json[language]["popular_courses"]}</h2>
            <Swiper
              slidesPerView={4}
              spaceBetween={15}
              modules={[FreeMode, Pagination]}
            >
              <SwiperSlide>
                <div className={styled.freebox}>
                  <div className={styled.SwiperSlideimg}></div>
                  <div className={styled.SwiperSlidetext}>
                    <div
                      id="SwiperSlideporf"
                      className={styled.SwiperSlideporf}
                    >
                      <div className={styled.SwiperSlideporfr}>
                        <h2>Mojipipel</h2>
                        <h3>@Mojipipel</h3>
                      </div>
                      <div className={styled.SwiperSlideporfl}>
                        <img src={Swiperimg} />
                      </div>
                    </div>

                    <div className={styled.SwiperSlideprice}>
                      <div className={styled.SwiperSlidepricer}>
                        <h4>{language_json[language]["price"]}</h4>
                      </div>
                      <div className={styled.SwiperSlidepricel}>
                        <img src={freeimg} />
                        <h2>{language_json[language]["free"]}</h2>
                      </div>
                    </div>
                    <div className={styled.SwiperSlidevisit}>
                      <div className={styled.SwiperSlidevisitr}>
                        <Clock size="15" color="#5E5E5E" />
                        <h5>5d 16h</h5>
                      </div>
                      <div className={styled.SwiperSlidevisitl}>
                        <Eye size="15" color="#5E5E5E" />
                        <h5>2.5k</h5>
                      </div>
                    </div>
                  </div>

                  <Link to="/Courseinfo">
                    <div className={styled.SwiperSlidebutten}>
                      <UserAdd size="15" color="#FFFFFF" />
                      <h2>{language_json[language]["open"]}</h2>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styled.monybox}>
                  <div className={styled.SwiperSlideimg}></div>
                  <div className={styled.SwiperSlidetext}>
                    <div className={styled.SwiperSlideporf}>
                      <div className={styled.SwiperSlideporfr}>
                        <h2>Mojipipel</h2>
                        <h3>@Mojipipel</h3>
                      </div>
                      <div className={styled.SwiperSlideporfl}>
                        <img src={Swiperimg} />
                      </div>
                    </div>
                    <div className={styled.SwiperSlideprice}>
                      <div className={styled.SwiperSlidepricer}>
                        <h4>{language_json[language]["price"]}</h4>
                      </div>
                      <div className={styled.SwiperSlidepricel}>
                        <img src={monyimg} />
                        <h2>999$</h2>
                      </div>
                    </div>
                    <div className={styled.SwiperSlidevisit}>
                      <div className={styled.SwiperSlidevisitr}>
                        <Clock size="15" color="#5E5E5E" />
                        <h5>5d 16h</h5>
                      </div>
                      <div className={styled.SwiperSlidevisitl}>
                        <Eye size="15" color="#5E5E5E" />
                        <h5>2.5k</h5>
                      </div>
                    </div>
                  </div>
                  <Link to="/Courseinfo">
                    <div className={styled.SwiperSlidebutten}>
                      <Tag size="15" color="#FFFFFF" />
                      <h2> {language_json[language]["buy"]} </h2>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styled.freebox}>
                  <div className={styled.SwiperSlideimg}></div>
                  <div className={styled.SwiperSlidetext}>
                    <div className={styled.SwiperSlideporf}>
                      <div className={styled.SwiperSlideporfr}>
                        <h2>Mojipipel</h2>
                        <h3>@Mojipipel</h3>
                      </div>
                      <div className={styled.SwiperSlideporfl}>
                        <img src={Swiperimg} />
                      </div>
                    </div>
                    <div className={styled.SwiperSlideprice}>
                      <div className={styled.SwiperSlidepricer}>
                        <h4>{language_json[language]["price"]}</h4>
                      </div>
                      <div className={styled.SwiperSlidepricel}>
                        <img src={freeimg} />
                        <h2>{language_json[language]["free"]}</h2>
                      </div>
                    </div>
                    <div className={styled.SwiperSlidevisit}>
                      <div className={styled.SwiperSlidevisitr}>
                        <Clock size="15" color="#5E5E5E" />
                        <h5>5d 16h</h5>
                      </div>
                      <div className={styled.SwiperSlidevisitl}>
                        <Eye size="15" color="#5E5E5E" />
                        <h5>2.5k</h5>
                      </div>
                    </div>
                  </div>
                  <Link to="/Courseinfo">
                    <div className={styled.SwiperSlidebutten}>
                      <UserAdd size="15" color="#FFFFFF" />
                      <h2>{language_json[language]["open"]}</h2>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styled.freebox}>
                  <div className={styled.SwiperSlideimg}></div>
                  <div className={styled.SwiperSlidetext}>
                    <div className={styled.SwiperSlideporf}>
                      <div className={styled.SwiperSlideporfr}>
                        <h2>Mojipipel</h2>
                        <h3>@Mojipipel</h3>
                      </div>
                      <div className={styled.SwiperSlideporfl}>
                        <img src={Swiperimg} />
                      </div>
                    </div>
                    <div className={styled.SwiperSlideprice}>
                      <div className={styled.SwiperSlidepricer}>
                        <h4>{language_json[language]["price"]}</h4>
                      </div>
                      <div className={styled.SwiperSlidepricel}>
                        <img src={freeimg} />
                        <h2>{language_json[language]["free"]}</h2>
                      </div>
                    </div>
                    <div className={styled.SwiperSlidevisit}>
                      <div className={styled.SwiperSlidevisitr}>
                        <Clock size="15" color="#5E5E5E" />
                        <h5>5d 16h</h5>
                      </div>
                      <div className={styled.SwiperSlidevisitl}>
                        <Eye size="15" color="#5E5E5E" />
                        <h5>2.5k</h5>
                      </div>
                    </div>
                  </div>
                  <Link to="/Courseinfo">
                    <div className={styled.SwiperSlidebutten}>
                      <UserAdd size="15" color="#FFFFFF" />
                      <h2>{language_json[language]["open"]}</h2>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styled.freebox}>
                  <div className={styled.SwiperSlideimg}></div>
                  <div className={styled.SwiperSlidetext}>
                    <div className={styled.SwiperSlideporf}>
                      <div className={styled.SwiperSlideporfr}>
                        <h2>Mojipipel</h2>
                        <h3>@Mojipipel</h3>
                      </div>
                      <div className={styled.SwiperSlideporfl}>
                        <img src={Swiperimg} />
                      </div>
                    </div>
                    <div className={styled.SwiperSlideprice}>
                      <div className={styled.SwiperSlidepricer}>
                        <h4>{language_json[language]["price"]}</h4>
                      </div>
                      <div className={styled.SwiperSlidepricel}>
                        <img src={freeimg} />
                        <h2>{language_json[language]["free"]}</h2>
                      </div>
                    </div>
                    <div className={styled.SwiperSlidevisit}>
                      <div className={styled.SwiperSlidevisitr}>
                        <Clock size="15" color="#5E5E5E" />
                        <h5>5d 16h</h5>
                      </div>
                      <div className={styled.SwiperSlidevisitl}>
                        <Eye size="15" color="#5E5E5E" />
                        <h5>2.5k</h5>
                      </div>
                    </div>
                  </div>
                  <Link to="/Courseinfo">
                    <div className={styled.SwiperSlidebutten}>
                      <UserAdd size="15" color="#FFFFFF" />
                      <h2>{language_json[language]["open"]}</h2>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <h2 className={styled.texth}>
            {language_json[language]["new_courses"]}
          </h2>
          <div className={styled.boxbar}>
            <div className={styled.box}>
              <div className={styled.freebox}>
                <div className={styled.SwiperSlideimg}></div>
                <div className={styled.SwiperSlidetext}>
                  <div className={styled.SwiperSlideporf}>
                    <div className={styled.SwiperSlideporfr}>
                      <h2>Mojipipel</h2>
                      <h3>@Mojipipel</h3>
                    </div>
                    <div className={styled.SwiperSlideporfl}>
                      <img src={Swiperimg} />
                    </div>
                  </div>
                  <div className={styled.SwiperSlideprice}>
                    <div className={styled.SwiperSlidepricer}>
                      <h4>{language_json[language]["price"]}</h4>
                    </div>
                    <div className={styled.SwiperSlidepricel}>
                      <img src={freeimg} />
                      <h2>{language_json[language]["free"]}</h2>
                    </div>
                  </div>
                  <div className={styled.SwiperSlidevisit}>
                    <div className={styled.SwiperSlidevisitr}>
                      <Clock size="15" color="#5E5E5E" />
                      <h5>5d 16h</h5>
                    </div>
                    <div className={styled.SwiperSlidevisitl}>
                      <Eye size="15" color="#5E5E5E" />
                      <h5>2.5k</h5>
                    </div>
                  </div>
                </div>
                <Link to="/Courseinfo">
                  <div className={styled.SwiperSlidebutten}>
                    <UserAdd size="15" color="#FFFFFF" />
                    <h2>{language_json[language]["open"]}</h2>
                  </div>
                </Link>
              </div>
            </div>
            <div className={styled.box}>
              <div className={styled.freebox}>
                <div className={styled.SwiperSlideimg}></div>
                <div className={styled.SwiperSlidetext}>
                  <div className={styled.SwiperSlideporf}>
                    <div className={styled.SwiperSlideporfr}>
                      <h2>Mojipipel</h2>
                      <h3>@Mojipipel</h3>
                    </div>
                    <div className={styled.SwiperSlideporfl}>
                      <img src={Swiperimg} />
                    </div>
                  </div>
                  <div className={styled.SwiperSlideprice}>
                    <div className={styled.SwiperSlidepricer}>
                      <h4>{language_json[language]["price"]}</h4>
                    </div>
                    <div className={styled.SwiperSlidepricel}>
                      <img src={freeimg} />
                      <h2>{language_json[language]["free"]}</h2>
                    </div>
                  </div>
                  <div className={styled.SwiperSlidevisit}>
                    <div className={styled.SwiperSlidevisitr}>
                      <Clock size="15" color="#5E5E5E" />
                      <h5>5d 16h</h5>
                    </div>
                    <div className={styled.SwiperSlidevisitl}>
                      <Eye size="15" color="#5E5E5E" />
                      <h5>2.5k</h5>
                    </div>
                  </div>
                </div>
                <Link to="/Courseinfo">
                  <div className={styled.SwiperSlidebutten}>
                    <UserAdd size="15" color="#FFFFFF" />
                    <h2>{language_json[language]["open"]}</h2>
                  </div>
                </Link>
              </div>
            </div>
            <div className={styled.box}>
              <div className={styled.monybox}>
                <div className={styled.SwiperSlideimg}></div>
                <div className={styled.SwiperSlidetext}>
                  <div className={styled.SwiperSlideporf}>
                    <div className={styled.SwiperSlideporfr}>
                      <h2>Mojipipel</h2>
                      <h3>@Mojipipel</h3>
                    </div>
                    <div className={styled.SwiperSlideporfl}>
                      <img src={Swiperimg} />
                    </div>
                  </div>
                  <div className={styled.SwiperSlideprice}>
                    <div className={styled.SwiperSlidepricer}>
                      <h4>{language_json[language]["price"]}</h4>
                    </div>
                    <div className={styled.SwiperSlidepricel}>
                      <img src={freeimg} />
                      <h2>999$</h2>
                    </div>
                  </div>
                  <div className={styled.SwiperSlidevisit}>
                    <div className={styled.SwiperSlidevisitr}>
                      <Clock size="15" color="#5E5E5E" />
                      <h5>5d 16h</h5>
                    </div>
                    <div className={styled.SwiperSlidevisitl}>
                      <Eye size="15" color="#5E5E5E" />
                      <h5>2.5k</h5>
                    </div>
                  </div>
                </div>
                <Link to="/Courseinfo">
                  <div className={styled.SwiperSlidebutten}>
                    <Tag size="15" color="#FFFFFF" />
                    <h2> {language_json[language]["buy"]} </h2>
                  </div>
                </Link>
              </div>
            </div>
            <div className={styled.box}>
              <div className={styled.freebox}>
                <div className={styled.SwiperSlideimg}></div>
                <div className={styled.SwiperSlidetext}>
                  <div className={styled.SwiperSlideporf}>
                    <div className={styled.SwiperSlideporfr}>
                      <h2>Mojipipel</h2>
                      <h3>@Mojipipel</h3>
                    </div>
                    <div className={styled.SwiperSlideporfl}>
                      <img src={Swiperimg} />
                    </div>
                  </div>
                  <div className={styled.SwiperSlideprice}>
                    <div className={styled.SwiperSlidepricer}>
                      <h4>{language_json[language]["price"]}</h4>
                    </div>
                    <div className={styled.SwiperSlidepricel}>
                      <img src={freeimg} />
                      <h2>{language_json[language]["free"]}</h2>
                    </div>
                  </div>
                  <div className={styled.SwiperSlidevisit}>
                    <div className={styled.SwiperSlidevisitr}>
                      <Clock size="15" color="#5E5E5E" />
                      <h5>5d 16h</h5>
                    </div>
                    <div className={styled.SwiperSlidevisitl}>
                      <Eye size="15" color="#5E5E5E" />
                      <h5>2.5k</h5>
                    </div>
                  </div>
                </div>
                <Link to="/Courseinfo">
                  <div className={styled.SwiperSlidebutten}>
                    <UserAdd size="15" color="#FFFFFF" />
                    <h2>{language_json[language]["open"]}</h2>
                  </div>
                </Link>
              </div>
            </div>
            <div className={styled.box}>
              <div className={styled.freebox}>
                <div className={styled.SwiperSlideimg}></div>
                <div className={styled.SwiperSlidetext}>
                  <div className={styled.SwiperSlideporf}>
                    <div className={styled.SwiperSlideporfr}>
                      <h2>Mojipipel</h2>
                      <h3>@Mojipipel</h3>
                    </div>
                    <div className={styled.SwiperSlideporfl}>
                      <img src={Swiperimg} />
                    </div>
                  </div>
                  <div className={styled.SwiperSlideprice}>
                    <div className={styled.SwiperSlidepricer}>
                      <h4>{language_json[language]["price"]}</h4>
                    </div>
                    <div className={styled.SwiperSlidepricel}>
                      <img src={freeimg} />
                      <h2>{language_json[language]["free"]}</h2>
                    </div>
                  </div>
                  <div className={styled.SwiperSlidevisit}>
                    <div className={styled.SwiperSlidevisitr}>
                      <Clock size="15" color="#5E5E5E" />
                      <h5>5d 16h</h5>
                    </div>
                    <div className={styled.SwiperSlidevisitl}>
                      <Eye size="15" color="#5E5E5E" />
                      <h5>2.5k</h5>
                    </div>
                  </div>
                </div>
                <Link to="/Courseinfo">
                  <div className={styled.SwiperSlidebutten}>
                    <UserAdd size="15" color="#FFFFFF" />
                    <h2>{language_json[language]["open"]}</h2>
                  </div>
                </Link>
              </div>
            </div>
            <div className={styled.box}>
              <div className={styled.freebox}>
                <div className={styled.SwiperSlideimg}></div>
                <div className={styled.SwiperSlidetext}>
                  <div className={styled.SwiperSlideporf}>
                    <div className={styled.SwiperSlideporfr}>
                      <h2>Mojipipel</h2>
                      <h3>@Mojipipel</h3>
                    </div>
                    <div className={styled.SwiperSlideporfl}>
                      <img src={Swiperimg} />
                    </div>
                  </div>
                  <div className={styled.SwiperSlideprice}>
                    <div className={styled.SwiperSlidepricer}>
                      <h4>{language_json[language]["price"]}</h4>
                    </div>
                    <div className={styled.SwiperSlidepricel}>
                      <img src={freeimg} />
                      <h2>{language_json[language]["free"]}</h2>
                    </div>
                  </div>
                  <div className={styled.SwiperSlidevisit}>
                    <div className={styled.SwiperSlidevisitr}>
                      <Clock size="15" color="#5E5E5E" />
                      <h5>5d 16h</h5>
                    </div>
                    <div className={styled.SwiperSlidevisitl}>
                      <Eye size="15" color="#5E5E5E" />
                      <h5>2.5k</h5>
                    </div>
                  </div>
                </div>
                <Link to="/Courseinfo">
                  <div className={styled.SwiperSlidebutten}>
                    <UserAdd size="15" color="#FFFFFF" />
                    <h2>{language_json[language]["open"]}</h2>
                  </div>
                </Link>
              </div>
            </div>
            <div className={styled.box}>
              <div className={styled.freebox}>
                <div className={styled.SwiperSlideimg}></div>
                <div className={styled.SwiperSlidetext}>
                  <div className={styled.SwiperSlideporf}>
                    <div className={styled.SwiperSlideporfr}>
                      <h2>Mojipipel</h2>
                      <h3>@Mojipipel</h3>
                    </div>
                    <div className={styled.SwiperSlideporfl}>
                      <img src={Swiperimg} />
                    </div>
                  </div>
                  <div className={styled.SwiperSlideprice}>
                    <div className={styled.SwiperSlidepricer}>
                      <h4>{language_json[language]["price"]}</h4>
                    </div>
                    <div className={styled.SwiperSlidepricel}>
                      <img src={freeimg} />
                      <h2>{language_json[language]["free"]}</h2>
                    </div>
                  </div>
                  <div className={styled.SwiperSlidevisit}>
                    <div className={styled.SwiperSlidevisitr}>
                      <Clock size="15" color="#5E5E5E" />
                      <h5>5d 16h</h5>
                    </div>
                    <div className={styled.SwiperSlidevisitl}>
                      <Eye size="15" color="#5E5E5E" />
                      <h5>2.5k</h5>
                    </div>
                  </div>
                </div>
                <Link to="/Courseinfo">
                  <div className={styled.SwiperSlidebutten}>
                    <UserAdd size="15" color="#FFFFFF" />
                    <h2>{language_json[language]["open"]}</h2>
                  </div>
                </Link>
              </div>
            </div>
            <div className={styled.box}>
              <div className={styled.freebox}>
                <div className={styled.SwiperSlideimg}></div>
                <div className={styled.SwiperSlidetext}>
                  <div className={styled.SwiperSlideporf}>
                    <div className={styled.SwiperSlideporfr}>
                      <h2>Mojipipel</h2>
                      <h3>@Mojipipel</h3>
                    </div>
                    <div className={styled.SwiperSlideporfl}>
                      <img src={Swiperimg} />
                    </div>
                  </div>
                  <div className={styled.SwiperSlideprice}>
                    <div className={styled.SwiperSlidepricer}>
                      <h4>{language_json[language]["price"]}</h4>
                    </div>
                    <div className={styled.SwiperSlidepricel}>
                      <img src={freeimg} />
                      <h2>{language_json[language]["free"]}</h2>
                    </div>
                  </div>
                  <div className={styled.SwiperSlidevisit}>
                    <div className={styled.SwiperSlidevisitr}>
                      <Clock size="15" color="#5E5E5E" />
                      <h5>5d 16h</h5>
                    </div>
                    <div className={styled.SwiperSlidevisitl}>
                      <Eye size="15" color="#5E5E5E" />
                      <h5>2.5k</h5>
                    </div>
                  </div>
                </div>
                <Link to="/Courseinfo">
                  <div className={styled.SwiperSlidebutten}>
                    <UserAdd size="15" color="#FFFFFF" />
                    <h2>{language_json[language]["open"]}</h2>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Describing
        changeLanguage={changeLanguage}
        language={language}
        changeTheme={changeTheme}
        theme={theme}
      />
      <Loading active={isLoading} />
    </div>
  );
}
export default Academy;
