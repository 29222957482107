import styled from "./Code.module.css";
import { Link } from "react-router-dom";
import { Sun1 } from "iconsax-react";
import { Moon } from "iconsax-react";
import { LanguageSquare } from "iconsax-react";
import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { code_api } from "../../component/api/code.jsx";

var language_json = JSON.parse(localStorage.getItem("language"));
function CodeLogin() {
  const [language, setLanguage] = useState("fa");
  const [theme, setTheme] = useState("light");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const email_address = localStorage.getItem("email");

  if (email_address === null || email_address === "") {
    window.location.href = "/Login";
  }
  const SubmitCode = async () => {
    var input0 = document.getElementById("codeInput0");
    var input1 = document.getElementById("codeInput1");
    var input2 = document.getElementById("codeInput2");
    var input3 = document.getElementById("codeInput3");
    var input4 = document.getElementById("codeInput4");
    var input5 = document.getElementById("codeInput5");

    var SubmitCode = document.getElementById("SubmitCode");

    const text =
      input0.value +
      input1.value +
      input2.value +
      input3.value +
      input4.value +
      input5.value;
    if (text !== "" && text !== null) {
      if (text.length === 6) {
        SubmitCode.innerHTML = language_json[language]["loading"];
        SubmitCode.style.opacity = "0.5";
        handleClick(true);
        const code_api_data = await code_api(email_address, text);
        if (code_api_data[1] === 200) {
          toast.success(language_json[language][code_api_data[0]["message"]], {
            position:
              language === "fa"
                ? "bottom-right"
                : language === "en"
                ? "bottom-left"
                : "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            rtl: language === "fa" ? true : language === "en" ? false : true,
            draggable: true,
            progress: undefined,
            theme: theme,
          });
          const status = code_api_data[0]["status"];
          const token = code_api_data[0]["token"];
          localStorage.setItem("token", token);
          if (status === "OK") {
            setTimeout(() => {
              window.location.href = "/dashboard";
            }, 2000);
          } else if (status === "CA" || status === "NN") {
            setTimeout(() => {
              window.location.href = "/Completion";
            }, 2000);
          }
        } else if (code_api_data[1] === 406) {
          toast.error(language_json[language]["err_code_is_wrong"], {
            position:
              language === "fa"
                ? "bottom-right"
                : language === "en"
                ? "bottom-left"
                : "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            rtl: language === "fa" ? true : language === "en" ? false : true,
            draggable: true,
            progress: undefined,
            theme: theme,
          });
          SubmitCode.style.opacity = "1";
          handleClick(false);
          SubmitCode.innerHTML = language_json[language]["check"];
          setTimeout(() => {
            window.location.href = "/code";
          }, 2000);
        }
      } else {
        toast.error(language_json[language]["err_code_format"], {
          position:
            language === "fa"
              ? "bottom-right"
              : language === "en"
              ? "bottom-left"
              : "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          rtl: language === "fa" ? true : language === "en" ? false : true,
          draggable: true,
          progress: undefined,
          theme: theme,
        });
      }
    } else {
      toast.error(language_json[language]["err_code_empty"], {
        position:
          language === "fa"
            ? "bottom-right"
            : language === "en"
            ? "bottom-left"
            : "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: language === "fa" ? true : language === "en" ? false : true,
        draggable: true,
        progress: undefined,
        theme: theme,
      });
    }
  };
  const handleClick = (boly) => {
    setButtonDisabled(boly);
  };
  const changeLanguage = () => {
    var codeImgGrad = document.getElementById("codeImgGrad");
    var codeWraper = document.getElementById("codeWraper");
    var codetext = document.getElementById("codetext");
    var box = document.getElementById("box");
    var solgan = document.getElementById("solgan");
    var icons = document.getElementById("icons");

    const newLanguage = language === "fa" ? "en" : "fa";
    localStorage.setItem("select_language", newLanguage);
    if (newLanguage == "fa") {
      codeImgGrad.style.background = `var(--Gradient)`;
      codeWraper.style.flexDirection = "row";
      box.style.flexDirection = "row-reverse";
      codetext.style.direction = "rtl";
      solgan.style.left = "auto";
      solgan.style.right = "30px";
      icons.style.left = "0px";
      icons.style.right = "auto";
      document.documentElement.style.setProperty("--font", "var(--fa-font)");
    } else if (newLanguage == "en") {
      codeImgGrad.style.background = `var(--Gradient-reverse)`;
      codeWraper.style.flexDirection = "row-reverse";
      box.style.flexDirection = "row";
      codetext.style.direction = "ltr";
      solgan.style.left = "30px";
      solgan.style.right = "auto";
      icons.style.left = "auto";
      icons.style.right = "0px";
      document.documentElement.style.setProperty("--font", "var(--en-font)");
    }
    setLanguage(newLanguage);
  };
  const changeTheme = () => {
    var moonbutton = document.getElementById("moonbutton");
    var sunbutton = document.getElementById("sunbutton");

    const newtheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("select_theme", newtheme);

    if (newtheme == "light") {
      sunbutton.style.display = "inline";
      moonbutton.style.display = "none";

      document.documentElement.style.setProperty("--Text", "var(--light-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--light-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--light-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--light-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--light-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--light-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--light-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--light-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--light-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--light-Gradient${language == "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--light-code-img)"
      );
    } else if (newtheme == "dark") {
      moonbutton.style.display = "inline";
      sunbutton.style.display = "none";

      document.documentElement.style.setProperty("--Text", "var(--dark-Text)");
      document.documentElement.style.setProperty(
        "--Subtext",
        "var(--dark-Subtext)"
      );
      document.documentElement.style.setProperty(
        "--Primary",
        "var(--dark-Primary)"
      );
      document.documentElement.style.setProperty(
        "--Dialog",
        "var(--dark-Dialog)"
      );
      document.documentElement.style.setProperty(
        "--Secondary",
        "var(--dark-Secondary)"
      );
      document.documentElement.style.setProperty(
        "--Interactive",
        "var(--dark-Interactive)"
      );
      document.documentElement.style.setProperty(
        "--Stroke",
        "var(--dark-Stroke)"
      );
      document.documentElement.style.setProperty(
        "--ButtonText",
        "var(--dark-ButtonText)"
      );
      document.documentElement.style.setProperty(
        "--Gradient",
        "var(--dark-Gradient)"
      );
      document.documentElement.style.setProperty(
        "--Gradient-reverse",
        `var(--dark-Gradient${language == "en" ? "-reverse" : ""})`
      );

      document.documentElement.style.setProperty(
        "--code-img",
        "var(--dark-code-img)"
      );
    }
    setTheme(newtheme);
  };
  useEffect(() => {
    const selectLanguage = localStorage.getItem("select_language");
    const selectTheme = localStorage.getItem("select_theme");

    if (selectLanguage === "en") {
      changeLanguage();
    }
    if (selectTheme === "dark") {
      changeTheme();
    }
  }, []);

  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const codeRefs = useRef([]);

  const handleCodeClick = (event) => {
    event.target.select();
  };
  const handleCodeChange = (event, index) => {
    const value = event.target.value;

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value.length === 1 && index < codeRefs.current.length - 1) {
      codeRefs.current[index + 1].focus();
    } else if (value.length === 0 && index > 0) {
      codeRefs.current[index - 1].focus();
    }
  };

  return (
    <div id="codeWraper" className={styled.codeWraper}>
      <div id="codetext" className={styled.codeText}>
        <div id="icons" className={styled.icon}>
          <button
            onClick={changeTheme}
            id="moonbutton"
            className={styled.moonbutton}
          >
            <Moon className={styled.moon} />
          </button>
          <button
            onClick={changeTheme}
            id="sunbutton"
            className={styled.sunbutton}
          >
            <Sun1 className={styled.Sun1} />
          </button>
          {
            <LanguageSquare
              onClick={changeLanguage}
              className={styled.LanguageSquare}
            />
          }
        </div>
        <div id="Text" className={styled.Text}>
          <h1 className={styled.gradient}>
            {language_json[language]["login_code_sent_text"]}
            <br />
            <span>{email_address}</span>
          </h1>
          <Link to="/Login">{language_json[language]["change_email"]}</Link>
          <h6>{language_json[language]["enter_code_text"]}</h6>
          <div id="box" className={styled.box}>
            {code.map((digit, index) => (
              <div className={styled.box1}>
                <input
                  key={index}
                  id={`codeInput${index}`}
                  ref={(ref) => (codeRefs.current[index] = ref)}
                  type="text"
                  value={digit}
                  className={styled.inputCode}
                  onChange={(event) => handleCodeChange(event, index)}
                  maxLength={1}
                  onClick={handleCodeClick}
                />
              </div>
            ))}
          </div>
          <div className={styled.button}>
            <button
              id="SubmitCode"
              onClick={SubmitCode}
              disabled={isButtonDisabled}
            >
              {language_json[language]["check"]}
            </button>
          </div>
        </div>
      </div>
      <div id="codeImg" className={styled.CodeImg}>
        <div id="codeImgGrad" className={styled.CodeImggGrad}></div>
        <h2 id="solgan">{language_json[language]["slogan"]}</h2>
      </div>
      <ToastContainer />
    </div>
  );
}
export default CodeLogin;
